import Repository from "@/plugins/axios";

export default {
  // Получить страницу участников
  getParticipantsList(biennaleName) {
    return Repository.get(`/biennale/${biennaleName}/participants`);
  },

  // Получить страницу одного участника
  getParticipant(ident) {
    return Repository.get(`/biennale/participants/${ident}`);
  },
};
