import moment from "moment";

export const TitleWrap = function (str, maxWidth, newLineStr) {
  const whiteRegexp = new RegExp(/^[\s-]$/);
  let found = false;
  let res = "";
  while (str.length > maxWidth) {
    found = false;
    // Inserts new line at first whitespace of the line
    for (let i = maxWidth - 1; i >= 0; i--) {
      if (whiteRegexp.test(str.charAt(i))) {
        res = res + [str.slice(0, i), newLineStr].join("");
        str = str.slice(i + 1);
        found = true;
        break;
      }
    }
    // Inserts new line at maxWidth position, the word is too long to wrap
    if (!found) {
      res += [str.slice(0, maxWidth), newLineStr].join("");
      str = str.slice(maxWidth);
    }
  }
  return res + str;
};

export const Schedule = (scheduleObject, disabledWeekDays) => {
  const dt = {};
  if (scheduleObject.ActiveKindId) {
    if (scheduleObject.ActiveKindId == 3) {
      dt.dtBegin =
        scheduleObject.dt_begin || scheduleObject.dtBegin || undefined;
      dt.dtEnd = scheduleObject.dt_end || scheduleObject.dtEnd || undefined;
    } else if (scheduleObject.ActiveKindId == 4) {
      dt.Dates = scheduleObject.dates || scheduleObject.Dates || undefined;
    }
  } else {
    dt.dtBegin = scheduleObject.dt_begin || scheduleObject.dtBegin || undefined;
    dt.dtEnd = scheduleObject.dt_end || scheduleObject.dtEnd || undefined;
    dt.Dates = scheduleObject.dates || scheduleObject.Dates || undefined;
  }

  const Times = scheduleObject.times || [];
  const Today = moment().startOf("day");
  const DisabledWeekDays = disabledWeekDays || [];

  return {
    Typ: scheduleObject.typ,
    Text: scheduleObject.text || "",
    dtBegin: dt.dtBegin
      ? moment(dt.dtBegin.substr(0, 10)).startOf("day")
      : undefined,
    dtEnd: dt.dtEnd ? moment(dt.dtEnd.substr(0, 10)).startOf("day") : undefined,
    Dates: dt.Dates
      ? dt.Dates.map((dt) => moment(dt.substr(0, 10)).startOf("day"))
      : undefined,
    Times,
    Today,
    DisabledWeekDays,

    format(date) {
      if (date === undefined) return undefined;
      return date.format("yyyy-MM-DD HH:mm:ss");
    },
    minDate() {
      const min =
        this.Dates && this.Dates.length > 0 ? this.Dates[0] : this.dtBegin;
      if (!min) return this.Today;
      return min < this.Today ? this.Today : min;
    },
    minDateFormatted() {
      return this.format(this.minDate());
    },
    maxDate() {
      return this.Dates && this.Dates.length > 0
        ? this.Dates[this.Dates.length - 1]
        : this.dtEnd;
    },
    maxDateFormatted() {
      return this.format(this.maxDate());
    },
    allowedDates() {
      return (date) => {
        if (this.Dates && this.Dates.length > 0) {
          return this.Dates.some((dt) => dt.format("yyyy-MM-DD") == date);
        } else {
          const day = moment(date).day().toString();
          return !this.DisabledWeekDays.includes(day);
        }
      };
    },
    hasAvailableDates() {
      if (this.maxDate() === undefined) return true;
      return this.minDate() <= this.maxDate();
    },
    isPast() {
      if (this.maxDate() === undefined) return false;
      return this.maxDate() < this.Today;
    },
  };
};
