var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.pageLoaded)?_c('div',{staticClass:"section"},[_c('image-header',{ref:"refimageheader",attrs:{"image":_vm.pageData.fields.image,"video":_vm.pageData.fields.video,"glow":_vm.pageData.fields.glow || '0'}}),_vm._v(" "),_c('kk-breadcrumbs',{staticClass:"mb-5 mb-sm-10",attrs:{"items":_vm.breadcrumbsItems}}),_vm._v(" "),_c('page-title',{staticClass:"mb-4 mb-md-8",attrs:{"title":_vm.pageData.fields.title}}),_vm._v(" "),(!_vm.isActualBiennale)?_c('div',{staticClass:"archive-notification longread pa-5 pa-md-10 mb-4 mb-md-8",domProps:{"innerHTML":_vm._s(_vm.pageData.main_fields.archive_notification)}}):_vm._e(),_vm._v(" "),_c('kk-article',{ref:"refarticle",attrs:{"contents":_vm.pageData.fields.contents,"dense":"","ticket-settings":_vm.pageData.fields}},[_c('kk-dynamic-html',{attrs:{"html":_vm.pageData.fields.article}}),_vm._v(" "),_c('tag-list',{staticClass:"mb-5 mb-md-10",attrs:{"tags":_vm.pageData.fields.tags || [],"tag-route":_vm.tagRoute}})],1),_vm._v(" "),((_vm.pageData.sites.children || []).length > 0)?_c('div',{staticClass:"subsection border-top stick-to-body padding-from-body pt-5 pt-md-10"},[_c('subsection-title',{staticClass:"mb-5 mb-md-10",attrs:{"title":_vm.pageData.sites.fields.title,"browse-title":_vm.pageData.sites.fields.title_all,"browse-link":{
        name: 'Sites',
        params: { biennale: _vm.biennale },
      }}}),_vm._v(" "),_vm._l((_vm.pageData.sites.children),function(item,index){return _c('div',{key:index,staticClass:"mb-8 mb-md-16"},[_c('image-extended',{staticClass:"mb-5 mb-md-10",attrs:{"max-width":item.fields?.image?.i_width || '100%',"image":item.fields.image}}),_vm._v(" "),_c('h5',{staticClass:"mb-5 mb-md-10"},[_vm._v("\n        "+_vm._s(item.fields.title)+"\n      ")]),_vm._v(" "),((item.fields.tags || []).length > 0)?_c('tag-list',{staticClass:"mb-5 mb-md-10",attrs:{"tags":item.fields.tags,"tag-route":_vm.tagRoute}}):((item.fields.directions || []).length > 0)?_c('tag-list',{staticClass:"mb-5 mb-md-10",attrs:{"tags":item.fields.directions,"tag-route":_vm.directionRoute}}):_vm._e(),_vm._v(" "),_c('kk-dynamic-html',{attrs:{"html":item.fields.article}})],1)})],2):_vm._e(),_vm._v(" "),(_vm.pageData.media.list.length > 0)?_c('subsection-title',{staticClass:"mb-5 mb-md-10 border-top stick-to-body padding-from-body pt-5 pt-md-10",attrs:{"title":_vm.pageData.main_fields.title_media,"browse-title":_vm.pageData.main_fields.browse_all_media,"browse-link":{
      name: 'MediaLibraryItem',
      params: { mediabiennale: _vm.biennale, ident: _vm.pageData.media.node_link },
    }}}):_vm._e(),_vm._v(" "),(_vm.pageData.media.list.length > 0)?_c('horizontal-scroller',{staticClass:"subsection",attrs:{"items":_vm.pageData.media.list,"content-class":"ml-n4"},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('div',{staticClass:"mx-4 scroll-item__container"},[_c('img',{staticClass:"scroll-item__image",attrs:{"src":item.i_size_600,"draggable":"false","alt":""}})])]}}],null,false,357190831)}):_vm._e(),_vm._v(" "),(_vm.pageData.participants.children.length > 0)?_c('participant-list',{staticClass:"border-top stick-to-body padding-from-body pt-5 pt-md-10",attrs:{"payload":_vm.pageData.participants}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }