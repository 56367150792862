<template>
  <div>
    <page-title :title="pageData.fields.title" />

    <v-row class="section align-stretch ff__row flex-sm-nowrap">
      <v-col cols="12" sm="10" md="8" xl="6">
        <h3 class="mb-5 mb-sm-10">{{ pageData.fields.subtitle }}</h3>
        <kk-dynamic-html
          v-if="pageData.fields.article"
          :html="pageData.fields.article"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="showPreloader" cols="12" class="section">
        <infinite-loader :disabled="!showPreloader" :visible="showPreloader" />
      </v-col>

      <v-col v-else class="subsection">
        <search-alter :section-data="alterData" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SupplementRepository from "@/api/supplement.js";
import PageTitle from "@/components/PageTitle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import SearchAlter from "@/pages/Search/SearchAlter.vue";
import InfiniteLoader from "@/components/InfiniteLoader.vue";

export default {
  components: { PageTitle, KkDynamicHtml, SearchAlter, InfiniteLoader },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      showPreloader: true,
      alterData: null,
    };
  },
  computed: {
    pageData() {
      return this.$store.getters.getPageNotFound;
    },
  },
  async mounted() {
    this.$store.commit("setLangsAvailAll");
    this.$emit("page-loaded");
    const { data } = await SupplementRepository.notfound();
    this.alterData = data;
    this.showPreloader = false;
  },
};
</script>
