<template>
  <div>
    <section-title :title="sectionData.fields.title" class="mb-5 mb-md-10" />

    <v-row class="subsection justify-start align-start ma-n3 ma-md-n5">
      <v-col
        v-for="(item, index) in sectionData.children"
        :key="index"
        cols="12"
        sm="6"
        md="12"
        xl="6"
        class="pa-3 pa-md-5"
      >
        <div class="overview-item pa-5 pa-md-10">
          <v-row class="align-start ma-n3 ma-md-n5">
            <v-col
              v-if="hasMark(item)"
              class="overview-mark flex-grow-0 pa-3 pa-md-5"
            >
              <img
                v-if="item.fields.image"
                :src="item.fields.image"
                alt=""
                class="overview-image"
              />
              <span v-else class="overview-number biennalePrimary--text">{{
                item.fields.number
              }}</span>
            </v-col>
            <v-col>
              <div class="overview-text kk-accent" v-html="item.fields.text" />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";

export default {
  name: "MainPartners",
  components: {
    SectionTitle,
  },
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasMark() {
      return (item) =>
        (item.fields.image || "").length > 0 ||
        (item.fields.number || "").length > 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.overview-item {
  position: relative;
  border: 1px solid var(--v-line-base);

  .overview-mark {
    padding-right: 28px;

    .overview-image {
      display: block;
      max-width: 180px;
      @media (min-width: 1024px) {
        max-width: 225px;
      }
    }

    .overview-number {
      display: block;
      font-family: "A Chaika Futuris";
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      white-space: nowrap;
      @media (min-width: 1024px) {
        font-size: 180px;
        line-height: 100%;
      }
    }
  }

  .overview-text {
    min-width: 150px;
    @media (min-width: 1024px) {
      min-width: 300px;
    }
  }
}
</style>
