<template>
  <div class="header-logo menu-closure">
    <div class="header-logo__icon menu-closure">
      <img
        :src="dark ? logoDark : logoLight"
        alt=""
        class="header-logo__icon_image menu-closure"
        draggable="false"
      />
    </div>
    <div
      v-if="currentBiennale && !dark"
      class="header-logo__text menu-closure biennalePrimary--text"
    >
      {{ currentBiennale.number.toUpperCase() }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentBiennale() {
      return this.$store.getters.getCurrentBiennale;
    },
    logoLight() {
      return this.currentBiennale?.logo_light
        ? this.currentBiennale.logo_light
        : `${this.baseUrl}images/logo-biennale-light.svg`;
    },
    logoDark() {
      return this.currentBiennale?.logo_dark
        ? this.currentBiennale.logo_dark
        : `${this.baseUrl}images/logo-biennale-dark.svg`;
    },
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
};
</script>
<style lang="scss" scoped>
.header-logo {
  --w-big: 40px;
  --w-small: 12px;
  width: auto;
  height: var(--w-big);
  display: flex;
  align-items: center;
  user-select: none;

  .header-logo__icon_image {
    display: block;
    width: var(--w-big);
    height: var(--w-big);
  }

  .header-logo__text {
    width: auto;
    font-family: A Chaika Futuris;
    font-size: 52px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px;
    height: 45px;
    margin-left: 16px;

    @media (max-width: 1023px) {
      height: 28px;
      font-size: 32px;
      line-height: 32px;
    }

    html.inclusive-darktheme & {
      filter: invert(100%) sepia(75%) saturate(0%) brightness(100%)
        contrast(100%);
    }
  }
}

@media (max-width: 1023px) {
  .header-logo {
    --w-big: 24px;
    --w-small: 8px;
    .header-logo__text {
      margin-left: 5px;
    }
  }
}
</style>
