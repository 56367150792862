<template>
  <transition name="slide">
    <kk-scrollbar v-show="opened" class="menu" :style="kkScrollbarStyle">
      <div class="mobile-fix">
        <header-lang-selector
          class="d-flex d-md-none my-4"
          :langs="langs"
          :locale="locale"
          @change-locale="onChangeLocale"
        />

        <header-search-mobile
          class="d-block d-md-none mb-10"
          @on-search="onSearch"
        />

        <header-menu-wide-list class="mt-md-6" :section-data="sectionData" />

        <social-buttons
          height="35"
          :space-between="$vuetify.breakpoint.mdAndUp ? 24 : 8"
          class="mb-5 mb-md-10"
        />
      </div>
    </kk-scrollbar>
  </transition>
</template>

<script>
import HeaderMenuWideList from "./HeaderMenuWideList.vue";
import HeaderLangSelector from "./HeaderLangSelector.vue";
import HeaderSearchMobile from "./HeaderSearchMobile.vue";
import SocialButtons from "@/components/SocialButtons.vue";

export default {
  components: {
    HeaderMenuWideList,
    HeaderLangSelector,
    HeaderSearchMobile,
    SocialButtons,
  },
  props: {
    opened: {
      type: Boolean,
      required: true,
    },
    sectionData: {
      type: Object,
      required: true,
    },
    langs: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    addPadding: {
      type: Number,
      default: 0,
    },
    addPaddingInverted: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      search: "",
      email: "",
      showSearch: false,
      showSubscriptionDialog: false,
    };
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
    kkScrollbarStyle() {
      const max = Math.max(this.addPaddingInverted, this.addPadding);
      if (this.$vuetify.breakpoint.mdAndUp) {
        return {
          right: `-${this.addPaddingInverted}px`,
          "padding-right": `${max + 40}px`,
        };
      } else {
        return {
          right: `-${this.addPaddingInverted}px`,
          "padding-right": "16px",
          "padding-left": `${max + 16}px`,
        };
      }
    },
  },
  methods: {
    onChangeLocale(locale, langEnabled) {
      this.$emit("change-locale", locale, langEnabled);
    },
    onSearch(searchTerm) {
      this.$emit("on-search", searchTerm);
    },
  },
};
</script>
<style lang="scss" scoped>
.menu {
  position: fixed;
  box-sizing: content-box;
  right: 0;
  top: 46px;
  width: calc(100vw - 32px);
  background: var(--v-bgPrimary-base);
  z-index: 1;
  height: calc(100vh - var(--menu-height));
  overflow-y: hidden;
  padding-left: 16px;
  border-left: 1px solid var(--v-line-base);
  .mobile-fix {
    @media (max-width: 1023px) {
      padding-right: 32px;
    }
  }
}

@media (min-width: 1024px) {
  .menu {
    top: 70px;
    width: 520px;
    padding-left: 40px;
    border-left: 1px solid var(--v-line-base);
  }
}

.slide-enter-active {
  transition-property: height;
  transition-property: margin-right;
  transition-duration: 0.675s;
  transition-timing-function: cubic-bezier(0.3, 0.1, 0.3, 1);
}

.slide-leave-active {
  transition-property: height;
  transition-property: margin-right;
  transition-duration: 0.675s;
  transition-timing-function: cubic-bezier(0.3, 0.1, 0.3, 1);
}

.slide-enter-to,
.slide-leave {
  height: 100vh;
  margin-right: 0;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  height: 100vh;
  margin-right: -100vw;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .slide-enter,
  .slide-leave-to {
    margin-right: -600px;
    overflow: hidden;
  }
}
</style>
