<template>
  <div>
    <section-title
      :title="mainFields.title_news"
      :browse-title="mainFields.browse_all_news"
      :browse-link="{ name: 'NewsList', params: { biennale } }"
      class="mb-6 mb-md-12"
    />

    <v-row>
      <v-col cols="12" xl="6" class="pa-md-5">
        <news-list-item :payload="news(0)" kind="first" />
      </v-col>
      <v-col cols="12" xl="6" class="pa-md-5">
        <v-row class="ma-md-n5">
          <v-col cols="12" class="pa-md-5">
            <news-list-item :payload="news(1)" kind="second" />
          </v-col>
        </v-row>
        <v-row class="ma-md-n5">
          <v-col cols="12" sm="6" class="pa-md-5">
            <news-list-item :payload="news(2)" kind="third-fourth" />
          </v-col>
          <v-col cols="12" sm="6" class="pa-md-5">
            <news-list-item :payload="news(3)" kind="third-fourth" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import NewsListItem from "@/components/News/NewsListItem.vue";

export default {
  name: "MainMedia",
  components: { SectionTitle, NewsListItem },
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
    mainFields: {
      type: Object,
      required: true,
    },
    biennale: {
      type: String,
      required: true,
    },
  },
  computed: {
    news() {
      return (index) => this.sectionData.children[index];
    },
  },
};
</script>
