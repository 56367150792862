<template>
  <div>
    <v-row
      v-for="(menuItem, index) in sectionData.children"
      :key="index"
      no-gutters
      class="mb-8"
    >
      <v-col cols="12 mb-1 mb-md-3">
        <div class="h6-mini">{{ menuItem.fields.title }}</div>
      </v-col>
      <v-col
        v-for="(menuSubItem, indexSub) in menuItem.children"
        :key="indexSub"
        cols="12"
        class="mb-1"
      >
        <kk-link
          class="menu-closure caption-common"
          :link="menuSubItem.fields.href"
        >
          {{ menuSubItem.fields.title }}
        </kk-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import KkLink from "@/components/KkLink.vue";
export default {
  components: { KkLink },
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
  },
};
</script>
<style lang="scss" scoped>
.list-row-xe {
  margin-top: 80px;
  padding-bottom: 122px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--v-line-base);
  .list-title {
    margin-bottom: 24px;
  }
  .list-item {
    margin-bottom: 12px;
  }
}
</style>
