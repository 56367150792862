<template>
  <div v-if="tags && tags.length > 0" class="tags-container">
    <tag-chip
      v-for="(item, index) in tagsWithLinks"
      :key="index"
      :class="nonClickable ? 'not-not-clickable' : ''"
      :to="item.to"
      @click="$emit('tagclick', item.tag)"
    >
      {{ (item.tag.text || item.tag || "").toLowerCase() }}
    </tag-chip>
  </div>
</template>

<script>
import TagChip from "@/components/Tags/TagChip.vue";

export default {
  components: { TagChip },
  props: {
    tags: {
      type: Array,
      required: true,
    },
    nonClickable: {
      type: Boolean,
      default: false,
    },
    tagRoute: {
      type: Function,
      default: null,
    },
  },
  computed: {
    tagsWithLinks() {
      return this.tags.map((tag) => {
        const result = { tag };
        if (this.tagRoute === null) {
          return result;
        }
        result.to = this.tagRoute(tag);
        return result;
      });
    },
  },
};
</script>
