<template>
  <div v-if="pageLoaded" class="section">
    <image-header
      ref="refimageheader"
      :image="pageData.fields.image"
      :video="pageData.fields.video"
      :glow="pageData.fields.glow || '0'"
    />

    <kk-breadcrumbs :items="breadcrumbsItems" class="mb-5 mb-sm-10" />

    <page-title :title="pageData.fields.title" class="mb-4 mb-md-8" />

    <div
      v-if="!isActualBiennale"
      class="archive-notification longread pa-5 pa-md-10 mb-4 mb-md-8"
      v-html="pageData.main_fields.archive_notification"
    />

    <kk-article
      ref="refarticle"
      :contents="pageData.fields.contents"
      dense
      :ticket-settings="pageData.fields"
    >
      <kk-dynamic-html :html="pageData.fields.article" />

      <tag-list
        :tags="pageData.fields.tags || []"
        :tag-route="tagRoute"
        class="mb-5 mb-md-10"
      />
    </kk-article>

    <div
      v-if="(pageData.sites.children || []).length > 0"
      class="subsection border-top stick-to-body padding-from-body pt-5 pt-md-10"
    >
      <subsection-title
        :title="pageData.sites.fields.title"
        :browse-title="pageData.sites.fields.title_all"
        :browse-link="{
          name: 'Sites',
          params: { biennale },
        }"
        class="mb-5 mb-md-10"
      />

      <div
        v-for="(item, index) in pageData.sites.children"
        :key="index"
        class="mb-8 mb-md-16"
      >
        <image-extended
          :max-width="item.fields?.image?.i_width || '100%'"
          :image="item.fields.image"
          class="mb-5 mb-md-10"
        />
        <h5 class="mb-5 mb-md-10">
          {{ item.fields.title }}
        </h5>
        <tag-list
          v-if="(item.fields.tags || []).length > 0"
          :tags="item.fields.tags"
          :tag-route="tagRoute"
          class="mb-5 mb-md-10"
        />
        <tag-list
          v-else-if="(item.fields.directions || []).length > 0"
          :tags="item.fields.directions"
          :tag-route="directionRoute"
          class="mb-5 mb-md-10"
        />
        <kk-dynamic-html :html="item.fields.article" />
      </div>
    </div>

    <subsection-title
      v-if="pageData.media.list.length > 0"
      :title="pageData.main_fields.title_media"
      :browse-title="pageData.main_fields.browse_all_media"
      :browse-link="{
        name: 'MediaLibraryItem',
        params: { mediabiennale: biennale, ident: pageData.media.node_link },
      }"
      class="mb-5 mb-md-10 border-top stick-to-body padding-from-body pt-5 pt-md-10"
    />

    <horizontal-scroller
      v-if="pageData.media.list.length > 0"
      :items="pageData.media.list"
      content-class="ml-n4"
      class="subsection"
    >
      <template v-slot="{ item }">
        <div class="mx-4 scroll-item__container">
          <img
            :src="item.i_size_600"
            draggable="false"
            class="scroll-item__image"
            alt=""
          />
        </div>
      </template>
    </horizontal-scroller>

    <participant-list
      v-if="pageData.participants.children.length > 0"
      :payload="pageData.participants"
      class="border-top stick-to-body padding-from-body pt-5 pt-md-10"
    />
  </div>
</template>

<script>
import ProgramRepository from "@/api/program.js";
import ImageHeader from "@/components/ImageHeader.vue";
import ImageExtended from "@/components/ImageExtended.vue";
import KkBreadcrumbs from "@/components/Article/KkBreadcrumbs.vue";
import KkArticle from "@/components/Article/KkArticle.vue";
import PageTitle from "@/components/PageTitle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import TagList from "@/components/Tags/TagList.vue";
import SubsectionTitle from "@/components/SubsectionTitle.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import ParticipantList from "./components/ParticipantList.vue";

export default {
  components: {
    ImageHeader,
    ImageExtended,
    KkBreadcrumbs,
    KkArticle,
    PageTitle,
    KkDynamicHtml,
    TagList,
    SubsectionTitle,
    HorizontalScroller,
    ParticipantList,
  },
  props: {
    biennale: {
      type: String,
      required: true,
    },
    ident: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(
        this.pageData?.fields?.title || ""
      ),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        links: { person: [] },
        main_fields: {},
        program_fields: {},
        participants: { fields: {}, children: [] },
        media: { node_link: null, list: [] },
      },
    };
  },
  computed: {
    isActualBiennale() {
      return this.biennale === this.$store.getters.getActualBiennaleIdent;
    },
    breadcrumbsItems() {
      return this.isActualBiennale
        ? this.breadcrumbsItemsActual
        : this.breadcrumbsItemsArchive;
    },
    breadcrumbsItemsActual() {
      const { biennale } = this;
      return [
        {
          text: this.pageData.main_fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.program_fields.title || "",
          to: { path: `/${biennale}/program` },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.fields.direction.text || "",
          to: {
            name: "ProgramDirection",
            params: {
              biennale,
              ident: this.pageData.fields.direction.id,
            },
          },
          exact: true,
          disabled: false,
        },
      ];
    },
    breadcrumbsItemsArchive() {
      const { biennale } = this;
      const biennaleName = this.biennaleInfo?.title || "";
      const biennaleNumber = this.biennaleInfo?.number || "";
      return [
        {
          text: this.pageData.main_fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.main_fields.archive_title || "",
          to: { name: "ArchiveList" },
          exact: true,
          disabled: false,
        },
        {
          text: `${biennaleNumber} «${biennaleName}»`,
          to: { name: "ArchiveItem", params: { biennale } },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.program_fields.title || "",
          to: { path: `/${biennale}/program` },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.fields.direction.text || "",
          to: {
            name: "ProgramDirection",
            params: {
              biennale,
              ident: this.pageData.fields.direction.id,
            },
          },
          exact: true,
          disabled: false,
        },
      ];
    },
    biennaleInfo() {
      return this.$store.getters.getBiennaleByIdent(this.biennale);
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await ProgramRepository.getProgramItem(
        this.biennale,
        this.ident
      );
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
        this.$refs.refimageheader.initObserver();
      });
    },
    tagRoute(tag) {
      const { biennale } = this;
      return { name: "ProgramListByTag", params: { biennale, tag: tag.id } };
    },
    directionRoute(direction) {
      const { biennale } = this;
      return {
        name: "ProgramDirection",
        params: { biennale, ident: direction.id },
      };
    },
  },
};
</script>
<style scoped lang="scss">
.archive-notification {
  border: 2px solid var(--v-biennalePrimary);
  color: var(--v-biennalePrimary);
  ::v-deep(a) {
    text-decoration: underline;
    color: var(--v-biennalePrimary) !important;
  }
}
.scroll-item__container {
  position: relative;
  overflow: hidden;
}
.scroll-item__image {
  height: 300px;
  @media (min-width: 1024px) {
    height: 400px;
  }
}
</style>
