<template>
  <div>
    <section-title
      :title="mainFields.title_sites"
      :browse-title="mainFields.browse_all_sites"
      :browse-link="{ name: 'Sites', params: { biennale } }"
      class="mb-6 mb-md-12"
    />

    <sites-list :list="sectionData.children" :biennale="biennale" />
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import SitesList from "@/components/Sites/SitesList.vue";

export default {
  name: "MainSites",
  components: { SectionTitle, SitesList },
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
    mainFields: {
      type: Object,
      required: true,
    },
    biennale: {
      type: String,
      required: true,
    },
  },
};
</script>
