<template>
  <div>
    <page-title :title="pageData.fields.title" />

    <kk-article
      v-if="pageLoaded"
      ref="refarticle"
      :contents="contents"
      class="subsection"
    >
      <div
        v-for="(item, index) in pageData.children"
        :key="index"
        class="site-item"
      >
        <h3 :id="item.node_name" class="biennalePrimary--text mb-5 mb-md-10">
          {{ item.fields.title }}
        </h3>
        <image-extended
          width="100%"
          :image="item.fields.image"
          class="mb-5 mb-md-10"
        />
        <tag-list
          v-if="(item.fields.tags || []).length > 0"
          :tags="item.fields.tags"
          :tag-route="tagRoute"
          class="mb-5 mb-md-10"
        />
        <tag-list
          v-else-if="(item.fields.directions || []).length > 0"
          :tags="item.fields.directions"
          :tag-route="directionRoute"
          class="mb-5 mb-md-10"
        />
        <kk-dynamic-html :html="item.fields.article" />
      </div>
    </kk-article>

    <zuruck with-aux-bar />
  </div>
</template>

<script>
import SimplePagesRepository from "@/api/simple.js";
import PageTitle from "@/components/PageTitle.vue";
import KkArticle from "@/components/Article/KkArticle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import ImageExtended from "@/components/ImageExtended.vue";
import TagList from "@/components/Tags/TagList.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    PageTitle,
    KkArticle,
    KkDynamicHtml,
    ImageExtended,
    TagList,
    Zuruck,
  },
  props: {
    biennale: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
      },
    };
  },
  computed: {
    contents() {
      return this.pageData.children.reduce((contents, item) => {
        contents[item.node_name] = item.fields.title;
        return contents;
      }, {});
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
    biennale() {
      this.loadPage();
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await SimplePagesRepository.getSites(this.biennale);
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
      });
    },
    tagRoute(tag) {
      const { biennale } = this;
      return { name: "ProgramListByTag", params: { biennale, tag: tag.id } };
    },
    directionRoute(direction) {
      const biennale = this.$store.getters.getActualBiennaleIdent;
      return {
        name: "ProgramDirection",
        params: { biennale, ident: direction.id },
      };
    },
  },
};
</script>
<style scoped lang="scss">
.site-item {
  margin-bottom: 55px;
  @media (min-width: 1024px) {
    margin-bottom: 110px;
  }
}
</style>
