<template>
  <div v-if="pageLoaded" class="section">
    <kk-breadcrumbs
      :items="breadcrumbsItems"
      class="pt-2 mb-5 mb-sm-10 stick-to-menu"
    />

    <page-title :title="pageData.fields.title" class="mb-5 mb-md-10">
      <v-col cols="12" class="textSecondary--text mt-md-6 caption-common">
        {{ formatDate(pageData.fields.date) }}
      </v-col>
    </page-title>

    <kk-dynamic-html
      v-if="(pageData.fields.article || '').length > 0"
      :html="pageData.fields.article"
      class="mb-5 mb-md-10"
    />

    <v-row class="ma-n2 ma-md-n5 justify-start align-center">
      <v-col
        v-for="item in pageData.children"
        :key="item.id"
        class="pa-2 pa-md-5 flex-grow-0"
        :cols="item.type == 'line' ? 12 : 0"
      >
        <div
          v-if="item.type == 'photo'"
          class="clickable"
          @click="zoomItem(item.index)"
        >
          <image-extended
            :image="item.image"
            content-class="news-image-container"
            class="mb-4"
            :max-height="$vuetify.breakpoint.mdAndUp ? '400' : '40vw'"
            :max-width="$vuetify.breakpoint.mdAndUp ? '400' : '40vw'"
            thumb-size="400"
            :contain="true"
            fill="#ffffff"
            eager
          />
        </div>
        <h5 v-else-if="item.type == 'line'">{{ item.title }}</h5>
      </v-col>
      <v-col cols="12">
        <infinite-loader
          :fetch="fetchMore"
          :disabled="disableInfiniteScroll"
          :visible="pageData.more > 0 || !pageLoaded"
        />
      </v-col>
    </v-row>

    <zuruck />

    <v-dialog
      v-model="zoomDialog"
      content-class="zoom-dialog"
      :overlay-opacity="0.8"
    >
      <div
        class="zoom-control zoom-close clickable biennaleTetriary"
        @click="zoomDialog = false"
      >
        <v-icon size="36">$vuetify.icons.Close</v-icon>
      </div>
      <div class="zoom-title biennaleTetriary">
        {{ getCurrentItemTitle }}
      </div>
      <div id="zoom-navigate-modal-prev-container">
        <div v-if="zoomDialog" id="zoom-navigate-modal-prev">
          <div
            class="zoom-control clickable biennaleTetriary"
            style="transform: rotateZ(180deg)"
          >
            <v-icon size="32">$vuetify.icons.Arrrow</v-icon>
          </div>
        </div>
      </div>
      <div id="zoom-navigate-modal-next-container">
        <div v-if="zoomDialog" id="zoom-navigate-modal-next">
          <div class="zoom-control clickable biennaleTetriary">
            <v-icon size="32">$vuetify.icons.Arrrow</v-icon>
          </div>
        </div>
      </div>
      <div class="zoom-panel">
        <div id="zoom-modal-zoom-out">
          <div
            class="zoom-control zoom-panel-control clickable biennaleTetriary"
          >
            <v-icon size="32">$vuetify.icons.ZoomOut</v-icon>
          </div>
        </div>
        <div id="zoom-modal-zoom-in" class="ml-5">
          <div
            class="zoom-control zoom-panel-control clickable biennaleTetriary"
          >
            <v-icon size="32">$vuetify.icons.ZoomIn</v-icon>
          </div>
        </div>
      </div>
      <div id="openseadragon-modal" class="zoom-dialog__scrollbox" />
    </v-dialog>
  </div>
</template>

<script>
import MediaLibraryRepository from "@/api/media.js";
import PageTitle from "@/components/PageTitle.vue";
import KkBreadcrumbs from "@/components/Article/KkBreadcrumbs.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import ImageExtended from "@/components/ImageExtended.vue";
import InfiniteLoader from "@/components/InfiniteLoader.vue";
import moment from "moment";
import Zuruck from "@/components/Zuruck.vue";
import OpenSeadragon from "openseadragon";
import cloneDeep from "lodash/cloneDeep";

export default {
  components: {
    PageTitle,
    KkBreadcrumbs,
    KkDynamicHtml,
    ImageExtended,
    InfiniteLoader,
    Zuruck,
  },
  props: {
    ident: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        main_fields: {},
        media_fields: {},
        fields: {},
        children: [],
        more: 0,
      },
      offset: 0,
      maxIndex: 0,
      loading: true,
      zoomDialog: false,
      zoomViewer: null,
    };
  },
  computed: {
    breadcrumbsItems() {
      return [
        {
          text: this.pageData.main_fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.media_fields.title || "",
          to: { name: "MediaLibraryList" },
          exact: true,
          disabled: false,
        },
      ];
    },
    tiles() {
      return this.pageData.children.reduce((tiles, item) => {
        if (item.type == "photo") {
          tiles.push({
            type: "image",
            url: item.image.i_main,
            title: item.title,
          });
        }
        return tiles;
      }, []);
    },
    disableInfiniteScroll() {
      return this.loading || this.pageData.more == 0;
    },
    formatDate() {
      return (date) => moment(date).format("D MMMM yyyy");
    },
    getCurrentItemTitle() {
      if (!this.zoomViewer) return "";
      const index = this.zoomViewer._sequenceIndex;
      if (isNaN(index)) return "";
      const tile = this.tiles[index] || {};
      return tile.title || "";
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
    zoomDialog(newV) {
      if (!newV) {
        this.zoomViewer.destroy();
        this.zoomViewer = null;
      }
    },
  },
  created() {
    window.addEventListener("keydown", this.onKeyDown);
  },
  destroyed() {
    window.removeEventListener("keydown", this.onKeyDown);
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      await this.queryItems();
      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      this.$emit("page-loaded");
      this.loading = false;
    },
    async queryItems() {
      const { data } = await MediaLibraryRepository.getAlbumContent(this.ident);
      this.offset = data.children.length;
      this.pageData = data;
    },
    async fetchMore() {
      this.loading = true;

      const queryParams = { offset: this.offset };

      const { data } = await MediaLibraryRepository.getAlbumContent(
        this.ident,
        queryParams
      );

      this.offset += data.children.length;
      data.children.forEach(async (element) => {
        const index = this.pageData.children.length;
        this.$set(this.pageData.children, index, element);
        if (this.zoomDialog && this.zoomViewer && element.type == "photo") {
          this.zoomViewer.tileSources.push({
            title: element.title,
            type: "image",
            url: element.image.i_main,
          });
        }
      });
      this.pageData.more = data.more;

      this.loading = false;
    },
    async zoomItem(index) {
      if (index >= this.tiles.length - 2) {
        if (this.pageData.more > 0 && !this.loading) {
          await this.fetchMore();
        }
      }

      this.zoomDialog = true;
      await this.$nextTick();

      this.zoomViewer = OpenSeadragon({
        id: "openseadragon-modal",
        tileSources: cloneDeep(this.tiles),
        sequenceMode: true,
        initialPage: index,
        visibilityRatio: 1.0,
        constrainDuringPan: true,
        preload: true,
        zoomInButton: "zoom-modal-zoom-in",
        zoomOutButton: "zoom-modal-zoom-out",
        previousButton: "zoom-navigate-modal-prev",
        nextButton: "zoom-navigate-modal-next",
      });

      this.zoomViewer.addHandler("page", this.onViewerNavigate);

      return true;
    },

    onViewerNavigate(event) {
      if (!this.zoomViewer) return;
      if (event.page >= this.tiles.length - 2) {
        if (this.pageData.more > 0 && !this.loading) {
          this.fetchMore();
        }
      }
    },

    onKeyDown(e) {
      if (e.keyCode == 37) {
        this.zoomViewer?.goToPreviousPage();
      } else if (e.keyCode == 39) {
        this.zoomViewer?.goToNextPage();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.zoom-dialog__scrollbox {
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: grab;
}
</style>
