<template>
  <div v-if="pageLoaded">
    <div
      v-show="['ProgramList', 'ProgramListByTag'].includes($route.name)"
      class="section"
    >
      <page-title :title="pageData.fields.title" class="page-header-program" />

      <program-concept
        v-if="pageData.fields.concept_header"
        :payload="pageData.fields"
        class="subsection"
      />

      <v-row no-gutters class="mb-5 mb-md-8">
        <v-col cols="12" sm="" class="flex-grow-1">
          <v-select
            v-model="filters.direction"
            dense
            outlined
            :items="directionsList"
            item-value="id"
            item-text="text"
            attach="#program-direction-select-sub"
            :menu-props="{
              contentClass: 'kk-select-menu',
            }"
            append-icon="$vuetify.icons.ChevronDown"
            hide-details
            single-line
            tabindex="0"
            class="direction-selector"
          >
            <template v-slot:item="{ item }">
              <div class="py-1">
                {{ item.text }}
              </div>
            </template>
            <template v-slot:selection="{ item }">
              <div class="py-1 program-direction-selection">
                {{ item.text }}
              </div>
            </template>
          </v-select>
          <div id="program-direction-select-sub" />
        </v-col>
        <v-col
          v-if="actualBiennaleIdent === biennale"
          cols="12"
          sm=""
          class="d-flex flex-grow-0 ml-md-8 order-first order-sm-last justify-end mb-5 mb-md-0"
        >
          <router-link
            :to="{ name: 'ProgramCalendar', params: { biennale } }"
            class="d-flex align-center button flex-nowrap biennalePrimary--text calendar-link"
          >
            <span class="text-no-wrap">{{
              pageData.calendar.fields.title
            }}</span>
            <v-icon class="biennalePrimary--text ml-4" size="32"
              >$vuetify.icons.ArrowSplash</v-icon
            >
          </router-link>
        </v-col>
      </v-row>

      <chips-filters
        id="tags"
        v-model="filters.tags"
        :tags-dic="pageData.programTags"
        class="mb-5 mb-md-8"
      />

      <program-list-direction
        v-for="directionBlock in directionsFiltered"
        :key="directionBlock.node_name"
        :payload="directionBlock"
        :biennale="biennale"
        :browse-title="pageData.directions_fields.details_title"
        show-items
      />

      <zuruck />
    </div>

    <program-calendar
      v-show="$route.name === 'ProgramCalendar'"
      :biennale="biennale"
      :section-data="pageData.calendar"
      :date-begin="pageData.biennale_fields.date_begin"
      :date-end="pageData.biennale_fields.date_end"
      :tags="pageData.calendarTags"
    />

    <router-view
      v-show="$route.name === 'ProgramItem'"
      :key="$route.path"
      @page-loaded="$emit('page-loaded')"
    >
    </router-view>
  </div>
</template>

<script>
import ProgramRepository from "@/api/program.js";
import PageTitle from "@/components/PageTitle.vue";
import ProgramConcept from "@/components/Program/ProgramConcept.vue";
import Zuruck from "@/components/Zuruck.vue";
import ChipsFilters from "@/components/ChipsFilters.vue";
import ProgramListDirection from "@/components/Program/ProgramListDirection.vue";
import ProgramCalendar from "./ProgramCalendar.vue";
import cloneDeep from "lodash/cloneDeep";

export default {
  components: {
    PageTitle,
    ProgramConcept,
    Zuruck,
    ChipsFilters,
    ProgramListDirection,
    ProgramCalendar,
  },
  props: {
    biennale: {
      type: String,
      required: true,
    },
    tag: {
      type: String,
      default: "",
    },
  },
  metaInfo() {
    if (this.$route.name === "ProgramCalendar") {
      return {
        title: this.$store.getters.getWindowTitle(
          this.pageData.fields.calendar_title
        ),
      };
    } else {
      return {
        title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
      };
    }
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        children: [],
        biennale_fields: {},
        directions_fields: {},
        calendar: { fields: {}, children: [] },
        programTags: [],
        calendarTags: [],
      },
      scrollTop: {
        ProgramList: 0,
        ProgramListByTag: 0,
        ProgramCalendar: 0,
      },
      filters: {
        direction: "",
        tags: [],
      },
    };
  },
  computed: {
    directionsFiltered() {
      const { filters } = this;
      const directions = cloneDeep(this.pageData.children);
      const directionsMapped = directions.map((direction) => {
        const directionMatch =
          filters.direction == "" || direction.node_name == filters.direction;
        direction.children = directionMatch
          ? direction.children.filter((activity) => {
              return (
                filters.tags.length == 0 ||
                (activity.fields.tags || []).some((tag) =>
                  filters.tags.includes(tag.id)
                )
              );
            })
          : [];
        return direction;
      });
      return directionsMapped.filter(
        (direction) => direction.children.length > 0
      );
    },
    directionsList() {
      return [
        { id: "", text: this.$t("Common.allDirections") },
        ...this.pageData.children.map((item) => ({
          id: item.node_name,
          text: item.fields.title,
        })),
      ];
    },
    actualBiennaleIdent() {
      return this.$store.getters.getActualBiennaleIdent;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
    "$route.name"(newV, oldV) {
      // + disalbed scroll behaviour in router/index.js
      if (Object.keys(this.scrollTop).includes(oldV)) {
        this.scrollTop[oldV] = window.scrollY;
      }
      if (Object.keys(this.scrollTop).includes(newV)) {
        this.$nextTick(() => {
          if (this.tag.length > 0) {
            const element = document.getElementById("tags");
            if (element) {
              element.scrollIntoView({ behavior: "instant" });
            }
          } else {
            window.scrollTo(0, this.scrollTop[newV]);
          }
        });
      }
      this.checkTagSet();
    },
    biennale() {
      this.loadPage();
    },
  },
  mounted() {
    this.checkTagSet();
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await ProgramRepository.getProgramList(this.biennale);
      this.pageData = data;
      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      if (this.$route.name !== "ProgramItem") {
        const payload = this.tag.length > 0 ? { hash: "#tags" } : {};
        this.$emit("page-loaded", payload);
      }
    },
    checkTagSet() {
      if (this.tag.length > 0) {
        // если уже были заданы какие-то тэги
        if (this.filters.tags.length > 0) {
          // если пришли тэги другие, то надо сбросить Направление
          if (
            !(this.filters.tags[0] == this.tag && this.filters.tags.length == 1)
          ) {
            this.filters.direction = "";
          }
        }
        this.filters.tags = [this.tag];
      }
    },
  },
};
</script>
<style scoped lang="scss">
@media (min-width: 1024px) {
  .page-header {
    margin-bottom: 50px;
  }
}
.calendar-link:hover {
  text-decoration: underline;
}
#tags {
  margin-top: -60px;
  padding-top: 60px;
}
@media (min-width: 1024px) {
  #tags {
    margin-top: -100px;
    padding-top: 100px;
  }
}
.direction-selector {
  width: 100%;
  min-width: 100%;
  margin-left: 0;
  @media (min-width: 640px) {
    width: 350px;
    min-width: 350px;
    max-width: 350px;
  }
}
#program-direction-select-sub {
  position: relative;
}
.program-direction-selection {
  line-height: 140% !important;
}
</style>
