import Vue from "vue";
import Router from "vue-router";
import Store from "@/store";
import { sync as RouterSync } from "vuex-router-sync";
import { elementScrollIntoView } from "seamless-scroll-polyfill";

import routes from "./routes";

Vue.use(Router);

const sameParentView = [
  { from: "NewsItem", to: "NewsList" },
  { from: "MediaLibraryItem", to: "MediaLibraryList" },
  { from: "MediaLibraryListVideo", to: "MediaLibraryList" },
  { from: "MediaLibraryList", to: "MediaLibraryListVideo" },
  { from: "ProgramItem", to: "ProgramList" },
  { from: "ProgramItem", to: "ProgramCalendar" },
  { from: "ProgramCalendar", to: "ProgramList" },
  { from: "ProgramList", to: "ProgramCalendar" },
  { from: "Participant", to: "ParticipantsList" },
];

const isSameParentView = function (to, from) {
  return sameParentView.some(
    (entry) => entry.from == from.name && entry.to == to.name
  );
};

// Создание экземпляра Router
const SvRouter = new Router({
  mode: "history",
  base: process.env.VUE_APP_CLI_PUBLIC_PATH || undefined,
  routes,

  // При переходе скролить к верху страницы или к Хэшу / ID
  scrollBehavior(to) {
    if (to.hash) {
      const element = document.getElementById(to.hash.substring(1));
      if (element) {
        elementScrollIntoView(element, { behavior: "smooth" });
        return {};
      }
    }
  },
});

// Функция вызывемая при каждом обновлении маршрута
SvRouter.afterEach(async (to, from) => {
  if (
    to.name != "Search" &&
    !isSameParentView(to, from) &&
    to.path != from.path
  ) {
    Store.commit("setStartInternalLoading");
  }
  if (to.params?.biennale) {
    Store.commit("setCurrentBiennale", to.params.biennale);
  } else {
    Store.commit("clearCurrentBiennale");
  }
});

RouterSync(Store, SvRouter);
Vue.router = SvRouter;

export default SvRouter;
