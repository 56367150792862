<template>
  <div>
    <section-title
      :title="mainFields.title_about"
      :browse-title="mainFields.browse_all_about"
      :browse-link="{ name: 'About' }"
      class="mb-6 mb-md-12"
    />

    <v-row class="subsection ma-n5 ma-md-n10">
      <v-col cols="12" md="6" class="pb-2 px-5 pt-5 pa-md-10">
        <kk-dynamic-html class="" :html="sectionData.fields.annotation_text" />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="d-flex flex-column justify-stretch pt-0 px-5 pb-5 pa-md-10"
      >
        <div class="flex-grow-1 mb-3 mb-md-4">
          <image-extended
            :image="sectionData.fields.annotation_image"
            class="about__image"
          />
        </div>
        <kk-dynamic-html
          class="caption-common"
          :html="sectionData.fields.annotation_image_text"
        />
      </v-col>
    </v-row>

    <horizontal-scroller :items="biennaleItems" content-class="ml-n5">
      <template v-slot="{ item }">
        <div class="mx-5 scroll-item__container">
          <router-link-wrapper
            :node-name="item.disable_archive_section == '1' ? '' : '1'"
            :to="{ name: 'ArchiveItem', params: { biennale: item.ident } }"
            draggable="false"
          >
            <image-extended
              :image="item.archive_image"
              :width="maxWidth"
              :height="maxHeight"
            />
          </router-link-wrapper>
        </div>
      </template>
    </horizontal-scroller>
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import ImageExtended from "@/components/ImageExtended.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import RouterLinkWrapper from "@/components/RouterLinkWrapper.vue";

export default {
  name: "MainAbout",
  components: {
    SectionTitle,
    ImageExtended,
    KkDynamicHtml,
    HorizontalScroller,
    RouterLinkWrapper,
  },
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
    mainFields: {
      type: Object,
      required: true,
    },
  },
  computed: {
    maxWidth() {
      return this.$vuetify.breakpoint.mdAndUp ? 200 : 100;
    },
    maxHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? 200 : 100;
    },
    biennaleItems() {
      const actualBiennale = this.$store.getters.getActualBiennaleIdent;
      return Object.entries(this.$store.getters.getBiennaleIndex)
        .map((entry) => ({ ident: entry[0], ...entry[1] }))
        .filter(
          (item) =>
            (item.archive_image?.i_main || "").length > 0 &&
            item.ident != actualBiennale
        )
        .reverse();
    },
  },
};
</script>
<style lang="scss" scoped>
.about__image {
  width: 100%;
  height: 100%;
}
.scroll-item__container {
  display: flex;
  height: 100%;
  align-items: center;
}
</style>
