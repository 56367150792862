<template>
  <div>
    <div
      v-if="pageLoaded"
      v-show="$route.name === 'ParticipantsList'"
      class="section"
    >
      <page-title :title="pageData.fields.title">
        <v-col cols="12" sm="" class="activity-selector">
          <v-select
            v-model="filterActivity"
            dense
            outlined
            :items="activitiesDic"
            item-value="value"
            item-text="txt"
            attach="#participants-activity-select-sub"
            :menu-props="{
              contentClass: 'kk-select-menu',
            }"
            append-icon="$vuetify.icons.ChevronDown"
            :placeholder="$t('Common.allActivities')"
            hide-details
            single-line
            return-object
            tabindex="0"
            width="100%"
          >
            <template v-slot:item="{ item }">
              <div class="py-1">
                {{ item.txt }}
              </div>
            </template>
            <template v-slot:selection="{ item }">
              <div class="py-1 participants-activity-selection">
                {{ item.txt }}
              </div>
            </template>
          </v-select>
          <div id="participants-activity-select-sub" />
        </v-col>
      </page-title>

      <div v-for="group in groupsCompiled" :key="group.value">
        <h3 class="biennalePrimary--text mb-5 mb-md-10">{{ group.txt }}</h3>
        <participant
          v-for="participant in group.participants"
          :key="participant.id"
          :payload="participant"
          :avatar-copycat="pageData.fields.avatar_copycat"
        />
      </div>

      <zuruck />
    </div>

    <router-view
      v-show="$route.name === 'Participant'"
      :key="routerKey"
      @page-loaded="$emit('page-loaded')"
    ></router-view>
  </div>
</template>

<script>
import ParticipantsRepository from "@/api/participants.js";
import Participant from "./components/Participant.vue";
import PageTitle from "@/components/PageTitle.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    PageTitle,
    Participant,
    Zuruck,
  },
  props: {
    biennale: {
      type: String,
      default: "",
    },
    ident: {
      type: String,
      default: "",
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        activities: [],
        groups: [],
        participants: [],
      },
      scrollTop: {
        ParticipantsList: 0,
      },
      filterActivity: {
        value: "",
        txt: "",
        participants: [],
      },
    };
  },
  computed: {
    routerKey() {
      const to = this.$route;
      const key =
        to.matched && to.matched.length > 1 ? to.matched[0].path : to.path;
      return key.replace(/^\/.+\/participants\/?$/g, `/:biennale/participants`);
    },
    participantsIndex() {
      return this.pageData.participants.reduce((index, participant) => {
        index[participant.id] = participant;
        return index;
      }, {});
    },
    groupsCompiled() {
      const { filterActivity, pageData, participantsIndex } = this;
      return pageData.groups
        .map((groupEntry) => {
          const { value } = groupEntry;
          if (filterActivity.value != "") {
            groupEntry.participants = filterActivity.participants
              .map((id) => participantsIndex[id])
              .filter((item) => item.group_id == value);
          } else {
            groupEntry.participants = Object.values(
              pageData.participants
            ).filter((item) => item.group_id == value);
          }
          return groupEntry;
        })
        .filter((group) => group.participants.length > 0);
    },
    activitiesDic() {
      return [
        { value: "", txt: this.$t("Common.allActivities"), participants: [] },
        ...this.pageData.activities,
      ];
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
    async "$route.name"(newV, oldV) {
      // + disalbed scroll behaviour in router/index.js
      if (Object.keys(this.scrollTop).includes(oldV)) {
        this.scrollTop[oldV] = window.scrollY;
      }
      if (Object.keys(this.scrollTop).includes(newV)) {
        if (!this.pageLoaded) {
          await this.loadPage();
        }
        this.$nextTick(() => {
          window.scrollTo(0, this.scrollTop[newV]);
        });
      }
    },
    biennale() {
      this.resetPage();
      this.loadPage();
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      if (this.biennale) {
        const { data } = await ParticipantsRepository.getParticipantsList(
          this.biennale
        );
        this.pageData = data;
        this.$store.commit("setLangsAvailAll");
        this.pageLoaded = true;
        this.$emit("page-loaded");
      }
    },
    resetPage() {
      this.filterActivity = {
        value: "",
        txt: "",
        participants: [],
      };
    },
  },
};
</script>
<style scoped lang="scss">
@media (min-width: 1024px) {
  .page-header {
    margin-bottom: 50px;
  }
}
.calendar-link:hover {
  text-decoration: underline;
}
.activity-selector {
  width: 100%;
  min-width: 100%;
  margin-left: 0;
  @media (min-width: 640px) {
    width: 350px;
    min-width: 350px;
    max-width: 350px;
  }
}
#participants-activity-select-sub {
  position: relative;
}
.participants-activity-selection {
  line-height: 140% !important;
}
</style>
