<template>
  <div v-if="pageLoaded">
    <page-title :title="pageData.fields.title" />

    <kk-article v-if="pageLoaded" ref="refarticle" :contents="contents">
      <div
        v-for="block in pageData.children"
        :key="block.node_name"
        class="subsection"
      >
        <subsection-title
          v-if="
            block.class_name == 'biennale_visitors_sites' && biennale !== ''
          "
          :title="block.fields.title"
          :browse-title="pageData.sites_fields.title_all"
          :browse-link="{ name: 'Sites', params: { biennale } }"
          :header-id="block.node_name"
          class="mb-4 mb-sm-8"
        />
        <h3
          v-if="block.class_name !== 'biennale_visitors_sites'"
          :id="block.node_name"
          class="biennalePrimary--text mb-4 mb-sm-8"
        >
          {{ block.fields.title }}
        </h3>

        <kk-dynamic-html
          v-if="block.class_name == 'biennale_visitors_paragraph'"
          :html="block.fields.text"
        />

        <sites-list
          v-else-if="block.class_name == 'biennale_visitors_sites'"
          :list="pageData.sites"
          :biennale="biennale"
        />

        <visitors-prices
          v-else-if="block.class_name == 'biennale_visitors_ticket_section'"
          :section-data="block"
        />

        <visitors-faq
          v-else-if="block.class_name == 'biennale_visitors_faq_section'"
          :section-data="block"
        />
      </div>
    </kk-article>

    <zuruck with-aux-bar />
  </div>
</template>

<script>
import VisitorsRepository from "@/api/visitors.js";
import KkArticle from "@/components/Article/KkArticle.vue";
import PageTitle from "@/components/PageTitle.vue";
import SubsectionTitle from "@/components/SubsectionTitle.vue";
import SitesList from "@/components/Sites/SitesList.vue";
import VisitorsPrices from "./VisitorsPrices.vue";
import VisitorsFaq from "./VisitorsFaq.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    KkArticle,
    PageTitle,
    SubsectionTitle,
    SitesList,
    VisitorsPrices,
    VisitorsFaq,
    KkDynamicHtml,
    Zuruck,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        children: [],
        sites: [],
      },
    };
  },
  computed: {
    contents() {
      return this.pageData.children.reduce((contents, block) => {
        if (
          block.class_name == "biennale_visitors_sites" &&
          this.biennale === ""
        ) {
          return contents;
        }
        contents[block.node_name] = block.fields.title;
        return contents;
      }, {});
    },
    biennale() {
      return this.$store.getters.getActualBiennaleIdent;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await VisitorsRepository.getVisitors();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
      });
    },
  },
};
</script>
