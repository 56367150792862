import Repository from "@/plugins/axios";

export default {
  // Получить страницу партнёрской программы
  getPartnershipProgram(params) {
    return Repository.get(`/biennale/partnership`, { params });
  },
  // Получить страницу с описанием площадок
  getSites(biennaleName) {
    return Repository.get(`/biennale/${biennaleName}/sites`);
  },
  // Получить страницу команды
  getTeam(biennaleName) {
    return Repository.get(`/biennale/${biennaleName}/team`);
  },
};
