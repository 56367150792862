<template>
  <div class="screen-header-tip">
    <div class="screen-header-tip__arrow">
      <v-icon size="16" class="rot-90" color="biennalePrimary"
        >$vuetify.icons.Arrrow</v-icon
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "MainHeaderScrollTip",
};
</script>
<style lang="scss" scoped>
.screen-header-tip {
  position: fixed;
  width: 28px;
  height: 40px;
  border: 2px solid var(--v-biennalePrimary);
  border-radius: 14px;
  bottom: 40px;
  left: 50%;
  margin-left: -14px;
  z-index: 11;

  .screen-header-tip__arrow {
    position: absolute;
    top: -4px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    animation-delay: 0s;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-name: animate-topdown;

    .rot-90 {
      transform: rotateZ(90deg);
    }
  }
}

@keyframes animate-topdown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
