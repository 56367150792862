<template>
  <v-row class="section__logotype-band align-center justify-start mb-0">
    <v-col
      v-for="(logotype, index) in items"
      :key="index"
      cols="12"
      sm=""
      class="logotype__col d-flex justify-center px-5 flex-grow-0"
    >
      <v-img eager contain :src="logotype.src" class="logotype" />
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "LogotypeBand",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.section__logotype-band {
  .logotype__col {
    @media (max-width: 639px) {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }

    .logotype {
      max-height: 100px;
      max-width: 200px;
      margin: 0 auto;
      @media (min-width: 1024px) {
        max-width: 130px;
        max-height: 40px;
      }
      @media (min-width: 1280px) {
        max-width: 160px;
        max-height: 56px;
      }
      @media (min-width: 1440px) {
        max-width: 200px;
        max-height: 66px;
      }
      @media (min-width: 1920px) {
        max-width: 300px;
        max-height: 96px;
      }
      @media (min-width: 2300px) {
        max-width: 380px;
        max-height: 120px;
      }
    }
  }
}
</style>
