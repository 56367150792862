import Repository from "@/plugins/axios";

export default {
  // Искать на сайте по строке
  search(params) {
    return Repository.get("/biennale/search", { params });
  },
  notfound() {
    return Repository.get("/biennale/notfound");
  },
};
