<template>
  <v-list expand flat subheader class="menu-expansion-list border-top">
    <v-list-group
      v-for="(item, index) in items"
      :key="index"
      v-model="listExpand[`ind${index}`]"
      color="textBase"
      class="accordion__item"
    >
      <template v-slot:activator>
        <v-list-item-content class="ml-n4">
          <h4 class="accordion__header my-1 my-md-4">
            <slot name="header" :item="item" :index="index" />
          </h4>
        </v-list-item-content>
      </template>
      <template v-slot:appendIcon>
        <v-icon
          :size="$vuetify.breakpoint.mdAndUp ? 34 : 28"
          class="d-none d-sm-flex accordion__header__icon chevron mx-0"
          >$vuetify.icons.ChevronDownLarge</v-icon
        >
        <v-icon size="26" class="d-sm-none accordion__header__icon chevron mx-0"
          >$vuetify.icons.ChevronDown</v-icon
        >
      </template>

      <slot :item="item" :index="index" />
    </v-list-group>
  </v-list>
</template>

<script>
export default {
  name: "Accordion",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      listExpand: {},
    };
  },
};
</script>
<style scoped lang="scss">
.accordion__item {
  .accordion__header__icon {
    transform: rotate(-90deg);
  }
}
.accordion__item.v-list-group--active {
  .accordion__header,
  .accordion__header__icon {
    color: var(--v-biennalePrimary) !important;
  }
  .accordion__header__icon {
    transform: rotate(0deg);
  }
}
.accordion__item:hover {
  .accordion__header,
  .accordion__header__icon {
    color: var(--v-biennalePrimary) !important;
  }
}
</style>
