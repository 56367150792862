<template>
  <div class="section">
    <div v-if="sectionData.biennale_program_section">
      <section-title
        :title="sectionData.biennale_program_section.fields.title"
        :browse-title="$t('Common.searchPage.browseAllActivities')"
        :browse-link="{ path: `/${biennale}/program` }"
        class="mb-6 mb-md-12"
      />

      <div>
        <program-list-item
          v-for="activity in sectionData.biennale_program_section.children"
          :key="activity.node_name"
          :payload="activity.fields"
          :biennale="biennale"
          :ident="activity.node_name"
        />
        <div class="shader__container">
          <div class="shader" />
          <browse-button
            :browse-title="$t('Common.searchPage.moreActivities')"
            :browse-link="{ path: `/${biennale}/program` }"
            class="justify-center"
          />
        </div>
      </div>
    </div>

    <section-title
      :title="sectionData.biennale_news_section.fields.title"
      :browse-title="$t('Common.searchPage.browseAllNews')"
      :browse-link="{ name: 'NewsList' }"
      class="mb-6 mb-md-12"
    />

    <v-row>
      <v-col cols="12" xl="6" class="pa-md-5">
        <news-list-item :payload="news(0)" kind="first" />
      </v-col>
      <v-col cols="12" xl="6" class="pa-md-5">
        <v-row class="ma-md-n5">
          <v-col cols="12" class="pa-md-5">
            <news-list-item :payload="news(1)" kind="second" />
          </v-col>
        </v-row>
        <v-row class="ma-md-n5">
          <v-col cols="12" sm="6" class="pa-md-5">
            <news-list-item :payload="news(2)" kind="third-fourth" />
          </v-col>
          <v-col cols="12" sm="6" class="pa-md-5">
            <news-list-item :payload="news(3)" kind="third-fourth" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import BrowseButton from "@/components/BrowseButton.vue";
import ProgramListItem from "@/components/Program/ProgramListItem.vue";
import NewsListItem from "@/components/News/NewsListItem.vue";

export default {
  name: "SearchAlter",
  components: {
    SectionTitle,
    BrowseButton,
    ProgramListItem,
    NewsListItem,
  },
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    news() {
      return (index) => this.sectionData.biennale_news_section.children[index];
    },
    biennale() {
      return this.$store.getters.getActualBiennaleIdent;
    },
  },
};
</script>
<style lang="scss" scoped>
.shader__container {
  position: relative;

  .shader {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    z-index: 5;
    pointer-events: none;
  }
}
</style>
