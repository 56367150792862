<template>
  <div class="kk-aux-menu" :class="expanded ? 'kk-aux-menu--expanded' : ''">
    <auxiliary-menu-button
      v-if="expanded"
      :title="closeTitle"
      :icon="iconClose"
      @click="toggleMenu(false)"
    />
    <auxiliary-menu-button
      v-else
      :title="title"
      :icon="icon"
      @click="toggleMenu(true)"
    />
    <transition name="slideup">
      <div v-show="expanded" class="kk-menu-content d-md-none">
        <slot :collapse="onCollapse" />
      </div>
    </transition>
  </div>
</template>

<script>
import AuxiliaryMenuButton from "./AuxiliaryMenuButton.vue";
export default {
  name: "AuxiliaryMenu",
  components: { AuxiliaryMenuButton },
  props: {
    title: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    titleClose: {
      type: String,
      default: "",
    },
    iconClose: {
      type: String,
      default: "$vuetify.icons.Close",
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    closeTitle() {
      return this.titleClose || this.$t("Common.auxMenuClose");
    },
  },
  methods: {
    toggleMenu(value) {
      this.expanded = value;
      if (value) {
        document.querySelector("html").classList.add(`popup-aux-menu`);
      } else {
        document.querySelector("html").classList.remove(`popup-aux-menu`);
      }
      this.$emit("toggle", value);
    },
    onCollapse() {
      this.toggleMenu(false);
    },
  },
};
</script>
<style scoped lang="scss">
.kk-aux-menu {
  display: flex;
  align-items: stretch;
  flex-grow: 1;

  .kk-menu-content {
    position: fixed;
    top: 42px;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 8;
    min-width: 320px;
    overflow-y: auto;
    padding: 12px;
    background-color: var(--v-bgSecondary-base);
  }

  .slideup-enter-active {
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
  }

  .slideup-leave-active {
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  .slideup-enter-to,
  .slideup-leave {
    top: 42px;
    overflow: hidden;
  }

  .slideup-enter,
  .slideup-leave-to {
    top: calc(100% - 56px);
    overflow: hidden;
  }
}
</style>
