<template>
  <v-row no-gutters class="align-start mb-0">
    <v-col cols="12" md="" class="flex-grow-md-1">
      <horizontal-scroller
        :items="tagsDic"
        :disabled="$vuetify.breakpoint.mdAndUp"
        content-class="mx-n2"
        class="mb-0"
        disable-spacing
      >
        <template v-slot:prepend>
          <v-col class="flex-grow-0">
            <tag-chip
              :input-value="tags.length == 0"
              class="my-2 mx-2"
              @click="onTagsReset"
            >
              {{ $t("Common.allTags") }}
            </tag-chip>
          </v-col>
        </template>

        <template v-slot="{ item }">
          <tag-chip
            :input-value="tags.some((ct) => ct == (item.id || item))"
            class="my-2 mx-2"
            @click="onTagToggle(item.id || item)"
          >
            {{ item.text || item }}
          </tag-chip>
        </template>
      </horizontal-scroller>
    </v-col>
    <slot> </slot>
  </v-row>
</template>

<script>
import TagChip from "@/components/Tags/TagChip.vue";
import HorizontalScroller from "./HorizontalScroller.vue";
import xor from "lodash/xor";

export default {
  name: "ChipsFilters",
  components: { TagChip, HorizontalScroller },
  props: {
    value: {
      type: Array,
      required: true,
    },
    tagsDic: {
      type: Array,
      required: true,
    },
  },
  computed: {
    tags: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    onTagsReset() {
      this.tags = [];
      this.$emit("change");
    },
    onTagToggle(tag) {
      this.tags = xor(this.tags, [tag]);
      this.$emit("change");
    },
  },
};
</script>
