<template>
  <div v-if="pageLoaded" class="section">
    <page-title :title="pageData.fields.title" />
    <kk-article v-if="pageLoaded" ref="refarticle" :contents="contents">
      <kk-dynamic-html
        :html="pageData.fields.article"
        class="partnership-paragraph"
      />

      <div
        v-for="paragraph in pageData.children"
        :key="paragraph.node_name"
        class="partnership-paragraph"
      >
        <h3
          :id="paragraph.node_name"
          class="partnership-program__heading biennalePrimary--text"
        >
          {{ paragraph.fields.title }}
        </h3>

        <kk-dynamic-html
          :html="paragraph.fields.article"
          class="mb-5 mb-md-10"
        />

        <div
          v-if="paragraph.fields.href_title && paragraph.fields.href_link"
          class="d-flex justify-end"
        >
          <a
            :href="paragraph.fields.href_link"
            class="d-flex align-center button"
          >
            <span>{{ paragraph.fields.href_title }}</span>
            <v-icon size="26" class="ml-4">$vuetify.icons.Arrrow</v-icon>
          </a>
        </div>

        <div
          v-if="paragraph.children?.length > 0"
          class="partnership-contacts pt-5 pt-md-10 d-flex flex-wrap align-start"
        >
          <div class="flex-grow-1 h4 textSecondary--text mb-5 mb-md-10">
            {{ paragraph.fields.contacts_title }}
          </div>
          <div class="flex-grow-0">
            <person
              v-for="(person, index) in paragraph.children"
              :key="index"
              :name="person.fields.title"
              :role="person.fields.position"
              :avatar="person.fields.photo"
              :phone="person.fields.phone"
              :email="person.fields.email"
              hide-details
              class="mb-5 mb-md-10"
            />
          </div>
        </div>
      </div>
    </kk-article>

    <zuruck with-aux-bar />
  </div>
</template>

<script>
import SimpleRepository from "@/api/simple.js";
import PageTitle from "@/components/PageTitle.vue";
import KkArticle from "@/components/Article/KkArticle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import Zuruck from "@/components/Zuruck.vue";
import Person from "@/components/Person.vue";

export default {
  components: { PageTitle, KkArticle, KkDynamicHtml, Zuruck, Person },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        children: [],
      },
    };
  },
  computed: {
    contents() {
      return this.pageData.children.reduce((contents, paragraph) => {
        contents[paragraph.node_name] = paragraph.fields.title;
        return contents;
      }, {});
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await SimpleRepository.getPartnershipProgram();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
      });
    },
  },
};
</script>
<style scoped lang="scss">
.partnership-paragraph {
  margin-bottom: 40px;

  @media (min-width: 1024px) {
    margin-bottom: 80px;
  }

  .partnership-program__heading {
    margin-bottom: 40px;
  }

  .partnership-contacts {
    border-top: 1px solid var(--v-line-base);
    border-bottom: 1px solid var(--v-line-base);
  }
}
</style>
