<template>
  <v-row no-gutters>
    <v-col cols="12">
      <kk-dynamic-html :html="blockData.fields.article" />
    </v-col>
    <v-col class="flex-grow-0 px-10">
      <image-extended :image="blockData.fields.award" class="award-image" />
    </v-col>
    <v-col cols="12" xl="" class="flex-grow-1 award-title">
      <kk-dynamic-html :html="blockData.fields.award_title" />
    </v-col>
  </v-row>
</template>
<script>
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import ImageExtended from "@/components/ImageExtended.vue";

export default {
  name: "AboutAwards",
  components: { KkDynamicHtml, ImageExtended },
  props: {
    blockData: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.award-image {
  max-width: 165px;
  margin-bottom: 40px;
}
@media (min-width: 1024px) {
  .award-image {
    margin-bottom: 80px;
  }
}
@media (min-width: 1440px) {
  .award-image {
    margin-bottom: 0;
  }
  .award-title {
    margin-left: 80px;
  }
}
</style>
