<template>
  <div class="img-header stick-to-body stick-to-menu" :data-glow="glow">
    <div id="header-image">
      <image-extended
        :image="image"
        :position="image.position"
        class="img-header-img"
        :style="imgStyle"
        eager
      />
      <video-background
        :src="video"
        class="img-header-video"
        :style="imgStyle"
      />
      <div
        v-if="glow == '1'"
        ref="refglow"
        class="glow"
        :style="glowStyle"
        @mousemove="onMouseMove"
        @mouseleave="onMouseOut"
      />
    </div>
  </div>
</template>

<script>
import ProgressObserverMixin from "@/mixins/ProgressObserverMixin.js";
import ImageExtended from "@/components/ImageExtended.vue";
import VideoBackground from "@/components/VideoBackground.vue";

export default {
  components: { ImageExtended, VideoBackground },
  mixins: [ProgressObserverMixin],
  props: {
    image: {
      type: Object,
      default: () => {},
    },
    glow: {
      type: String,
      default: "0",
    },
    video: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      glowRadius: 1000,
      glowCoord: {
        x: null,
        y: null,
      },
      obvOptions: {
        viewportTopPct: 0,
        viewportBottomPct: 0,
        pivotBegin: "top",
        pivotEnd: "bottom",
        offsetFromMenu: true,
      },
    };
  },
  computed: {
    glowStyle() {
      const { x, y } = this.glowCoord;
      const { imgStyle } = this;
      if (x == null || y == null) {
        return {
          ...imgStyle,
          "background-color": "black",
        };
      } else {
        return {
          ...imgStyle,
          background: `radial-gradient(${this.glowRadius}px
            circle at ${x * 100}% ${y * 100}%,
            rgba(255, 255, 255, 0.25) 0%,
            rgba(255, 255, 255, 0.1) 10%,
            transparent 16%,
            black 40%`,
        };
      }
    },
    imgStyle() {
      const pct = 1 - this.obv("header-image");
      if (pct == 1 || !this.$vuetify.breakpoint.mdAndUp) {
        return {};
      }
      return {
        transform: `scale(${pct})`,
      };
    },
  },
  methods: {
    initObserver() {
      this.obvAddElement("header-image");
    },
    onMouseMove(event) {
      if (this.glow == "1" && this.$refs.refglow) {
        const rect = this.$refs.refglow.getBoundingClientRect();
        this.glowCoord.x = (event.clientX - rect.left) / rect.width;
        this.glowCoord.y = (event.clientY - rect.top) / rect.height;
      }
    },
    onMouseOut() {
      this.glowCoord.x = this.glowCoord.y = null;
    },
  },
};
</script>
<style scoped lang="scss">
.img-header {
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;

  #header-image {
    position: relative;
    &,
    & .img-header-img {
      height: calc(100vh - var(--menu-height));
    }
    .img-header-img {
      transform-origin: bottom center;
    }
    .img-header-video {
      height: calc(100vh - var(--menu-height));
      transform-origin: bottom center;
    }
  }

  @media (max-width: 1024px) {
    margin-bottom: 10px;
  }
}
.glow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 3;
  transform-origin: bottom center;
}
</style>
