<template>
  <div v-if="!!serviceId" class="ticket-widget-tonline">
    <div v-if="loading" class="ticket-widget-tonline__preloader" />
    <div v-else>
      <v-expansion-panels
        v-model="expanded"
        tile
        flat
        class="menu-expansion-list"
        :readonly="breakpoint"
      >
        <v-expansion-panel>
          <v-expansion-panel-header
            v-show="!breakpoint"
            expand-icon="$vuetify.icons.ChevronDown"
          >
            <span class="base">{{
              ticketWidgetSection.fields.button_buy_ticket
            }}</span>
          </v-expansion-panel-header>
          <h4 v-show="breakpoint" class="mb-6">
            {{ ticketWidgetSection.fields.button_buy_ticket }}
          </h4>
          <v-expansion-panel-content>
            <div v-for="(ticket, index) in tickets" :key="index" class="mb-6">
              <v-row>
                <v-col cols="12">
                  <kk-date-picker
                    v-model="ticket.date"
                    :allowed-dates="schedule.allowedDates()"
                    :min="schedule.minDateFormatted()"
                    :max="schedule.maxDateFormatted()"
                    show-adjacent-months
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div id="ticket-visitor-selector">
                    <v-select
                      v-model="ticket.visitor"
                      dense
                      outlined
                      :items="selectableCategories"
                      item-value="id"
                      item-text="text"
                      :attach="
                        $vuetify.breakpoint.smAndUp
                          ? '#ticket-visitor-selector'
                          : false
                      "
                      :menu-props="{
                        nudgeBottom: $vuetify.breakpoint.smAndUp ? 53 : 40,
                        contentClass: 'kk-select-menu',
                      }"
                      append-icon="$vuetify.icons.ChevronDown"
                      hide-details
                    />
                  </div>
                </v-col>
              </v-row>
              <v-row class="align-center">
                <v-col>
                  <h4>{{ calcPrice(ticket) }} ₽</h4>
                </v-col>
                <v-col class="input-count">
                  <v-text-field
                    v-model="ticket.count"
                    dense
                    outlined
                    readonly
                    append-outer-icon="$vuetify.icons.Plus"
                    prepend-icon="$vuetify.icons.Minus"
                    class="text-field-centered"
                    hide-details
                    @click:append-outer="countIncrease(ticket)"
                    @click:prepend="countDecrease(ticket)"
                  />
                </v-col>
              </v-row>
            </div>
            <div>
              <v-btn outlined tile block @click="goBuyTicket">
                {{ ticketWidgetSection.fields.button_pay_ticket }}
              </v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import KkDatePicker from "@/components/Controls/KkDatePicker.vue";
export default {
  name: "KkTicketWidgetTonline",
  components: { KkDatePicker },
  props: {
    serviceId: {
      type: [String, Number],
      required: true,
    },
    schedule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tickets: [
        {
          date: "",
          visitor: "",
          count: 1,
        },
      ],
      expandedPanel: null,
    };
  },
  computed: {
    expanded: {
      get() {
        return this.breakpoint ? 0 : this.expandedPanel;
      },
      set(v) {
        this.expandedPanel = v;
      },
    },
    breakpoint() {
      return !this.$vuetify.breakpoint.smAndUp;
    },
    ticketWidgetSection() {
      return this.$store.getters.getTicketWidget;
    },
    loading() {
      return !this.service;
    },
    service() {
      if (!this.serviceId) return undefined;
      return this.$store.getters.getService(this.serviceId);
    },
    selectableCategories() {
      if (!this.service || !this.service.Price) return [];
      const allCategories = this.$store.getters.getAllCategories;
      return this.service.Price.map((item) => {
        const id = item.CategoryVisitorId;
        const cost = item.cost;
        const text = `${allCategories[id]} ${item.Cost} ₽`;
        return { id, cost, text };
      });
    },
  },
  mounted() {
    if (this.service) {
      this.tickets[0].date = this.schedule.minDateFormatted();
    }
    if (this.selectableCategories.length > 0) {
      this.tickets[0].visitor = this.selectableCategories[0]["id"];
    }
  },
  methods: {
    calcPrice(ticket) {
      const costBlock = this.service.Price.find(
        (item) => item.CategoryVisitorId == ticket.visitor
      );
      if (!costBlock) return 0;
      return costBlock.Cost * ticket.count;
    },
    countIncrease(ticket) {
      if (ticket.count == 99) return false;
      ticket.count++;
    },
    countDecrease(ticket) {
      if (ticket.count == 1) return false;
      ticket.count--;
    },
    goBuyTicket() {
      window.alert("Buy ticket enquire trigger");
    },
  },
};
</script>
<style lang="scss">
.theme--light.v-expansion-panels.menu-expansion-list {
  border-top: 1px solid var(--v-line-base);
  border-bottom: 1px solid var(--v-line-base);
  background-color: transparent;

  @media (max-width: 1023px) {
    border: none;
  }

  .v-expansion-panel {
    background-color: transparent;
  }

  & .v-expansion-panel--active > .v-expansion-panel-header {
    min-height: inherit;
  }

  .v-expansion-panel-header {
    padding: 24px 0;
    .v-expansion-panel-header__icon .v-icon {
      color: var(--v-textBase-base);
    }
  }

  .v-expansion-panel-content__wrap {
    padding: 0 0 24px 0;
  }

  .input-count {
    min-width: 190px;
    max-width: 190px;
  }

  #ticket-visitor-selector {
    position: relative;
  }
}
</style>
