<template>
  <div class="section">
    <page-title :title="sectionData.fields.title" class="page-header-program" />

    <chips-filters v-model="tagsFilters" :tags-dic="tags">
      <v-col
        cols="12"
        md=""
        class="d-flex flex-grow-0 ml-md-8 order-first order-md-last justify-end mb-5 mb-md-0"
      >
        <router-link
          :to="{ path: `/${biennale}/program` }"
          class="d-flex align-center button flex-nowrap biennalePrimary--text calendar-link"
        >
          <span class="text-no-wrap">{{
            sectionData.fields.program_all_title
          }}</span>
          <v-icon class="biennalePrimary--text ml-4" size="32"
            >$vuetify.icons.ArrowSplash</v-icon
          >
        </router-link>
      </v-col>
    </chips-filters>

    <calendar-band
      v-model="dayFilter"
      :begin-biennale="beginBiennale"
      :end-biennale="endBiennale"
    />

    <program-list-item
      v-for="(activity, index) in activitiesFiltered"
      :key="`${activity.node_name}_${index}`"
      :payload="activity"
      :biennale="biennale"
      :ident="activity.node_name"
    />

    <zuruck />
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import ChipsFilters from "@/components/ChipsFilters.vue";
import Zuruck from "@/components/Zuruck.vue";
import CalendarBand from "./components/CalendarBand.vue";
import ProgramListItem from "@/components/Program/ProgramListItem.vue";
import moment from "moment";

export default {
  components: {
    PageTitle,
    ChipsFilters,
    Zuruck,
    CalendarBand,
    ProgramListItem,
  },
  props: {
    biennale: {
      type: String,
      required: true,
    },
    sectionData: {
      type: Object,
      required: true,
    },
    dateBegin: {
      type: String,
      required: true,
    },
    dateEnd: {
      type: String,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dayFilter: moment().format("yyyy-MM-DD"),
      tagsFilters: [],
    };
  },
  computed: {
    beginBiennale() {
      return moment(this.dateBegin);
    },
    endBiennale() {
      return moment(this.dateEnd);
    },
    activitiesFiltered() {
      const { tagsFilters, dayFilter, sectionData } = this;
      const dayRecord = sectionData.children.find(
        (item) => item.day == dayFilter
      );
      if (!dayRecord) return [];
      if (tagsFilters.length == 0) {
        return dayRecord.times;
      }
      return dayRecord.times.filter((item) =>
        (item.tags || []).some((tag) => tagsFilters.includes(tag.id))
      );
    },
  },
};
</script>
<style scoped>
@media (min-width: 1024px) {
  .page-header {
    margin-bottom: 50px;
  }
}
.calendar-link:hover {
  text-decoration: underline;
}
</style>
