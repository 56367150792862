<template>
  <div
    v-if="biennale"
    id="motion-container"
    class="main-header-scrollbox stick-to-body stick-to-menu"
    :class="{ 'intro-enabled': introEnabled }"
  >
    <div id="main-image-poster" class="screen-motion__sticky">
      <div class="screen-motion screen-motion__logo" :style="motionLogoStyle">
        <main-header-intro-logo :biennale="biennale" />
      </div>

      <div class="screen-motion screen-motion__intro" :style="motionIntroStyle">
        <div
          class="screen-motion__intro--bg"
          :style="motionIntroElementOpacity"
        />

        <div class="intro__poster__collapsible" :style="motionIntroPosterSize">
          <div class="intro__poster flex-grow-1">
            <div class="intro__poster--sub">
              <image-extended
                :image="biennale.main_image"
                class="intro__poster__image"
              />
            </div>
          </div>

          <div
            class="intro__assets pt-6 pb-5 padding-from-body d-flex flex-column flex-md-row align-center justify-space-between flex-wrap"
            :style="motionIntroElementOpacity"
          >
            <main-header-intro-logo-masked :biennale="biennale" class="my-2" />
            <div class="my-8 my-md-2">
              <h3 class="mb-2 text-center text-md-left biennale__title">
                <span
                  v-if="biennaleIdent !== actualBiennaleIdent"
                  class="archive-shaded-text"
                  >{{ $t("Common.archive") }}:</span
                >
                {{ biennale.title }}
              </h3>
              <div class="caption-common text-center text-md-right">
                {{ biennaleDates.from }} — {{ biennaleDates.to }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <main-header-scroll-tip :style="scrollTipStyle" />
  </div>
</template>
<script>
import MainHeaderIntroLogo from "./MainHeaderIntroLogo.vue";
import MainHeaderIntroLogoMasked from "./MainHeaderIntroLogoMasked.vue";
import MainHeaderScrollTip from "./MainHeaderScrollTip.vue";
import moment from "moment";
import ImageExtended from "@/components/ImageExtended.vue";

export default {
  name: "MainHeader",
  components: {
    MainHeaderIntroLogo,
    MainHeaderIntroLogoMasked,
    ImageExtended,
    MainHeaderScrollTip,
  },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
    obvm: {
      type: Number,
      required: true,
    },
    biennaleIdent: {
      type: String,
      required: true,
    },
  },
  computed: {
    motionIntroPosterSize() {
      if (!this.$vuetify.breakpoint.mdAndUp) {
        return {};
      }
      const scaleMin = 0.5;
      const scaleMax = 1;
      const scale =
        1 -
        Math.max(
          Math.min((this.obvm - scaleMin) / (scaleMax - scaleMin), 1),
          0
        );
      return {
        transform: `scale(${scale})`,
      };
    },
    motionIntroElementOpacity() {
      const pct = Math.min(this.obvm * 2, 1);
      const sqr = pct * pct;
      const opacityMin = 0.8;
      const opacityMax = 1;
      const opacity = Math.max(
        Math.min((sqr - opacityMin) / (opacityMax - opacityMin), 1),
        0
      );
      return {
        opacity,
      };
    },

    motionIntroStyle() {
      const pct = Math.min(this.obvm * 2, 1);
      const scaleMin = 0;
      const scaleMax = 0.8;
      const opacityMin = 0.3;
      const opacityMax = 0.5;
      const sqr = pct * pct;
      const scale = Math.max(
        Math.min((sqr - scaleMin) / (scaleMax - scaleMin), 1),
        0
      );
      const opacity = Math.max(
        Math.min((pct - opacityMin) / (opacityMax - opacityMin), 1),
        0
      );
      const margin = this.offsetFromMenu * (1 - scale);

      return {
        transform: `scale(${scale})`,
        "margin-top": `-${margin}px`,
        opacity,
      };
    },

    motionLogoStyle() {
      const scale = (1 + this.obvm * 8) * (1 + this.obvm * 8);
      const opacityMin = 0.2;
      const opacityMax = 0.25;
      const opacity =
        1 -
        Math.max(
          Math.min((this.obvm - opacityMin) / (opacityMax - opacityMin), 1),
          0
        );
      return {
        transform: `scale(${scale})`,
        opacity,
        display: opacity > 0 ? "flex" : "none",
      };
    },

    scrollTipStyle() {
      const opMin = 0;
      const opMax = 0.8;
      const pct = Math.min(this.obvm * 2, 1);
      const sqr = pct * pct;
      const finished = Math.max(
        Math.min((sqr - opMin) / (opMax - opMin), 1),
        0
      );
      return {
        display: finished >= 1 ? "none" : "block",
      };
    },

    offsetFromMenu() {
      return this.$vuetify.breakpoint.mdAndUp ? 70 : 46;
    },
    biennale() {
      return this.$store.getters.getBiennaleByIdent(this.biennaleIdent);
    },
    actualBiennaleIdent() {
      return this.$store.getters.getActualBiennaleIdent;
    },
    biennaleDates() {
      return {
        from: moment(this.biennale.date_begin).format("D MMMM yyyy"),
        to: moment(this.biennale.date_end).format("D MMMM yyyy"),
      };
    },
    introEnabled() {
      return this.$store.getters.isIntro;
    },
  },
};
</script>
<style lang="scss" scoped>
.main-header-scrollbox {
  position: relative;
  top: 0;
  left: 0;
  height: calc(100vh - var(--menu-height));

  &.intro-enabled {
    height: calc(200vh - var(--menu-height) - var(--menu-height));

    .screen-motion__sticky {
      padding-top: var(--menu-height);
      height: calc(100vh);
    }
  }
  .screen-motion__sticky {
    top: 0;
    position: sticky;
    width: 100%;
    height: calc(100vh - var(--menu-height));
    overflow: hidden;
    transform-origin: bottom center;

    .screen-motion {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .screen-motion__logo {
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
      transform-origin: center center;
    }

    .screen-motion__intro {
      color: #fff;

      .screen-motion__intro--bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: var(--v-biennaleSecondary);
        z-index: 2;
      }

      @media (max-width: 1023px) {
        justify-content: center;
        .biennale__title {
          max-width: 70vw;
        }
      }

      .intro__assets {
        position: relative;
        z-index: 3;
        width: 100%;
      }
    }

    .intro__poster__collapsible {
      position: relative;
      width: 100%;
      height: 100%;
      transform-origin: bottom center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      z-index: 3;
    }

    .intro__poster {
      @media (max-width: 1023px) {
        max-height: 33vh;
      }

      .intro__poster--sub {
        position: relative;
        width: 100%;
        height: 100%;

        .intro__poster__image {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
          z-index: 3;
        }
      }
    }
  }
}
.archive-shaded-text {
  color: rgba(255, 255, 255, 0.6);
}
</style>
