import BaseRepository from "@/api/base.js";

const baseFactory = () => {
  return {
    biennale_system_settings: {
      fields: {},
      biennale_system_settings_scripts: { fields: {}, children: [] },
    },
    biennale_header_section: { fields: {}, children: [] },
    biennale_footer_section: { fields: {}, children: [] },
    biennale_notfound_page: { fields: {}, news: {}, program: [] },
    biennale_social_section: { fields: {}, children: [] },
    ticket_widget_section: { fields: {} },
    lang_code: "",
    biennaleIndex: {},
    lastBiennaleIdent: "",
    actualBiennaleIdent: "",
  };
};

export default {
  state: {
    baseData: baseFactory(),
    isInit: false,
    isInternalNavigation: false,
    isMainLoading: true,
    isIntro: true,
    currentBiennaleName: "",
  },
  actions: {
    async fetchBase({ commit }) {
      try {
        const { data } = await BaseRepository.getBase();
        commit("setBaseData", data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
  getters: {
    getWindowTitle(state) {
      return (addTitle) => {
        if (!state.baseData.biennale_system_settings.fields.title)
          return undefined;
        return addTitle || state.baseData.biennale_system_settings.fields.title;
      };
    },
    getBiennaleIndex(state) {
      return state.baseData.biennaleIndex;
    },
    getBiennaleByIdent(state) {
      return (ident) => state.baseData.biennaleIndex[ident];
    },
    getActualBiennaleIdent(state) {
      return state.baseData.actualBiennaleIdent;
    },
    getLastBiennaleIdent(state) {
      return state.baseData.lastBiennaleIdent;
    },
    getActualBiennale(state) {
      return state.baseData.biennaleIndex[state.baseData.actualBiennaleIdent];
    },
    getCurrentBiennaleIdent(state) {
      return state.currentBiennaleName || state.baseData.actualBiennaleIdent;
    },
    getCurrentBiennale(state) {
      const ident =
        state.currentBiennaleName || state.baseData.actualBiennaleIdent;
      return state.baseData.biennaleIndex[ident] || null;
    },
    getSystem(state) {
      return state.baseData.biennale_system_settings;
    },
    getHeader(state) {
      return state.baseData.biennale_header_section;
    },
    getSocial(state) {
      return state.baseData.biennale_social_section;
    },
    getFooter(state) {
      return state.baseData.biennale_footer_section;
    },
    getTicketWidget(state) {
      return state.baseData.ticket_widget_section;
    },
    getPageNotFound(state) {
      return state.baseData.biennale_notfound_page;
    },
    getLang(state) {
      return state.baseData.lang_code;
    },
    getInternalLoading(state) {
      return state.isInternalNavigation;
    },
    getMainLoading(state) {
      return state.isMainLoading;
    },
    isInit(state) {
      return state.isInit;
    },
    isIntro(state) {
      return state.isIntro;
    },
  },
  mutations: {
    setBaseData(state, payload) {
      const baseData = baseFactory();
      for (let prop in payload) {
        baseData[prop] = payload[prop];
      }
      state.baseData = baseData;
      state.isInit = true;
    },
    setLang(state, payload) {
      state.baseData.lang_code = payload;
    },
    hideNotification(state) {
      state.baseData.biennale_system_settings.fields.notification = "";
    },
    setStartInternalLoading(state) {
      state.isInternalNavigation = true;
    },
    setFinishInternalLoading(state) {
      state.isInternalNavigation = false;
    },
    setFinishMainLoading(state) {
      state.isMainLoading = false;
    },
    setCurrentBiennale(state, biennaleName) {
      state.currentBiennaleName = biennaleName;
    },
    clearCurrentBiennale(state) {
      state.currentBiennaleName = "";
    },
    disableIntro(state) {
      state.isIntro = false;
    },
  },
};
