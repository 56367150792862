<template>
  <v-row no-gutters class="page-header align-center">
    <v-col cols="12" md="" class="mb-4 mb-md-0">
      <h1 class="biennalePrimary--text">
        {{ title }}
      </h1>
    </v-col>
    <v-col
      v-if="browseTitle.length > 0"
      cols="12"
      sm=""
      class="flex-sm-grow-0 text-no-wrap"
    >
      <router-link
        :to="browseLink"
        class="d-flex align-center button mt-2 mt-sm-4"
      >
        <span>{{ browseTitle }}</span>
        <v-icon class="ml-2">$vuetify.icons.Arrrow</v-icon>
      </router-link>
    </v-col>
    <slot> </slot>
  </v-row>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    title: {
      type: String,
      default: "",
    },
    browseTitle: {
      type: String,
      default: "",
    },
    browseLink: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style scoped lang="scss">
.page-header {
  margin-bottom: 20px;
}
@media (min-width: 1024px) {
  .page-header {
    margin-bottom: 60px;
  }
}
</style>
