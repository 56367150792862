<template>
  <about-content v-if="pageLoaded" :page-data="pageData" />
</template>

<script>
import AboutRepository from "@/api/about.js";
import AboutContent from "./AboutContent.vue";

export default {
  components: {
    AboutContent,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
      },
    };
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await AboutRepository.getAbout();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");
    },
  },
};
</script>
