import Repository from "@/plugins/axios";

export default {
  // Получить страницу со списком прошлых биеннале
  getList() {
    return Repository.get(`/biennale/archive`);
  },
  // Получить страницу архивного биеннале
  getArchiveItem(biennaleName) {
    return Repository.get(`/biennale/archive/${biennaleName}`);
  },
};
