<template>
  <v-row no-gutters class="align-start mb-0">
    <v-col cols="12" md="" class="flex-grow-md-1">
      <horizontal-scroller
        :items="days"
        content-class="mx-n2"
        class="mb-0 calendar-band"
        disable-spacing
      >
        <template v-slot="{ item }">
          <div
            class="py-2 px-2 day mb-6"
            :class="selectableDate(item) ? 'clickable' : 'day--disabled'"
            @click="onDayClick(item)"
          >
            <div class="day__month h6-mini">
              <span v-if="startOfMonth(item)">{{ getMonthDay(item) }}</span>
            </div>
            <div
              class="day__weekday mb-1 mb-md-3"
              :class="same(item, day) ? 'biennalePrimary--text' : ''"
            >
              {{ getWeekDay(item) }}
            </div>
            <h4
              class="day__numday"
              :class="same(item, day) ? 'textOnBgDark--text' : ''"
            >
              {{ getNumDay(item) }}
            </h4>
            <div v-show="same(item, day)" class="day__splash">
              <v-icon
                :size="$vuetify.breakpoint.mdAndUp ? 50 : 30"
                class="biennalePrimary--text"
                >$vuetify.icons.Splash</v-icon
              >
            </div>
          </div>
        </template>
      </horizontal-scroller>
    </v-col>
    <slot> </slot>
  </v-row>
</template>

<script>
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import moment from "moment";

export default {
  name: "CalendarBand",
  components: { HorizontalScroller },
  props: {
    value: {
      type: String,
      required: true,
    },
    beginBiennale: {
      type: Object,
      required: true,
    },
    endBiennale: {
      type: Object,
      required: true,
    },
    spanForward: {
      type: Number,
      default: 14,
    },
    spanBackward: {
      type: Number,
      default: 7,
    },
  },
  computed: {
    day: {
      get() {
        return moment(this.value);
      },
      set(val) {
        this.$emit("input", val.format("yyyy-MM-DD"));
      },
    },
    today() {
      return moment().startOf("day");
    },
    days() {
      const result = [];
      const start = this.firstDate.clone();
      for (let dt = start; dt <= this.maxCalendarDate; dt.add(1, "day")) {
        result.push(dt.clone());
      }
      return result;
    },
    startOfMonth() {
      return (item) => {
        return (
          this.getNumDay(item) == 1 ||
          (this.same(item, this.firstDate) &&
            this.getNumDay(item.clone().add(1, "day")) != 1)
        );
      };
    },
    firstDate() {
      return this.today.clone().subtract(this.spanBackward, "day");
    },
    maxSelectableDate() {
      return moment.min(
        this.endBiennale,
        this.today.clone().add(this.spanForward - 1, "day")
      );
    },
    maxCalendarDate() {
      return moment.max(
        this.endBiennale,
        this.today.clone().add(this.spanForward - 1, "day")
      );
    },
    getWeekDay() {
      return (day) => this.capitalize(day.format("dd"));
    },
    getNumDay() {
      return (day) => day.format("D");
    },
    getMonthDay() {
      return (day) => this.capitalize(day.format("MMMM"));
    },
    selectableDate() {
      return (date) => date >= this.today && date <= this.maxSelectableDate;
    },
    same() {
      return (day1, day2) => day1.isSame(day2, "day");
    },
    capitalize() {
      return (str) => str[0].toUpperCase() + str.substring(1);
    },
  },
  methods: {
    onDayClick(day) {
      if (this.selectableDate(day)) {
        this.day = day;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.calendar-band {
  border-bottom: 1px solid var(--v-line-base);
}
.day {
  width: 72px;
  position: relative;

  .day__month {
    height: 32px;
    @media (min-width: 1024px) {
      height: 36px;
    }
  }

  &.day--disabled {
    .day__weekday {
      color: var(--v-textDisabled-base);
    }
    .day__numday {
      color: var(--v-textDisabled-base);
    }
  }

  .day__weekday {
    color: var(--v-textBase-base);
    text-align: center;
    font-family: A Chaika Futuris;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    @media (min-width: 1024px) {
      font-size: 18px;
      line-height: 150%;
    }
  }

  .day__numday {
    color: var(--v-textBase-base);
    text-align: center;
    position: relative;
    z-index: 3;
  }

  .day__splash {
    position: absolute;
    bottom: 7px;
    left: 50%;
    margin-left: -15px;
    z-index: 2;
    @media (min-width: 1024px) {
      margin-left: -25px;
      bottom: 6px;
    }
  }
}
</style>
