import ParticipantsList from "@/pages/Participants/index";
import Participant from "@/pages/Participants/Participant";

export default [
  {
    path: "/:biennale/participants",
    component: ParticipantsList,
    props: true,
    children: [
      {
        path: "",
        name: "ParticipantsList",
      },

      {
        path: "/participant/:ident",
        name: "Participant",
        component: Participant,
        props: true,
      },
    ],
  },
];
