<template>
  <router-link
    v-if="payload.fields"
    :to="{ name: 'NewsItem', params: { ident: payload.node_name } }"
    class="d-block news-list-item kk-hoverable-image mb-5"
  >
    <image-extended
      :image="payload.fields.image"
      content-class="news-image-container"
      class="mb-4"
      :height="height"
      eager
    />
    <div class="h6-mini mb-1">
      {{ payload.fields.title }}
    </div>
    <div class="caption-common">
      {{ formatDate(payload.fields.date) }}
    </div>
  </router-link>
</template>
<script>
import moment from "moment";
import ImageExtended from "@/components/ImageExtended.vue";

export default {
  name: "NewsListItem",
  components: { ImageExtended },
  props: {
    payload: {
      type: Object,
      default: () => ({}),
    },
    kind: {
      type: String,
      default: "",
    },
  },
  computed: {
    formatDate() {
      return (date) => moment(date).format("D MMMM yyyy");
    },
    height() {
      switch (this.kind) {
        case "first":
          return this.heightFirst;
        case "second":
          return this.heightSecond;
        case "third-fourth":
          return this.heightThirdFourth;
        default:
          return this.heightDefault;
      }
    },
    heightFirst() {
      return this.$vuetify.breakpoint.xlOnly ? "45vw" : "56vw";
    },
    heightSecond() {
      return this.$vuetify.breakpoint.xlOnly ? "calc(30vw - 118px)" : "56vw";
    },
    heightThirdFourth() {
      const { breakpoint } = this.$vuetify;
      if (breakpoint.xlOnly) return "15vw";
      if (breakpoint.lgAndUp) return "25vw";
      if (breakpoint.smAndUp) return "30vw";
      return "56vw";
    },
    heightDefault() {
      const { breakpoint } = this.$vuetify;
      if (breakpoint.xlOnly) return "15vw";
      if (breakpoint.mdAndUp) return "20vw";
      if (breakpoint.smAndUp) return "30vw";
      return "56vw";
    },
  },
};
</script>
