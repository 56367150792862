<template>
  <div>
    <div
      class="video-list-item mb-2 mb-md-4"
      :style="videoStyle"
      v-html="payload.fields.video_embed"
    />
    <div class="h6-mini mb-1">
      {{ payload.fields.title }}
    </div>
    <div class="caption-common textSecondary--text">
      {{ formatDate(payload.fields.date) }}
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "VideoListItem",
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formatDate() {
      return (date) => moment(date).format("D MMMM yyyy");
    },
    videoStyle() {
      const { breakpoint } = this.$vuetify;
      const paddingPct = Math.floor((1 / this.aspectRatio) * 10000000) / 100000;
      if (breakpoint.xsOnly) {
        return {
          width: "100%",
          "padding-top": `${paddingPct}%`,
          position: "relative",
        };
      }
      return {
        width: `${this.width}vw`,
        height: `${this.height}vw`,
      };
    },
    aspectRatio() {
      const mult = parseFloat(this.payload.fields.aspect_ratio || "");
      return isNaN(mult) ? 1.77 : mult;
    },
    width() {
      return this.height * this.aspectRatio;
    },
    height() {
      const { breakpoint } = this.$vuetify;
      if (breakpoint.xlOnly) return "16";
      if (breakpoint.mdAndUp) return "24";
      return "35";
    },
  },
};
</script>
<style scoped>
.video-list-item ::v-deep(iframe) {
  width: 100%;
  height: 100%;
}
@media (max-width: 639px) {
  .video-list-item ::v-deep(iframe) {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
