import ProgramList from "@/pages/Program/ProgramList";
import ProgramDirection from "@/pages/Program/ProgramDirection";
import ProgramItem from "@/pages/Program/ProgramItem";

export default [
  {
    path: "/:biennale/program",
    component: ProgramList,
    props: true,
    children: [
      {
        path: "tag/:tag",
        name: "ProgramListByTag",
        props: true,
      },
      {
        path: "",
        name: "ProgramList",
      },
      {
        path: "calendar",
        name: "ProgramCalendar",
      },
      {
        path: ":ident",
        name: "ProgramItem",
        component: ProgramItem,
        props: true,
      },
    ],
  },
  {
    path: "/:biennale/direction/:ident",
    name: "ProgramDirection",
    component: ProgramDirection,
    props: true,
  },
];
