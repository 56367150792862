<template>
  <v-card tile elevation="0" color="bgDark" height="100%" class="footer">
    <v-container class="pa-0" fluid>
      <v-row class="align-stretch mb-5">
        <v-col cols="12" md="5" lg="6" class="d-flex flex-column">
          <v-row no-gutters class="flex-grow-0 mb-6">
            <v-col
              cols="8"
              sm="10"
              lg="8"
              class="footer__org h6-mini mb-4 mb-sm-6"
            >
              {{ footerData.fields.org }}
            </v-col>
            <v-col cols="12" sm="7" md="11">
              <div
                class="caption-common"
                v-html="footerData.fields.text_about"
              />
            </v-col>
          </v-row>

          <div class="flex-grow-1 d-none d-lg-block" />

          <social-buttons
            height="45"
            space-between="25"
            inverted
            class="d-flex flex-grow-0"
          />
        </v-col>
        <v-col cols="12" md="7" lg="6">
          <v-row>
            <v-col cols="12" md="6" class="pr-lg-10">
              <div
                v-for="menuItem in menuVisitors"
                :key="menuItem.node_name"
                class="mb-4 mb-md-6"
              >
                <kk-link :link="menuItem.fields.href" class="base-2">
                  {{ menuItem.fields.title }}
                </kk-link>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="h6-mini mb-4 mb-sm-6">
                {{ footerData.fields.title_contacts }}
              </div>
              <div
                class="caption-common"
                v-html="footerData.fields.text_contacts"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <footer-partners :items="footerData.children" class="mb-5" />

      <footer-bottom :section-data="footerData" />
    </v-container>
  </v-card>
</template>

<script>
import KkLink from "@/components/KkLink.vue";
import SocialButtons from "@/components/SocialButtons.vue";
import FooterPartners from "./FooterPartners.vue";
import FooterBottom from "./FooterBottom.vue";

export default {
  components: {
    SocialButtons,
    FooterPartners,
    FooterBottom,
    KkLink,
  },
  computed: {
    footerData() {
      return this.$store.getters.getFooter;
    },
    headerData() {
      return this.$store.getters.getHeader;
    },
    menuVisitors() {
      const section = (this.headerData.children || []).find(
        (section) => section.node_name == "menu-visitors"
      );
      return section?.children || [];
    },
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  padding: 64px 40px 40px 40px;
}

@media (max-width: 1023px) {
  .footer {
    padding: 40px 16px;

    .footer__org {
      margin-bottom: 16px;
    }
  }
}

@media (max-width: 639px) {
  .footer__org {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
</style>
