import Repository from "@/plugins/axios";

export default {
  // Получить необходимый контент для запуска приложения ("базовый запрос")
  // В числе прочего отдаст индексы, которые используются на других страницах в ссылках (links)
  getBase() {
    return Repository.get("/biennale/base");
  },

  // Сменить язык (code = [ru|en])
  setLang(code) {
    return Repository.post("/biennale/lang", { code });
  },
};
