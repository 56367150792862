<template>
  <div v-if="typ == 2">
    {{ dateText }}
  </div>
  <div
    v-else-if="dates.length > 0"
    class="schedule-dates-interval"
    :class="!richFormat ? 'd-flex align-center' : ''"
  >
    <div>
      <span v-for="(date, index) in datesFirst" :key="index">
        <span v-if="index > 0">, </span>
        <span class="text-no-wrap">{{ date }}</span>
      </span>
    </div>
    <kk-popover
      v-if="datesOther.length > 0"
      disable-article
      :html="datesOther"
      icon="mdi-dots-horizontal"
      class="d-inline-block"
      :class="!richFormat ? 'ml-2' : ''"
    />
  </div>
  <span v-else-if="dtb && dte">
    <span class="text-no-wrap">{{ dtb }}</span> —
    <span class="text-no-wrap">{{ dte }}</span>
  </span>
  <span v-else-if="dtb"
    >{{ $t("Common.dateFrom") }}
    <span class="text-no-wrap">{{ dtb }}</span></span
  >
  <span v-else-if="dte"
    >{{ $t("Common.dateTo") }} <span class="text-no-wrap">{{ dte }}</span></span
  >
  <span v-else>{{ ticketWidgetSection.fields.every_day }}</span>
</template>

<script>
import moment from "moment";
import KkPopover from "./KkPopover.vue";
export default {
  name: "ScheduleDatesInterval",
  components: { KkPopover },
  props: {
    schedule: {
      type: Object,
      default: () => {},
    },
    richFormat: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ticketWidgetSection() {
      return this.$store.getters.getTicketWidget;
    },
    typ() {
      if (!this.schedule) return -1;
      return this.schedule.typ;
    },
    dateText() {
      if (!this.schedule) return "";
      return this.schedule.text;
    },
    dates() {
      if (!this.schedule) return [];
      const dates = [...(this.schedule.dates || [])];
      dates.sort();
      const datesMoment = dates.map((dt) => moment(dt));
      const datesActual = datesMoment.filter(
        (dt) => moment().startOf("day").diff(dt) <= 0
      );
      if (datesMoment.length > 0 && datesActual.length == 0) {
        return datesMoment.map((dt) => this.fmt(dt));
      } else {
        return datesActual.map((dt) => this.fmt(dt));
      }
    },
    sliceCount() {
      if (this.richFormat) {
        return this.dates.length > 3 ? 2 : 3;
      } else {
        return 2;
      }
    },
    datesFirst() {
      return this.dates.slice(0, this.sliceCount);
    },
    datesOther() {
      return this.dates.slice(this.sliceCount).join("<br />");
    },
    dtb() {
      const dtb = this.schedule.dt_begin || undefined;
      return dtb ? this.fmt(moment(dtb)) : undefined;
    },
    dte() {
      const dte = this.schedule.dt_end || undefined;
      return dte ? this.fmt(moment(dte)) : undefined;
    },
    fmt() {
      return (dt) =>
        this.richFormat
          ? this.formatMomentRich(dt)
          : this.formatMomentSimple(dt);
    },
    formatMomentSimple() {
      return (dt) => {
        return dt.format("DD.MM.yyyy");
      };
    },
    formatMomentRich() {
      return (dt) => {
        const thisYear = moment().format("yyyy") == dt.format("yyyy");
        return thisYear ? dt.format("D MMMM") : dt.format("D MMMM yyyy");
      };
    },
  },
};
</script>
