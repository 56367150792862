<template>
  <div>
    <section-title
      :title="mainFields.title_partners"
      :browse-title="mainFields.browse_all_partners"
      :browse-link="{ name: 'Partnership' }"
      class="subsection"
    />

    <v-row class="subsection justify-start align-center mx-n4 mx-md-n8">
      <v-col
        v-for="(partner, index) in sectionData.children"
        :key="index"
        class="flex-grow-0 px-4 px-md-8"
      >
        <kk-link
          v-if="partner.fields.link"
          :link="partner.fields.link"
          class="d-block"
          draggable="false"
        >
          <img
            :src="partner.fields.logo"
            :height="$vuetify.breakpoint.mdAndUp ? 100 : 70"
            alt=""
          />
        </kk-link>
        <div v-else>
          <img
            :src="partner.fields.logo"
            :height="$vuetify.breakpoint.mdAndUp ? 100 : 70"
            alt=""
          />
        </div>
      </v-col>
    </v-row>

    <kk-dynamic-html class="" :html="sectionData.fields.text" />
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import KkLink from "@/components/KkLink.vue";

export default {
  name: "MainPartners",
  components: {
    SectionTitle,
    KkDynamicHtml,
    KkLink,
  },
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
    mainFields: {
      type: Object,
      required: true,
    },
  },
  computed: {
    maxWidth() {
      return this.$vuetify.breakpoint.mdAndUp ? 200 : 100;
    },
    maxHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? 200 : 100;
    },
  },
};
</script>
<style lang="scss" scoped>
.about__image {
  width: 100%;
  height: 100%;
}
</style>
