<template>
  <div v-if="pageLoaded && pageData.is_biennale">
    <main-header
      ref="mainheader"
      :obvm="obvHeader"
      :biennale-ident="biennale"
      class="mb-5 mb-md-10"
    />

    <main-program
      v-if="
        hasContent('biennale_program_section') ||
        (pageData.biennale_program_section?.fields.concept_header || '')
          .length > 0
      "
      :section-data="pageData.biennale_program_section"
      :main-fields="pageData.fields"
      :biennale="biennale"
      class="section"
    />

    <main-participants
      v-if="hasContent('biennale_participants_section')"
      :section-data="pageData.biennale_participants_section"
      :main-fields="pageData.fields"
      :biennale="biennale"
      class="section"
    />

    <main-media
      v-if="hasContent('biennale_media_section')"
      :section-items="pageData.biennale_media_section.children"
      :main-fields="pageData.fields"
      :biennale="biennale"
      class="section"
    />

    <main-news
      v-if="hasContent('biennale_news_section')"
      :section-data="pageData.biennale_news_section"
      :main-fields="pageData.fields"
      :biennale="biennale"
      class="section"
    />

    <main-about
      :section-data="pageData.biennale_about_section"
      :main-fields="pageData.fields"
      :biennale="biennale"
      class="section"
    />

    <main-partners
      v-if="hasContent('biennale_partners_section')"
      :section-data="pageData.biennale_partners_section"
      :main-fields="pageData.fields"
      class="section"
    />

    <main-sites
      v-if="hasContent('biennale_sites_section')"
      :section-data="pageData.biennale_sites_section"
      :main-fields="pageData.fields"
      :biennale="biennale"
      class="section"
    />

    <zuruck />
  </div>

  <about-content
    v-else-if="pageLoaded && !pageData.is_biennale"
    :page-data="pageDataAbout"
  />
</template>

<script>
import MainRepository from "@/api/main.js";
import MainHeader from "./MainHeader.vue";
import MainProgram from "./MainProgram.vue";
import MainParticipants from "./MainParticipants.vue";
import MainMedia from "./MainMedia.vue";
import MainNews from "./MainNews.vue";
import MainAbout from "./MainAbout.vue";
import MainPartners from "./MainPartners.vue";
import MainSites from "./MainSites.vue";
import Zuruck from "@/components/Zuruck.vue";
import AboutContent from "@/pages/About/AboutContent.vue";

export default {
  components: {
    MainHeader,
    MainProgram,
    MainParticipants,
    MainMedia,
    MainNews,
    MainAbout,
    MainPartners,
    MainSites,
    Zuruck,
    AboutContent,
  },
  props: {
    obvHeader: {
      type: Number,
      default: 0,
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(null),
    };
  },

  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        biennale_participants_section: { fields: {}, children: [] },
        biennale_program_section: { fields: {}, children: [] },
        biennale_partners_section: { fields: {}, children: [] },
        biennale_media_section: { fields: {}, children: [] },
        biennale_news_section: { fields: {}, children: [] },
        biennale_about_section: { fields: {} },
        directions: [],
        is_biennale: false,
      },
      pageDataAbout: {
        fields: {},
      },
    };
  },
  computed: {
    biennale() {
      return this.$store.getters.getActualBiennaleIdent;
    },
    hasContent() {
      return (section) => (this.pageData?.[section]?.children || []).length > 0;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await MainRepository.getMain();
      if (data.is_biennale) {
        this.pageData = data;
      } else {
        this.pageDataAbout = data;
      }
      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      this.$emit("page-loaded");
    },
  },
};
</script>
