<template>
  <div class="program-direction-block">
    <h4 class="textSecondary--text mb-5 mb-md-10">
      {{ payload.fields.title }}
    </h4>
    <v-row no-gutters>
      <v-col cols="12" md="">
        <div
          v-if="payload.fields.annotation"
          class="mb-3 mb-md-5"
          v-html="payload.fields.annotation || ''"
        />
        <div class="mb-5 mb-md-10">
          <browse-button
            :browse-link="{
              name: 'ProgramDirection',
              params: { biennale, ident: payload.node_name },
            }"
            :browse-title="browseTitle"
          />
        </div>
        <div v-if="showItems">
          <program-list-item
            v-for="activity in payload.children"
            :key="activity.node_name"
            :payload="activity.fields"
            :biennale="biennale"
            :ident="activity.node_name"
          />
        </div>
      </v-col>
      <v-col
        v-if="!showItems && randomImage"
        class="d-none d-md-block flex-grow-0 program-list-item__image"
      >
        <v-avatar :size="200" tile>
          <image-clipped :fit="200" :image="randomImage" />
        </v-avatar>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ImageClipped from "@/components/ImageClipped.vue";
import BrowseButton from "@/components/BrowseButton.vue";
import ProgramListItem from "@/components/Program/ProgramListItem.vue";
import shuffle from "lodash/shuffle";

export default {
  name: "ProgramListDirection",
  components: {
    ImageClipped,
    BrowseButton,
    ProgramListItem,
  },
  props: {
    payload: {
      type: Object,
      required: true,
    },
    biennale: {
      type: String,
      required: true,
    },
    browseTitle: {
      type: String,
      required: true,
    },
    showItems: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    randomImage() {
      const item = shuffle(this.payload.children).find(
        (item) => (item.fields?.image?.i_main || "").length > 0
      );
      return item?.fields?.image || null;
    },
  },
};
</script>
<style scoped lang="scss">
.program-direction-block {
  border-left: 6px solid var(--v-biennaleSecondary);
  padding-left: 40px;
  margin-bottom: 40px;
}
.program-list-item__image {
  min-width: 200px;
  max-width: 200px;
  margin-left: 40px;
}
</style>
