<template>
  <div class="participants-list">
    <h3
      v-if="headerId.length > 0"
      :id="headerId"
      class="biennalePrimary--text mb-5 mb-md-10"
    >
      {{ payload.fields.title }}
    </h3>
    <h3 v-else class="biennalePrimary--text mb-5 mb-md-10">
      {{ payload.fields.title }}
    </h3>
    <v-row class="align-start ma-md-n5">
      <v-col
        v-for="participant in payload.children"
        :key="participant.id"
        :cols="cols.def"
        :sm="cols.sm"
        :md="cols.md"
        :lg="cols.lg"
        :xl="cols.xl"
        class="pa-md-5"
        :class="colClass"
      >
        <router-link-wrapper
          :node-name="participant.person_node"
          :to="{
            name: 'Participant',
            params: { ident: participant.person_node },
          }"
        >
          <div class="d-flex align-start">
            <v-avatar
              :size="$vuetify.breakpoint.mdAndUp ? 160 : 80"
              class="mr-2 mr-sm-3 person-avatar flex-grow-0"
            >
              <image-clipped
                :image="
                  participant?.image?.i_main ? participant.image : imageStd
                "
                :fit="$vuetify.breakpoint.mdAndUp ? 160 : 80"
              />
            </v-avatar>
            <div class="avatar-text ml-3 ml-md-6">
              <h4 class="mb-2 mb-md-4">{{ participant.title }}</h4>
              <div class="longread textSecondary--text">
                {{ participant.role }}
              </div>
            </div>
          </div>
        </router-link-wrapper>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ImageClipped from "@/components/ImageClipped.vue";
import RouterLinkWrapper from "@/components/RouterLinkWrapper.vue";
export default {
  name: "ParticipantList",
  components: {
    ImageClipped,
    RouterLinkWrapper,
  },
  props: {
    payload: {
      type: Object,
      required: true,
    },
    headerId: {
      type: String,
      default: "",
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cols() {
      return this.dense
        ? {
            def: 12,
            sm: 6,
            md: 12,
            lg: 12,
            xl: 12,
          }
        : {
            def: 12,
            sm: 6,
            md: 12,
            lg: 6,
            xl: 6,
          };
    },
    colClass() {
      return this.dense ? "col-xxl-6" : "col-xxl-4";
    },
    imageStd() {
      return (
        this.payload.fields.avatar_copycat || {
          i_main: `${process.env.BASE_URL}images/logo.svg`,
          i_width: 160,
          i_height: 160,
          i_top: 0,
          i_bottom: 100,
          i_left: 0,
          i_right: 100,
        }
      );
    },
  },
};
</script>
<style scoped lang="scss">
.participants-list {
  .avatar-text {
    max-width: 400px;
  }
}
</style>
