<template>
  <router-link-wrapper
    :node-name="payload.fields.person_node"
    :to="{ name: 'Participant', params: { ident: payload.fields.person_node } }"
    class="d-md-flex participant-list-item mb-5 pb-5 mb-md-10 pb-md-10"
    :class="payload.fields.person_node ? 'kk-hoverable-text' : ''"
  >
    <image-extended
      :image="image"
      content-class="participant-image-container"
      :width="$vuetify.breakpoint.mdAndUp ? 300 : 200"
      :height="$vuetify.breakpoint.mdAndUp ? 300 : 200"
      class="mb-3 mb-md-0"
      eager
    />
    <div class="ml-md-6 d-flex flex-column flex-grow-1">
      <h4 class="mb-1 mb-md-3 flex-grow-0">{{ payload.fields.title }}</h4>
      <div
        class="base textSecondary--text kk-hoverable--item flex-grow-1 mb-3 mb-md-0"
      >
        {{ payload.fields.location }}
      </div>
      <div class="d-flex justify-space-between align-end">
        <div class="base textSecondary--text kk-hoverable--item flex-grow-0">
          {{ payload.fields.role }}
        </div>
        <h4 class="d-none d-md-block flex-grow-0 ml-md-6">
          {{ payload.fields.title_origin }}
        </h4>
      </div>
    </div>
  </router-link-wrapper>
</template>
<script>
import ImageExtended from "@/components/ImageExtended.vue";
import RouterLinkWrapper from "@/components/RouterLinkWrapper.vue";

export default {
  name: "Participant",
  components: { ImageExtended, RouterLinkWrapper },
  props: {
    payload: {
      type: Object,
      required: true,
    },
    avatarCopycat: {
      type: Object,
      default: null,
    },
  },
  computed: {
    image() {
      if (this.payload.fields.image?.i_main) {
        return this.payload.fields.image;
      } else {
        return (
          this.avatarCopycat || {
            i_main: `${process.env.BASE_URL}images/logo.svg`,
            i_width: 160,
            i_height: 160,
            i_top: 0,
            i_bottom: 100,
            i_left: 0,
            i_right: 100,
          }
        );
      }
    },
  },
};
</script>
<style scoped lang="scss">
.participant-list-item {
  border-bottom: 1px solid var(--v-line-base);
}
</style>
