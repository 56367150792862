<template>
  <div v-if="pageData">
    <image-header
      ref="refimageheader"
      :image="pageData.fields.poster"
      :video="pageData.fields.video"
      class="mb-10 mb-md-16"
    />

    <page-title :title="pageData.fields.title" />

    <kk-article ref="refarticle" :contents="contents" dense class="section">
      <horizontal-scroller
        :items="biennaleItems"
        class="minisection"
        content-class="ml-n5"
      >
        <template v-slot="{ item }">
          <div class="mx-5 scroll-item__container">
            <router-link-wrapper
              :node-name="item.disable_archive_section == '1' ? '' : '1'"
              :to="{ name: 'ArchiveItem', params: { biennale: item.ident } }"
              draggable="false"
            >
              <image-extended
                :image="item.archive_image"
                :width="maxWidth"
                :height="maxHeight"
              />
            </router-link-wrapper>
          </div>
        </template>
      </horizontal-scroller>

      <kk-dynamic-html :html="pageData.fields.header_text" class="subsection" />

      <div
        v-for="block in pageData.children || []"
        :key="block.node_name"
        class="subsection"
      >
        <h3 :id="block.node_name" class="biennalePrimary--text mb-5 mb-md-10">
          {{ block.fields.title }}
        </h3>
        <about-awards
          v-if="block.class_name == 'biennale_about_awards'"
          :block-data="block"
        />
        <kk-dynamic-html
          v-else-if="block.class_name == 'biennale_about_paragraph'"
          :html="block.fields.article"
        />
      </div>
    </kk-article>

    <zuruck with-aux-bar />
  </div>
</template>
<script>
import ImageHeader from "@/components/ImageHeader.vue";
import PageTitle from "@/components/PageTitle.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import ImageExtended from "@/components/ImageExtended.vue";
import RouterLinkWrapper from "@/components/RouterLinkWrapper.vue";
import KkArticle from "@/components/Article/KkArticle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import Zuruck from "@/components/Zuruck.vue";
import AboutAwards from "./AboutAwards.vue";

export default {
  name: "AboutContent",
  components: {
    ImageHeader,
    PageTitle,
    HorizontalScroller,
    ImageExtended,
    RouterLinkWrapper,
    KkArticle,
    KkDynamicHtml,
    AboutAwards,
    Zuruck,
  },
  props: {
    pageData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    contents() {
      return (this.pageData.children || []).reduce((contents, item) => {
        contents[item.node_name] = item.fields.title;
        return contents;
      }, {});
    },
    maxWidth() {
      return this.$vuetify.breakpoint.mdAndUp ? 200 : 100;
    },
    maxHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? 200 : 100;
    },
    biennaleItems() {
      const actualBiennale = this.$store.getters.getActualBiennaleIdent;
      return Object.entries(this.$store.getters.getBiennaleIndex)
        .map((entry) => ({ ident: entry[0], ...entry[1] }))
        .filter(
          (item) =>
            (item.archive_image?.i_main || "").length > 0 &&
            item.ident != actualBiennale
        )
        .reverse();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs?.refimageheader?.initObserver();
      this.$refs?.refarticle?.initObserver();
    });
  },
};
</script>
