<template>
  <div v-if="list.length > 0">
    <h3 id="contacts" class="biennalePrimary--text mb-6 mb-sm-12">
      {{ title }}
    </h3>
    <v-row>
      <v-col v-for="item in list" :key="item.node_name" cols="12" lg="6">
        <person
          :name="item.fields.title"
          :role="item.fields.position"
          :avatar="item.fields.photo"
          :phone="item.fields.phone"
          :email="item.fields.email"
          hide-details
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Person from "@/components/Person.vue";

export default {
  name: "ContactsList",
  components: { Person },
  props: {
    title: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
