<template>
  <div v-if="pageLoaded">
    <main-header
      ref="mainheader"
      :obvm="obvHeader"
      :biennale-ident="biennale"
      class="mb-5 mb-md-10"
    />

    <archive-overview
      v-if="hasContent('biennale_archive_stat_section')"
      :section-data="pageData.biennale_archive_stat_section"
      class="section"
    />

    <main-program
      v-if="
        hasContent('biennale_program_section') ||
        (pageData.biennale_program_section?.fields.concept_header || '')
          .length > 0
      "
      :section-data="pageData.biennale_program_section"
      :main-fields="pageData.fields"
      :biennale="biennale"
      class="section"
    />

    <main-participants
      v-if="hasContent('biennale_participants_section')"
      :section-data="pageData.biennale_participants_section"
      :main-fields="pageData.fields"
      :biennale="biennale"
      class="section"
    />

    <main-media
      v-if="hasContent('biennale_media_section')"
      :section-items="pageData.biennale_media_section.children"
      :main-fields="pageData.fields"
      :biennale="biennale"
      class="section"
    />

    <main-news
      v-if="hasContent('biennale_news_section')"
      :section-data="pageData.biennale_news_section"
      :main-fields="pageData.fields"
      :biennale="biennale"
      class="section"
    />

    <main-partners
      v-if="hasContent('biennale_partners_section')"
      :section-data="pageData.biennale_partners_section"
      :main-fields="pageData.fields"
      class="section"
    />

    <main-sites
      v-if="hasContent('biennale_sites_section')"
      :section-data="pageData.biennale_sites_section"
      :main-fields="pageData.fields"
      :biennale="biennale"
      class="section"
    />

    <zuruck />
  </div>
</template>

<script>
import ArchiveRepository from "@/api/archive.js";
import MainHeader from "./MainHeader.vue";
import ArchiveOverview from "./ArchiveOverview.vue";
import MainProgram from "./MainProgram.vue";
import MainParticipants from "./MainParticipants.vue";
import MainMedia from "./MainMedia.vue";
import MainNews from "./MainNews.vue";
import MainPartners from "./MainPartners.vue";
import MainSites from "./MainSites.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    MainHeader,
    ArchiveOverview,
    MainProgram,
    MainParticipants,
    MainMedia,
    MainNews,
    MainPartners,
    MainSites,
    Zuruck,
  },
  props: {
    biennale: {
      type: String,
      required: true,
    },
    obvHeader: {
      type: Number,
      default: 0,
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(null),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        biennale_fields: {},
        biennale_participants_section: { fields: {}, children: [] },
        biennale_program_section: { fields: {}, children: [] },
        biennale_partners_section: { fields: {}, children: [] },
        biennale_media_section: { fields: {}, children: [] },
        biennale_news_section: { fields: {}, children: [] },
        biennale_sites_section: { fields: {}, children: [] },
        biennale_archive_stat_section: { fields: {}, children: [] },
        directions: [],
        is_biennale: false,
      },
      pageDataAbout: {
        fields: {},
      },
    };
  },
  computed: {
    hasContent() {
      return (section) => (this.pageData?.[section]?.children || []).length > 0;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
    biennale() {
      this.loadPage();
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await ArchiveRepository.getArchiveItem(this.biennale);
      this.pageData = data;
      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      this.$emit("page-loaded");
    },
  },
};
</script>
