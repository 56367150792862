import MediaLibraryList from "@/pages/MediaLibraryList";
import MediaLibraryItem from "@/pages/MediaLibraryItem";

export default [
  {
    path: "/media",
    component: MediaLibraryList,
    props: true,
    children: [
      {
        path: "",
        name: "MediaLibraryList",
      },
      {
        path: "video",
        name: "MediaLibraryListVideo",
      },
      {
        path: ":mediabiennale/:ident",
        name: "MediaLibraryItem",
        component: MediaLibraryItem,
        props: true,
      },
    ],
  },
];
