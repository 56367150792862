import Repository from "@/plugins/axios";

export default {
  // Получить список фотоальбомов и видео
  getAlbumsList(params) {
    return Repository.get(`/biennale/media`, { params });
  },

  // Получить содержимое фотоальбомы
  getAlbumContent(ident, params) {
    return Repository.get(`/biennale/media/${ident}`, { params });
  },
};
