<template>
  <div
    v-if="biennale"
    class="intro__logo d-flex flex-column flex-xl-row justify-center align-center"
  >
    <div class="flex-grow-0 pa-3">
      <img :src="biennale.intro1" alt="" class="intro intro1" />
    </div>
    <div class="flex-grow-0 pa-3">
      <img :src="biennale.intro2" alt="" class="intro intro2" />
    </div>
    <div class="flex-grow-0 pa-3">
      <img :src="biennale.intro3" alt="" class="intro intro3" />
    </div>
  </div>
</template>
<script>
export default {
  name: "MainHeaderIntroLogo",
  props: {
    biennale: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.intro__logo {
  .intro {
    height: auto;
  }
  .intro1 {
    width: 39vw;
  }
  .intro2 {
    width: 79vw;
  }
  .intro3 {
    width: 36vw;
  }
  @media (min-width: 780px) {
    .intro1 {
      width: 300px;
    }
    .intro2 {
      width: 610px;
    }
    .intro3 {
      width: 280px;
    }
  }
}
</style>
