<template>
  <kk-scrollbar
    v-if="items.length > 0"
    v-dragscroll.x="$vuetify.breakpoint.smAndUp"
    class="hide-on-mobile horz__scroller"
    :settings="{ suppressScrollX: disabled, suppressScrollY: true }"
    :class="`${containerClass} ` + (disableSpacing ? '' : 'pt-8 mt-n8')"
    @dragscrollstart="onDragStart"
    @dragscrollend="onDragEnd"
  >
    <div :class="contentCompiledClass">
      <v-row no-gutters class="horz__row" :style="dragRowStyle">
        <slot name="prepend" />
        <v-col
          v-for="(item, index) in items"
          :key="index"
          class="flex-grow-0"
          :class="unlimitedItemWidth ? 'horz__col' : ''"
        >
          <slot :item="item" :index="index" />
        </v-col>
        <slot name="append" />
      </v-row>
    </div>
  </kk-scrollbar>
</template>

<script>
import KkScrollbar from "./KkScrollbar.vue";
export default {
  name: "HorizontalScroller",
  components: { KkScrollbar },
  props: {
    items: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: String,
      default: "",
    },
    stickTo: {
      type: String,
      default: "both",
    },
    unlimitedItemWidth: {
      type: Boolean,
      default: false,
    },
    disableSpacing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dragging: false,
    };
  },
  computed: {
    containerClass() {
      if (this.disabled) return "stick-to-body";
      if (this.stickTo == "left") return "stick-to-body-left horz";
      if (this.stickTo == "right") return "stick-to-body-right horz";
      return "stick-to-body horz";
    },
    contentCompiledClass() {
      if (this.disabled) {
        return `padding-from-body ${this.contentClass}`;
      } else if (this.stickTo == "left") {
        return `padding-from-body-left ${this.contentClass}`;
      } else if (this.stickTo == "right") {
        return `padding-from-body-right ${this.contentClass}`;
      }
      return `padding-from-body ${this.contentClass}`;
    },
    dragRowStyle() {
      const style = {};
      if (this.dragging && !this.disabled) {
        style["pointer-events"] = "none";
      }
      return style;
    },
  },
  methods: {
    onDragStart() {
      this.dragging = true;
    },
    onDragEnd() {
      this.dragging = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.horz__scroller {
  &.horz {
    user-select: none;
    cursor: grab;
    overflow-x: scroll;
    position: relative;

    .horz__row {
      user-select: none;
      -webkit-user-drag: none;
      flex-wrap: nowrap;
      .horz__col {
        max-width: unset;
        width: unset;
      }
    }
  }
}
</style>
