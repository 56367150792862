<template>
  <div
    class="archive__item pt-6 pt-md-14 pb-3 pb-md-6 kk-hoverable-image-manual"
    :class="[hover ? 'hovered' : '', `d-${breakpoint}-flex`]"
  >
    <div
      class="archive__item__img-container flex-grow-0 mb-5 mb-md-10"
      :class="`mb-${breakpoint}-0`"
    >
      <image-extended v-if="image" :image="image" class="archive__item_img" />
      <div v-else class="archive__item_img" />
      <div class="archive__item_img-year">
        <div>{{ yearSplit[0] }}</div>
        <div>{{ yearSplit[1] }}</div>
      </div>
    </div>
    <div class="flex-grow-1" :class="`pl-${breakpoint}-10`">
      <h4 class="mb-1 mb-md-2">{{ biennale.fields.archive_subtitle }}</h4>
      <h5 v-if="miniTitles" class="mb-2 mb-md-4">
        «{{ biennale.fields.title }}»
      </h5>
      <h2 v-else class="mb-2 mb-md-4">«{{ biennale.fields.title }}»</h2>
      <div v-html="biennale.fields.archive_annotation" />
      <div
        v-if="biennale.fields.disable_archive_section == '0'"
        class="mt-4 mt-md-8 mb-2 mb-md-4"
      >
        <div
          class="d-inline-block"
          @mouseenter="hover = true"
          @mouseleave="hover = false"
        >
          <browse-button
            :browse-title="browseTitle"
            :browse-link="{
              name: 'ArchiveItem',
              params: { biennale: biennale.node_name },
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BrowseButton from "@/components/BrowseButton.vue";
import ImageExtended from "@/components/ImageExtended.vue";
export default {
  name: "ArchiveListItem",
  components: { ImageExtended, BrowseButton },
  props: {
    biennale: {
      type: Object,
      required: true,
    },
    browseTitle: {
      type: String,
      required: true,
    },
    breakpoint: {
      type: String,
      default: "lg",
    },
    miniTitles: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    image() {
      return (
        this.biennale.fields.archive_image ||
        this.biennale.fields.main_image ||
        null
      );
    },
    yearSplit() {
      const { year } = this.biennale.fields;
      return [year.substr(0, 2), year.substr(2, 2)];
    },
  },
};
</script>
<style scoped lang="scss">
.archive__item {
  border-bottom: 1px solid var(--v-line-base);

  &.hovered {
    .archive__item_img-year {
      mix-blend-mode: exclusion;
    }
  }

  .archive__item__img-container {
    position: relative;

    .archive__item_img-year {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-left: 16px;
      z-index: 3;
      color: #fff;
      font-family: "A Chaika Futuris";
      font-style: normal;
      font-size: 42vw;
      font-weight: 700;
      line-height: 100%;

      @media (min-width: 640px) {
        padding-left: 20px;
        font-size: 200px;
        line-height: 100%;
        letter-spacing: -8px;
      }
    }

    .archive__item_img {
      position: relative;
      width: 100%;
      z-index: 2;

      @media (min-width: 640px) {
        width: 400px;
        height: 400px;
      }
    }
  }
}
</style>
