<template>
  <div>
    <section-title
      :title="mainFields.title_participants"
      :browse-title="mainFields.browse_all_participants"
      :browse-link="{ name: 'ParticipantsList', params: { biennale } }"
      class="mb-6 mb-md-12"
    />

    <horizontal-scroller :items="sectionData.children" content-class="ml-n1">
      <template v-slot="{ item }">
        <div
          class="mx-2 scroll-item__container"
          :class="
            !!item.fields.person_node
              ? 'kk-hoverable-image kk-hoverable-text'
              : ''
          "
        >
          <kk-link
            v-if="item.fields.person_node"
            :link="`/participant/${item.fields.person_node}`"
            class="d-block"
            draggable="false"
          >
            <image-extended
              :image="item.fields.image"
              :max-width="maxWidth"
              :max-height="maxHeight"
            />
          </kk-link>
          <image-extended
            v-else
            :image="item.fields.image"
            :max-width="maxWidth"
            :max-height="maxHeight"
          />
          <div class="pt-2 pt-md-4">
            <h4 class="mb-md-1 kk-hoverable--item">{{ item.fields.title }}</h4>
            <div class="longread textSecondary--text kk-hoverable--item">
              {{ item.fields.role }}
            </div>
          </div>
        </div>
      </template>
    </horizontal-scroller>
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import KkLink from "@/components/KkLink.vue";
import ImageExtended from "@/components/ImageExtended.vue";

export default {
  name: "MainParticipants",
  components: { SectionTitle, HorizontalScroller, KkLink, ImageExtended },
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
    mainFields: {
      type: Object,
      required: true,
    },
    biennale: {
      type: String,
      required: true,
    },
  },
  computed: {
    maxWidth() {
      return this.$vuetify.breakpoint.mdAndUp ? 500 : 400;
    },
    maxHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? 400 : 300;
    },
  },
};
</script>
<style scoped lang="scss">
.scroll-item__container {
  position: relative;
  overflow: hidden;
}
</style>
