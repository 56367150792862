<template>
  <fragment>
    <transition name="fadeapp">
      <v-app v-show="allLoaded" :class="`biennale-${currentBiennaleId}`">
        <Header
          id="header-dark"
          dark
          :style="obvHeaderStyleDark"
          @scrollbar-trigger="onScrollbarTrigger"
        />
        <Header
          id="header"
          :style="obvHeaderStyle"
          @scrollbar-trigger="onScrollbarTrigger"
        />
        <div
          class="content padding-from-body padding-from-top"
          :style="kkScrollbarStyle"
        >
          <transition name="fade" mode="out-in">
            <router-view
              :key="routeKey"
              :obv-header="obvHeader"
              @page-loaded="onPageLoaded"
            />
          </transition>
          <transition name="fade" mode="out-in">
            <preloader-internal v-show="internalLoading" />
          </transition>
        </div>
        <Footer v-show="!internalLoading" :style="kkScrollbarStyle" />
        <kkg-notification
          v-if="notificationAppear.length > 0"
          :notification-data="notificationAppear"
          @close="hideNotification"
        />
      </v-app>
    </transition>
    <transition name="fade">
      <preloader v-show="!allLoaded" ref="preloader" @finalize="showContent" />
    </transition>
  </fragment>
</template>

<script>
import ProgressObserverMixin from "@/mixins/ProgressObserverMixin.js";
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";
import moment from "moment";
import "moment/locale/ru";
import KkgNotification from "@/components/KkgNotification.vue";
import PreloaderInternal from "./components/PreloaderInternal.vue";
import Preloader from "@/components/Preloader.vue";
import { useBiennaleStyles } from "@/use/biennaleStyles.js";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    KkgNotification,
    PreloaderInternal,
    Preloader,
  },
  mixins: [ProgressObserverMixin],
  data() {
    return {
      appInitiated: false,
      pageLoaded: false,
      allLoaded: false,
      hash: "",
      obvOptions: {
        viewportTopPct: 0,
        viewportBottomPct: 0,
        pivotBegin: "top",
        pivotEnd: "bottom",
      },
      scrollWidth: 0,
      scrollWidthInverted: 0,
    };
  },
  computed: {
    routeKey() {
      const to = this.$route;
      const key =
        to.matched && to.matched.length > 1 ? to.matched[0].path : to.path;
      return key.replace(/^\/.+\/participants\/?$/g, `/:biennale/participants`);
    },
    currentBiennaleId() {
      return this.$store.getters.getCurrentBiennale?.id || "none";
    },
    systemSettings() {
      return this.$store.getters.getSystem;
    },
    header() {
      return this.$store.getters.getHeader;
    },
    notificationAppear() {
      return this.allLoaded ? this.systemSettings.fields.notification : "";
    },
    internalLoading() {
      return this.$store.getters.getInternalLoading;
    },
    allowedFullIntro() {
      return (
        this.$route.name == "Main" &&
        this.$store.getters.getActualBiennaleIdent != ""
      );
    },
    allowedHalfIntro() {
      return this.$route.name == "ArchiveItem" || this.allowedFullIntro;
    },
    obvHeader() {
      const pct = this.obv("motion-container");
      if (this.$store.getters.isIntro) {
        return pct;
      } else {
        return this.allowedHalfIntro ? pct * 0.5 + 0.5 : 1;
      }
    },
    opacityLeave() {
      const opacityLeaveMin = 0.95;
      const opacityLeaveMax = 1;
      return Math.max(
        Math.min(
          (this.obvHeader - opacityLeaveMin) /
            (opacityLeaveMax - opacityLeaveMin),
          1
        ),
        0
      );
    },
    obvHeaderStyleDark() {
      const pct = Math.min(this.obvHeader * 2, 1);
      const sqr = pct * pct;
      const opacityEnterMin = 0.8;
      const opacityEnterMax = 1;
      const opacityEnter = Math.max(
        Math.min(
          (sqr - opacityEnterMin) / (opacityEnterMax - opacityEnterMin),
          1
        ),
        0
      );
      const opacity = Math.min(opacityEnter, 1 - this.opacityLeave);
      const style = {
        opacity,
      };
      if (opacity < 1) {
        style["pointer-events"] = "none";
      }
      return style;
    },
    obvHeaderStyle() {
      const opacity = this.opacityLeave;
      const style = {
        opacity,
      };
      if (opacity < 1) {
        style["pointer-events"] = "none";
      }
      return style;
    },
    kkScrollbarStyle() {
      const padding = this.$vuetify.breakpoint.mdAndUp ? 40 : 16;
      return {
        "padding-right": `${padding + this.scrollWidth}px`,
      };
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.initApp();
      }
    },
  },
  created() {
    this.initApp();
  },
  methods: {
    async initApp() {
      const local = this;
      const promises = [Promise.resolve(local.$store.dispatch("fetchBase"))];

      Promise.all(promises)
        .then(() => {
          this.initiateUserScripts();
          const currentLocale = local.$store.getters.getLang;
          local.$vuetify.lang.current = currentLocale;
          local.$i18n.locale = currentLocale;
          moment.locale(currentLocale);

          const html = document.querySelector("html");
          html.setAttribute("lang", currentLocale);

          this.applyBiennaleStyles(this.$store.getters.getBiennaleIndex);
          local.appInitiated = true;

          local.checkLoaded();
        })
        .catch(() => {
          return true;
        });
    },
    onPageLoaded(payload) {
      this.$store.commit("setFinishInternalLoading");
      this.pageLoaded = true;
      if ((payload?.hash || "").length > 0) {
        this.hash = payload.hash;
      }
      this.checkLoaded();
    },
    checkLoaded() {
      if (this.appInitiated && this.pageLoaded) {
        document.getElementById("app").scrollIntoView({ behavior: "instant" });
        if (this.$refs.preloader) {
          this.$refs.preloader.finish();
        }
      }
    },
    showContent() {
      this.allLoaded = true;
      this.$nextTick(() => {
        if (location.hash?.length) {
          this.scrollTo(location.hash.substring(1));
        } else if (this.hash.length > 0) {
          this.scrollTo(this.hash.substring(1));
          this.hash = "";
        }
        if (!this.allowedFullIntro) {
          this.$store.commit("disableIntro");
        }
        this.$store.commit("setFinishMainLoading");
        this.obvAddElement("motion-container", { offsetFromMenu: true });
      });
    },
    scrollTo(hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "instant" });
      }
    },
    hideNotification() {
      this.$store.commit("hideNotification");
    },
    initiateUserScripts() {
      const scriptsList =
        this.systemSettings.biennale_system_settings_scripts.children;
      scriptsList.forEach((script) => {
        const template = document.createElement("template");
        template.innerHTML = script.fields.code.trim();
        const oldScript = template.content.firstChild;
        document.head.appendChild(oldScript);

        const newScript = document.createElement("script");
        Array.from(oldScript.attributes).forEach((attr) =>
          newScript.setAttribute(attr.name, attr.value)
        );
        newScript.appendChild(document.createTextNode(oldScript.innerHTML));
        document.head.replaceChild(newScript, oldScript);
      });
    },
    onScrollbarTrigger(payload) {
      this.scrollWidth = payload.scrollWidth;
      this.scrollWidthInverted = payload.scrollWidthInverted;
    },
  },
  setup() {
    const { applyBiennaleStyles } = useBiennaleStyles();

    return {
      applyBiennaleStyles,
    };
  },
};
</script>

<style lang="scss">
@import "./assets/styles/styles.scss";
.fadeapp-enter-active,
.fadeapp-leave-active {
  transition-property: opacity;
  transition-duration: 1s;
}

.fadeapp-enter,
.fadeapp-leave-active {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
<style lang="scss" scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  background-color: var(--v-bgPrimary-base);
}

.content {
  width: 100%;
  height: 100%;
}
</style>
