import Vue from "vue";
import Vuex from "vuex";
import BaseModule from "./modules/base.js";
import SearchModule from "./modules/search.js";
import LangAvailModule from "./modules/langavail.js";
import InclusiveModule from "./modules/inclusive.js";

Vue.use(Vuex);

const { VUE_APP_NAME, VUE_APP_VERSION } = process.env;

const store = new Vuex.Store({
  state: {
    appInfo: {
      name: VUE_APP_NAME,
      version: VUE_APP_VERSION,
    },
  },
  getters: {
    getAppInfo(state) {
      return state.appInfo;
    },
  },
  modules: {
    BaseModule,
    SearchModule,
    LangAvailModule,
    InclusiveModule,
  },
});

export default store;
