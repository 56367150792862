<template>
  <router-link
    :to="{
      name: 'MediaLibraryItem',
      params: { mediabiennale: biennale, ident: payload.node_name },
    }"
    class="d-block news-list-item kk-hoverable-image mb-5"
  >
    <image-extended
      :image="payload.fields.image"
      content-class="news-image-container"
      class="mb-4"
      :height="height"
      eager
    />
    <div class="h6-mini mb-1">
      {{ payload.fields.title }}
    </div>
    <div class="caption-common textSecondary--text">
      {{ formatDate(payload.fields.date) }}
    </div>
  </router-link>
</template>
<script>
import moment from "moment";
import ImageExtended from "@/components/ImageExtended.vue";

export default {
  name: "AlbumListItem",
  components: { ImageExtended },
  props: {
    payload: {
      type: Object,
      required: true,
    },
    biennale: {
      type: String,
      required: true,
    },
  },
  computed: {
    formatDate() {
      return (date) => moment(date).format("D MMMM yyyy");
    },
    height() {
      const { breakpoint } = this.$vuetify;
      if (breakpoint.xlOnly) return "30vw";
      if (breakpoint.smAndUp) return "45vw";
      return "90vw";
    },
  },
};
</script>
