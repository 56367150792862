<template>
  <div>
    <section-title
      :title="mainFields.title_media"
      :browse-title="mainFields.browse_all_media"
      :browse-link="{ name: 'MediaLibraryList', params: { biennale } }"
      class="mb-6 mb-md-12"
    />

    <horizontal-scroller :items="sectionItems" content-class="ml-n4">
      <template v-slot="{ item }">
        <div class="mx-4 scroll-item__container">
          <img
            :src="item.i_size_600"
            draggable="false"
            class="scroll-item__image"
            alt=""
          />
        </div>
      </template>
    </horizontal-scroller>
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";

export default {
  name: "MainMedia",
  components: { SectionTitle, HorizontalScroller },
  props: {
    sectionItems: {
      type: Array,
      required: true,
    },
    mainFields: {
      type: Object,
      required: true,
    },
    biennale: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
.scroll-item__container {
  position: relative;
  overflow: hidden;
}
.scroll-item__image {
  height: 300px;
  @media (min-width: 1024px) {
    height: 400px;
  }
}
</style>
