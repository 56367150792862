const useBiennaleStyles = () => {
  const applyBiennaleStyles = function (biennales) {
    const style = document.createElement("style");
    const entries = Object.values(biennales);
    entries.push({
      id: "none",
      color_primary: "#E20000",
      color_secondary: "#F7F6F6",
      color_tetriary: "#242424",
    });
    style.innerHTML = entries
      .map((biennale) => {
        const { id, color_primary, color_secondary, color_tetriary } = biennale;
        return [
          `#app.v-application.theme--light.biennale-${id} { --v-biennalePrimary: ${color_primary}; }`,
          `#app.v-application.theme--light.biennale-${id} { --v-biennaleSecondary: ${color_secondary}; }`,
          `#app.v-application.theme--light.biennale-${id} { --v-biennaleTetriary: ${color_tetriary}; }`,
          `#app.v-application.theme--light.biennale-${id} .biennalePrimary--text { color: ${color_primary} !important; }`,
          `#app.v-application.theme--light.biennale-${id} .biennaleSecondary--text { color: ${color_secondary}; }`,
          `#app.v-application.theme--light.biennale-${id} .biennaleTetriary--text { color: ${color_tetriary}; }`,
          `#app.v-application.theme--light.biennale-${id} .biennalePrimary { background-color: ${color_primary}; }`,
          `#app.v-application.theme--light.biennale-${id} .biennaleSecondary { background-color: ${color_secondary}; }`,
          `#app.v-application.theme--light.biennale-${id} .biennaleTetriary { background-color: ${color_tetriary}; }`,
          `#app.v-application.theme--light.biennale-${id} a:not(.v-btn):not(.tag-chip):hover { color: ${color_primary} !important; }`,
          `#app.v-application.theme--light.biennale-${id} a:not(.v-btn):hover .v-icon { color: ${color_primary}; }`,
          `#app.v-application.theme--light.biennale-${id} .link:not(.dark):hover { color: ${color_primary}; }`,
          `#app.v-application.theme--light.biennale-${id} .kk-hoverable-text:hover { color: ${color_primary} !important; }`,
          `#app.v-application.theme--light.biennale-${id} .kk-hoverable-text:hover .kk-hoverable--item:not(.dark) { color: ${color_primary} !important; }`,
        ].join("\n");
      })
      .join("\n");
    document.getElementsByTagName("head")[0].appendChild(style);
  };

  return {
    applyBiennaleStyles,
  };
};

export { useBiennaleStyles };
