<template>
  <horizontal-scroller :items="list" content-class="ml-n1">
    <template v-slot="{ item }">
      <div class="mx-2 scroll-item__container">
        <img
          :src="item.fields.image.i_main"
          draggable="false"
          class="scroll-item__image"
          alt=""
        />
        <div class="pt-2 pt-md-4">
          <h5 class="mb-1 mb-md-2 kk-hoverable--item">
            {{ item.fields.title }}
          </h5>
          <tag-list
            v-if="(item.fields.tags || []).length > 0"
            :tags="item.fields.tags"
            :tag-route="tagRoute"
          />
          <tag-list
            v-else-if="(item.fields.directions || []).length > 0"
            :tags="item.fields.directions"
            :tag-route="directionRoute"
          />
        </div>
      </div>
    </template>
  </horizontal-scroller>
</template>
<script>
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import TagList from "@/components/Tags/TagList.vue";

export default {
  name: "SitesList",
  components: { HorizontalScroller, TagList },
  props: {
    list: {
      type: Array,
      required: true,
    },
    biennale: {
      type: String,
      required: true,
    },
  },
  methods: {
    tagRoute(tag) {
      const { biennale } = this;
      return { name: "ProgramListByTag", params: { biennale, tag: tag.id } };
    },
    directionRoute(direction) {
      const { biennale } = this;
      return {
        name: "ProgramDirection",
        params: { biennale, ident: direction.id },
      };
    },
  },
};
</script>
<style scoped lang="scss">
.scroll-item__container {
  position: relative;
  overflow: hidden;
}
.scroll-item__image {
  height: 300px;
  @media (min-width: 1024px) {
    height: 400px;
  }
}
</style>
