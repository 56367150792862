<template>
  <accordion :items="sectionData.children">
    <template v-slot:header="{ item }">
      {{ item.fields.title }}
    </template>
    <template v-slot="{ item }">
      <kk-dynamic-html class="mb-6" :html="item.fields.article" />
    </template>
  </accordion>
</template>
<script>
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import Accordion from "@/components/Accordion.vue";

export default {
  name: "VisitorRules",
  components: { KkDynamicHtml, Accordion },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
