<template>
  <div>
    <program-concept
      v-if="sectionData.fields.concept_header"
      id="biennale-concept"
      :payload="sectionData.fields"
      class="section"
    />

    <section-title
      v-if="(sectionData.children || []).length > 0"
      :title="mainFields.title_program"
      :browse-title="mainFields.browse_all_program"
      :browse-link="{ path: `/${biennale}/program` }"
      class="mb-6 mb-md-12"
    />

    <div v-if="(sectionData.children || []).length > 0">
      <program-list-direction
        v-for="directionBlock in directionsFiltered"
        :key="directionBlock.node_name"
        :payload="directionBlock"
        :biennale="biennale"
        :browse-title="sectionData.fields.details_title"
      />

      <div class="shader__container">
        <div class="shader" />
        <browse-button
          :browse-title="mainFields.browse_all_program"
          :browse-link="{ path: `/${biennale}/program` }"
          class="justify-center"
        />
      </div>
    </div>
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import BrowseButton from "@/components/BrowseButton.vue";
import ProgramConcept from "@/components/Program/ProgramConcept.vue";
import ProgramListDirection from "@/components/Program/ProgramListDirection.vue";

export default {
  name: "MainProgram",
  components: {
    SectionTitle,
    ProgramConcept,
    ProgramListDirection,
    BrowseButton,
  },
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
    mainFields: {
      type: Object,
      required: true,
    },
    biennale: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      directionFilters: [],
    };
  },
  computed: {
    directionsFiltered() {
      return this.sectionData.children.slice(0, 3);
    },
  },
};
</script>
<style lang="scss" scoped>
.shader__container {
  margin-top: -40px;
  position: relative;

  .shader {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    z-index: 5;
    pointer-events: none;
  }
}
</style>
