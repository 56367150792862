import ArchiveList from "@/pages/Archive";
import ArchiveItem from "@/pages/Main/indexArchive";

export default [
  {
    path: "/archive",
    name: "ArchiveList",
    component: ArchiveList,
  },
  {
    path: "/archive/:biennale",
    name: "ArchiveItem",
    component: ArchiveItem,
    props: true,
  },
];
