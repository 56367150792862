<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <g transform="translate(3 2)">
      <path
        fill="currentColor"
        d="M10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10H2C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C7.25022 2 4.82447 3.38734 3.38451 5.50024L6 5.5V7.5H0V1.5H2L1.99989 3.99918C3.82434 1.57075 6.72873 0 10 0ZM11 5L10.9998 9.585L14.2426 12.8284L12.8284 14.2426L8.9998 10.413L9 5H11Z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>
