<template>
  <v-row no-gutters class="footer__bottom justify-md-space-between">
    <v-col
      cols="12"
      sm="6"
      md=""
      class="text-no-wrap mb-4 mb-sm-3 mb-md-2 flex-md-grow-0 mb-xl-0"
    >
      <span class="base-2"
        >© {{ year }}, {{ sectionData.fields.copyright }}</span
      >
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md=""
      class="text-no-wrap mb-4 mb-sm-3 mb-md-2 text-sm-right flex-md-grow-0 flex-xl-grow-1 pr-xl-3 mb-xl-0"
    >
      <router-link :to="{ path: '/pages/license' }" class="base-2">
        {{ sectionData.fields.link_license }}
      </router-link>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md=""
      class="text-no-wrap mb-4 mb-sm-0 mb-md-2 text-sm-right order-sm-4 order-md-3 flex-md-grow-0 flex-xl-grow-1 text-xl-left pl-xl-3 mb-xl-0"
    >
      <router-link :to="{ path: '/pages/privacy-policy' }" class="base-2">
        {{ sectionData.fields.link_privacy }}
      </router-link>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="12"
      xl=""
      class="text-no-wrap order-sm-3 order-md-4 text-md-left flex-xl-grow-0"
    >
      <a
        href="https://aeolian-carpet-4c7.notion.site/Ploshad-Mira-d5f671d1b2a34be1a165636fa8715548"
        class="base-2"
        target="_blank"
      >
        {{ sectionData.fields.link_creators }}
      </a>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "FooterBottom",
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>
