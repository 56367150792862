<template>
  <div class="search-input-relative">
    <div class="search-input-absolute">
      <div class="search-input" :class="active ? '' : 'hidden'">
        <v-text-field
          id="form-text-field-search"
          ref="searchref"
          v-model="searchTerm"
          clearable
          :color="dark ? 'textOnBgDark' : 'textBase'"
          clear-icon="mdi-close-circle"
          hide-details
          height="36"
          :dark="dark"
          :placeholder="$t('Common.search')"
          :disabled="!active"
          @keyup.enter="onSearch"
          @blur="onBlur"
          @keyup.esc="onBlur"
        >
          <template v-slot:prepend-inner>
            <button class="kk-button" style="margin-top: 2px" @click="onSearch">
              <v-icon class="d-block link mx-2" :class="dark ? 'dark' : ''"
                >$vuetify.icons.Search</v-icon
              >
            </button>
          </template>
        </v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    resetOnBlur: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTerm: "",
    };
  },
  methods: {
    onSearch() {
      if (this.active) {
        if (this.searchTerm == "") return false;
        const { searchTerm } = this;
        this.searchTerm = "";
        this.$emit("on-search", searchTerm);
      } else {
        this.$emit("on-activate");
      }
    },
    onBlur() {
      if (this.resetOnBlur) {
        this.searchTerm = "";
      }
      this.$emit("on-blur");
    },
  },
};
</script>
<style lang="scss" scoped>
.search-input-relative {
  position: relative;
  height: 42px;
}
.search-input-absolute {
  position: absolute;
  right: 0;
  white-space: nowrap;
}
.search-input {
  width: 484px;
  margin-right: 24px;
  display: inline-block;
  transition: width 0.25s ease-in-out;
  &.hidden {
    width: 0px;
  }
}
.search-input > .v-input {
  padding-top: 0;
  margin-top: 0;
}
.search,
.close {
  display: inline-block;
}
.hidden .search,
.hidden .close {
  display: none;
}
</style>
