<template>
  <div v-if="pageLoaded" class="section">
    <page-title :title="pageData.fields.title" />

    <kk-article
      v-if="pageLoaded"
      ref="refarticle"
      :contents="contactsContents"
      class="subsection"
    >
      <div v-if="pageData.sites.length > 0">
        <subsection-title
          :title="pageData.fields.sites_title"
          :browse-title="pageData.sites_fields.title_all"
          :browse-link="{ name: 'Sites', params: { biennale } }"
          header-id="sites"
          class="mb-4 mb-sm-8"
        />
        <sites-list
          :list="pageData.sites"
          :biennale="biennale"
          class="subsection"
        />
      </div>

      <contacts-list
        :title="pageData.fields.contacts_title"
        :list="pageData.children"
      />
    </kk-article>

    <zuruck with-aux-bar />
  </div>
</template>

<script>
import ContactsRepository from "@/api/contacts.js";
import PageTitle from "@/components/PageTitle.vue";
import SubsectionTitle from "@/components/SubsectionTitle.vue";
import KkArticle from "@/components/Article/KkArticle.vue";
import SitesList from "@/components/Sites/SitesList.vue";
import ContactsList from "./ContactsList.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    PageTitle,
    SubsectionTitle,
    KkArticle,
    SitesList,
    ContactsList,
    Zuruck,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        children: [],
        sites: [],
      },
    };
  },
  computed: {
    biennale() {
      return this.$store.getters.getActualBiennaleIdent;
    },
    contactsContents() {
      return {
        ...(this.pageData.sites.length > 0
          ? { sites: this.pageData.fields.sites_title }
          : {}),
        contacts: this.pageData.fields.contacts_title,
      };
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await ContactsRepository.getContacts();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
      });
    },
  },
};
</script>
