import Main from "@/pages/Main/indexMain.vue";
import About from "@/pages/About/index.vue";
import Visitors from "@/pages/Visitors/index.vue";
import Contacts from "@/pages/Contacts/index.vue";
import CustomPage from "@/pages/CustomPage";
import Partnership from "@/pages/Partnership";
import Sites from "@/pages/Sites";
import Team from "@/pages/Team";
import Search from "@/pages/Search/index.vue";
import PageNotFound from "@/pages/CrossPages/PageNotFound.vue";
import archiveRoutes from "./routes/archive.js";
import newsRoutes from "./routes/news.js";
import mediaRoutes from "./routes/media.js";
import programRoutes from "./routes/program.js";
import participantsRoutes from "./routes/participants.js";
import redirectRoutes from "./routes/redirects.js";

export default [
  {
    path: "/404",
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: "/",
    name: "Main",
    component: Main,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/visitors",
    name: "Visitors",
    component: Visitors,
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
  },
  ...archiveRoutes,
  ...newsRoutes,
  ...mediaRoutes,
  ...programRoutes,
  ...participantsRoutes,
  {
    path: "/:biennale/team",
    name: "Team",
    component: Team,
    props: true,
  },
  {
    path: "/:biennale/sites",
    name: "Sites",
    component: Sites,
    props: true,
  },
  {
    path: "/partnership",
    name: "Partnership",
    component: Partnership,
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
  },
  ...Object.entries(redirectRoutes).map((entry, index) => ({
    path: entry[0],
    name: `Redirect${index}`,
    redirect: () => {
      return entry[1];
    },
  })),
  {
    path: "/pages/:ident",
    name: "CustomPage",
    component: CustomPage,
    props: true,
  },
  {
    path: "*",
    name: "NoRoute",
    redirect: () => {
      return `/404`;
    },
  },
];
