<template>
  <div class="image-ext__container">
    <v-img
      v-if="$attrs.image !== undefined"
      :src="src"
      eager
      :position="position"
      :contain="contain"
      v-bind="$attrs"
      class="image-ext"
      :class="imgClass"
      :style="imgStyle"
      v-on="$listeners"
    />
    <img
      ref="refimg"
      :src="src"
      draggable="false"
      class="image-ext--ghost"
      alt=""
    />
  </div>
</template>
<script>
export default {
  name: "ImageExtended",
  computed: {
    src() {
      const thumbSize = this.$attrs["thumb-size"] || 0;
      if (thumbSize && this.$attrs.image[`i_size_${thumbSize}`]) {
        return this.$attrs.image[`i_size_${thumbSize}`];
      }
      return this.$attrs?.image?.i_main || "";
    },
    position() {
      if (this.contain) return "center center";
      const { image } = this.$attrs;
      if (!image) return "center center";
      if (this.$attrs.position) {
        return this.$attrs.position;
      } else if (image.position) {
        return image.position;
      } else if (
        image.i_left &&
        image.i_right &&
        image.i_top &&
        image.i_bottom
      ) {
        const posX =
          (parseFloat(image.i_left + "") + parseFloat(image.i_right + "")) / 2;
        const posY =
          (parseFloat(image.i_top + "") + parseFloat(image.i_bottom + "")) / 2;
        return `${posX}% ${posY}%`;
      } else if (image.left && image.right && image.top && image.bottom) {
        const posX =
          (parseFloat(image.left + "") + parseFloat(image.right + "")) / 2;
        const posY =
          (parseFloat(image.top + "") + parseFloat(image.bottom + "")) / 2;
        return `${posX}% ${posY}%`;
      } else {
        return "center center";
      }
    },
    contain() {
      if (this.$attrs?.contain) {
        return this.$attrs.contain;
      }
      if (this.$attrs?.image?.i_contain !== null) {
        return this.$attrs?.image?.i_contain == "1";
      }
      return false;
    },
    imgClass() {
      const classes = [];
      if (this.contain) return classes;
      if (this.$attrs["scale-auto"] !== undefined) {
        classes.push("image-ext--scaled-auto");
      }
      if (this.$attrs.scale2 !== undefined) {
        classes.push("image-ext--scaled2");
      }
      if (this.$attrs.scale3 !== undefined) {
        classes.push("image-ext--scaled3");
      }
      if (this.$attrs.scale4 !== undefined) {
        classes.push("image-ext--scaled4");
      }
      return classes;
    },
    imgStyle() {
      const bgcolor = {};
      if (this.$attrs.fill) {
        bgcolor["background-color"] = this.$attrs.fill;
      } else if (this.$attrs?.image?.i_fill !== null) {
        if (this.contain) {
          bgcolor["background-color"] = this.$attrs?.image?.i_fill || "#ffffff";
        }
      }

      if (
        bgcolor["background-color"] &&
        this.$store.getters.getInclusiveModels.darktheme
      ) {
        bgcolor["background-color"] = "#1f1f1f";
      }

      if (bgcolor["background-color"] == "#f7f6f6") {
        bgcolor["background-color"] = "#ffffff";
      }

      return {
        ...bgcolor,
      };
    },
  },
  methods: {
    getNaturalSize() {
      return {
        width: this.$refs.refimg.naturalWidth,
        height: this.$refs.refimg.naturalHeight,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.image-ext__container {
  position: relative;

  .image-ext {
    height: 100%;
    width: 100%;
    z-index: 2;
  }

  .image-ext.image-ext--scaled-auto :deep(.v-image__image) {
    background-size: auto auto;
  }

  .image-ext.image-ext--scaled2 :deep(.v-image__image) {
    background-size: 200%;
  }

  .image-ext.image-ext--scaled3 :deep(.v-image__image) {
    background-size: 300%;
  }

  .image-ext.image-ext--scaled4 :deep(.v-image__image) {
    background-size: 400%;
  }

  .image-ext--ghost {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    opacity: 0;
  }
}
</style>
