import Repository from "@/plugins/axios";

export default {
  // Получить список выставок, экскурсий и событий по программе биеннале
  getProgramList(biennaleName) {
    return Repository.get(`/biennale/${biennaleName}/program`);
  },

  // Получить календарь выставок, экскурсий и событий по программе биеннале
  getProgramCalendar(biennaleName) {
    return Repository.get(`/biennale/${biennaleName}/calendar`);
  },

  // Получить один элемент программы
  getProgramItem(biennaleName, ident) {
    return Repository.get(`/biennale/${biennaleName}/program/${ident}`);
  },

  // Получить страницу направления
  getDirectionPage(biennaleName, ident) {
    return Repository.get(`/biennale/${biennaleName}/direction/${ident}`);
  },
};
