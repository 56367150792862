<template>
  <div v-if="pageLoaded" class="section">
    <image-header ref="refimageheader" :image="pageData.fields.image" />

    <kk-breadcrumbs :items="breadcrumbsItems" class="mb-5 mb-sm-10" />

    <kk-article
      ref="refarticle"
      :contents="pageData.fields.contents"
      dense
      class="subsection"
    >
      <page-title :title="pageData.fields.title" class="mb-10">
        <v-col cols="12" class="textSecondary--text mt-6 caption-common">
          {{ formatDate(pageData.fields.date) }}
        </v-col>
      </page-title>
      <kk-dynamic-html :html="pageData.fields.article" />
    </kk-article>

    <page-title
      :title="pageData.news_fields.title_other"
      :browse-title="pageData.news_fields.button_all"
      :browse-link="{ name: 'NewsList' }"
    />

    <v-row v-if="pageData.more">
      <v-col cols="12" xl="6" class="pa-md-5">
        <news-list-item :payload="more(0)" kind="first" />
      </v-col>
      <v-col cols="12" xl="6" class="pa-md-5">
        <v-row class="ma-md-n5">
          <v-col cols="12" class="pa-md-5">
            <news-list-item :payload="more(1)" kind="second" />
          </v-col>
        </v-row>
        <v-row class="ma-md-n5">
          <v-col cols="12" sm="6" class="pa-md-5">
            <news-list-item :payload="more(2)" kind="third-fourth" />
          </v-col>
          <v-col cols="12" sm="6" class="pa-md-5">
            <news-list-item :payload="more(3)" kind="third-fourth" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import NewsRepository from "@/api/news.js";
import ImageHeader from "@/components/ImageHeader.vue";
import KkBreadcrumbs from "@/components/Article/KkBreadcrumbs.vue";
import KkArticle from "@/components/Article/KkArticle.vue";
import PageTitle from "@/components/PageTitle.vue";
import NewsListItem from "@/components/News/NewsListItem.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import moment from "moment";

export default {
  components: {
    KkArticle,
    PageTitle,
    NewsListItem,
    KkDynamicHtml,
    ImageHeader,
    KkBreadcrumbs,
  },
  props: {
    ident: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        main_fields: {},
        news_fields: {},
        more: [],
      },
    };
  },
  computed: {
    breadcrumbsItems() {
      return [
        {
          text: this.pageData.main_fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.news_fields.title || "",
          to: { name: "NewsList" },
          exact: true,
          disabled: false,
        },
      ];
    },
    formatDate() {
      return (date) => moment(date).format("D MMMM yyyy");
    },
    more() {
      return (index) => this.pageData.more[index];
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  updated() {
    this.$refs.refimageheader.initObserver();
  },
  methods: {
    async loadPage() {
      const { data } = await NewsRepository.getNewsItem(this.ident);
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
        this.$refs.refimageheader.initObserver();
      });
    },
  },
};
</script>
