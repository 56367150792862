<template>
  <div class="section-title stick-to-body padding-from-body">
    <div
      class="d-sm-flex flex-wrap flex-md-nowrap align-center justify-space-between mb-md-1"
    >
      <div class="flex-grow-1 flex-shrink-3 pb-3 pb-sm-2 pr-1 pr-sm-3">
        <h1
          v-if="headerId.length > 0"
          :id="headerId"
          class="fix-contents biennalePrimary--text"
        >
          {{ title }}
        </h1>
        <h1 v-else class="biennalePrimary--text">{{ title }}</h1>
      </div>
      <div v-if="browseTitle" class="flex-grow-0 text-no-wrap pl-1 pl-sm-3">
        <browse-button :browse-title="browseTitle" :browse-link="browseLink" />
      </div>
    </div>
  </div>
</template>

<script>
import BrowseButton from "./BrowseButton.vue";
export default {
  name: "SectionTitle",
  components: { BrowseButton },
  props: {
    title: {
      type: String,
      default: "",
    },
    browseTitle: {
      type: String,
      default: "",
    },
    browseLink: {
      type: Object,
      default: () => {},
    },
    headerId: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped lang="scss">
.section-title {
  border-bottom: 1px solid var(--v-line-base);
}
</style>
