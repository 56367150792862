<template>
  <div v-if="pageLoaded">
    <div v-show="$route.name == 'NewsList'" class="section">
      <page-title :title="pageData.fields.title">
        <v-col cols="12" sm="" class="biennale-selector">
          <v-select
            v-model="filters.biennale"
            dense
            outlined
            :items="pageData.biennale"
            item-value="id"
            item-text="title"
            attach="#news-biennale-select-sub"
            :menu-props="{
              contentClass: 'kk-select-menu',
            }"
            append-icon="$vuetify.icons.ChevronDown"
            :placeholder="$t('Common.selectBiennale')"
            hide-details
            single-line
            tabindex="0"
            return-object
            width="100%"
            @change="queryItems"
          >
            <template v-slot:item="{ item }">
              <div class="py-1">
                {{ item.title }}
              </div>
            </template>
            <template v-slot:selection="{ item }">
              <div class="py-1 news-biennale-selection">
                {{ item.title }}
              </div>
            </template>
          </v-select>
          <div id="news-biennale-select-sub" />
        </v-col>
      </page-title>

      <kk-dynamic-html
        v-if="pageData.fields.article"
        :html="pageData.fields.article"
        class="subsection"
      />

      <v-row class="ma-md-n5">
        <v-col cols="12" xl="6" class="pa-md-5">
          <news-list-item :payload="child(0)" kind="first" />
        </v-col>
        <v-col cols="12" xl="6" class="pa-md-5">
          <v-row class="ma-md-n5">
            <v-col cols="12" class="pa-md-5">
              <news-list-item :payload="child(1)" kind="second" />
            </v-col>
          </v-row>
          <v-row class="ma-md-n5">
            <v-col cols="12" sm="6" class="pa-md-5">
              <news-list-item :payload="child(2)" kind="third-fourth" />
            </v-col>
            <v-col cols="12" sm="6" class="pa-md-5">
              <news-list-item :payload="child(3)" kind="third-fourth" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="ma-md-n5">
        <v-col
          v-for="newsItem in children(4)"
          :key="newsItem.node_name"
          cols="12"
          sm="6"
          md="4"
          xl="3"
          class="pa-md-5"
        >
          <news-list-item :payload="newsItem" />
        </v-col>
        <v-col cols="12">
          <infinite-loader
            :fetch="fetchMore"
            :disabled="disableInfiniteScroll"
            :visible="pageData.more > 0 || !pageLoaded"
          />
        </v-col>
      </v-row>

      <zuruck />
    </div>
    <router-view
      v-show="$route.name == 'NewsItem'"
      :key="$route.path"
      @page-loaded="$emit('page-loaded')"
    >
    </router-view>
  </div>
</template>

<script>
import NewsRepository from "@/api/news.js";
import PageTitle from "@/components/PageTitle.vue";
import InfiniteLoader from "@/components/InfiniteLoader.vue";
import NewsListItem from "@/components/News/NewsListItem.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    PageTitle,
    InfiniteLoader,
    NewsListItem,
    KkDynamicHtml,
    Zuruck,
  },
  props: {
    biennale: {
      type: String,
      default: "",
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        children: [],
        biennale: [],
        more: 0,
      },
      filters: {
        biennale: null,
      },
      offset: 0,
      loading: true,
      scrollTop: 0,
    };
  },
  computed: {
    child() {
      return (index) => this.pageData.children[index];
    },
    children() {
      return (index) => this.pageData.children.slice(index);
    },
    disableInfiniteScroll() {
      return this.loading || this.pageData.more == 0;
    },
    queryParams() {
      const params = {};
      if (this.filters.biennale != null) {
        params.biennale = this.filters.biennale.id;
      }
      return params;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
    "$route.name"(newV) {
      // + disalbed scroll behaviour in router/index.js
      if (newV != "NewsList") {
        this.scrollTop = window.scrollY;
      } else {
        this.$nextTick(() => {
          window.scrollTo(0, this.scrollTop);
        });
      }
    },
  },
  mounted() {
    if (this.biennale.length > 0) {
      this.filters.biennale = { title: "", id: this.biennale };
    }
    this.loadPage();
  },
  methods: {
    async loadPage() {
      await this.queryItems();
      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      this.loading = false;
      if (this.$route.name !== "NewsItem") {
        this.$emit("page-loaded");
      }
    },
    async queryItems() {
      const { data } = await NewsRepository.getNewsList(this.queryParams);
      this.offset = data.children.length;
      this.pageData = data;
    },
    async fetchMore() {
      this.loading = true;

      const queryParams = { ...this.queryParams };
      queryParams.offset = this.offset;

      const { data } = await NewsRepository.getNewsList(queryParams);

      this.offset += data.children.length;
      data.children.forEach((element) => {
        const index = this.pageData.children.length;
        this.$set(this.pageData.children, index, element);
      });
      this.pageData.more = data.more;

      this.loading = false;
    },
  },
};
</script>
<style scoped lang="scss">
.biennale-selector {
  width: 100%;
  min-width: 100%;
  margin-left: 0;
  @media (min-width: 640px) {
    width: 350px;
    min-width: 350px;
    max-width: 350px;
  }
}
#news-biennale-select-sub {
  position: relative;
}
.news-biennale-selection {
  line-height: 140% !important;
}
</style>
