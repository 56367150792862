<template>
  <div v-if="pageLoaded">
    <div v-show="isListRoute" class="section">
      <page-title :title="pageData.fields.title" />

      <kk-dynamic-html
        v-if="pageData.fields.article"
        :html="pageData.fields.article"
        class="subsection"
      />

      <v-row no-gutters class="align-end mb-8">
        <v-col class="flex-grow-1">
          <v-tabs
            ref="reftabs"
            v-model="tab"
            color="textSecondary"
            active-class="textBase--text"
            background-color="bgPrimary"
            slider-color="biennalePrimary"
            slider-size="4"
            class="tab-row"
            :class="$vuetify.breakpoint.xsOnly ? 'stick-to-body' : ''"
          >
            <v-tab :to="{ path: getPath('') }" exact-path>
              {{ "Фотоальбомы" }}
            </v-tab>
            <v-tab :to="{ path: getPath('/video') }" exact-path>
              {{ "Видео" }}
            </v-tab>
          </v-tabs>
        </v-col>

        <v-col cols="12" sm="" class="biennale-selector mt-8 mt-sm-0">
          <v-select
            v-model="filters.biennale"
            dense
            outlined
            :items="pageData.biennale"
            item-value="id"
            item-text="title"
            attach="#biennale-select-sub"
            :menu-props="{
              contentClass: 'kk-select-menu',
            }"
            append-icon="$vuetify.icons.ChevronDown"
            :placeholder="$t('Common.selectBiennale')"
            hide-details
            single-line
            tabindex="0"
            return-object
            width="100%"
            @change="loadPage"
          >
            <template v-slot:item="{ item }">
              <div class="py-1">
                {{ item.title }}
              </div>
            </template>
            <template v-slot:selection="{ item }">
              <div class="py-1 biennale-selection">
                {{ item.title }}
              </div>
            </template>
          </v-select>
          <div id="biennale-select-sub" />
        </v-col>
      </v-row>
      <v-row v-if="tab.includes('/video')" class="ma-md-n5">
        <v-col
          v-for="videoItem in pageData.albums.video"
          :key="videoItem.node_name"
          class="pa-md-5 flex-grow-0"
          cols="12"
          sm=""
        >
          <video-list-item :payload="videoItem" />
        </v-col>
      </v-row>

      <v-row v-else class="ma-md-n5">
        <v-col
          v-for="photoAlbumItem in pageData.albums.photo"
          :key="photoAlbumItem.node_name"
          cols="12"
          sm="6"
          xl="4"
          class="pa-md-5"
        >
          <album-list-item
            :payload="photoAlbumItem"
            :biennale="filters.biennale?.id || ''"
          />
        </v-col>
      </v-row>

      <zuruck />
    </div>
    <router-view
      v-show="$route.name == 'MediaLibraryItem'"
      :key="$route.path"
      @page-loaded="$emit('page-loaded')"
    >
    </router-view>
  </div>
</template>

<script>
import MediaLibraryRepository from "@/api/media.js";
import PageTitle from "@/components/PageTitle.vue";
import AlbumListItem from "@/components/MediaLibrary/AlbumListItem.vue";
import VideoListItem from "@/components/MediaLibrary/VideoListItem.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    PageTitle,
    AlbumListItem,
    VideoListItem,
    KkDynamicHtml,
    Zuruck,
  },
  props: {
    biennale: {
      type: String,
      default: "",
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      tab: "",
      pageData: {
        fields: {},
        albums: {
          photo: [],
          video: [],
        },
        biennale: [],
        biennaleId: null,
        more: 0,
      },
      filters: {
        biennale: null,
      },
      loading: true,
      scrollTop: 0,
    };
  },
  computed: {
    isListRoute() {
      return ["MediaLibraryList", "MediaLibraryListVideo"].includes(
        this.$route.name
      );
    },
    queryParams() {
      const params = {};
      if (this.filters.biennale != null) {
        params.biennale = this.filters.biennale.id;
      }
      return params;
    },
    getPath() {
      return (slug) => `${this.$route.matched[0].path}${slug}`;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
    "$route.name"(newV) {
      // + disalbed scroll behaviour in router/index.js
      if (newV != "MediaLibraryList") {
        this.scrollTop = window.scrollY;
      } else {
        this.$nextTick(() => {
          window.scrollTo(0, this.scrollTop);
        });
      }
    },
  },
  mounted() {
    if (this.biennale.length > 0) {
      this.filters.biennale = { title: "", id: this.biennale };
    }
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await MediaLibraryRepository.getAlbumsList(
        this.queryParams
      );
      this.pageData = data;
      if (this.filters.biennale == null) {
        this.filters.biennale = { title: "", id: this.pageData.biennaleId };
      }
      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      if (this.isListRoute) {
        this.$emit("page-loaded");
      }
      await this.$nextTick();
      this.$refs.reftabs.callSlider();
      this.loading = false;
    },
  },
};
</script>
<style scoped lang="scss">
.biennale-selector {
  width: 100%;
  min-width: 100%;
  margin-left: 0;
  @media (min-width: 640px) {
    width: 350px;
    min-width: 350px;
    max-width: 350px;
  }
}
#biennale-select-sub {
  position: relative;
}
.biennale-selection {
  line-height: 140% !important;
}
#app.v-application.theme--light {
  .v-tabs.tab-row {
    overflow-x: auto !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: unset;
  }
  .v-tabs.tab-row::-webkit-scrollbar {
    display: none !important;
  }
}
</style>
