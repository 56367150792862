<template>
  <div v-if="pageLoaded" class="section">
    <v-row no-gutters class="page-header align-start">
      <v-col cols="12" sm="" class="flex-grow-0 mb-4 mb-md-10">
        <v-avatar
          v-if="pageData.fields.photo.i_main"
          :size="$vuetify.breakpoint.mdAndUp ? 100 : 50"
          class="mr-5 mr-md-10 person-avatar flex-grow-0"
        >
          <image-clipped
            :image="pageData.fields.photo"
            :fit="$vuetify.breakpoint.mdAndUp ? 100 : 50"
          />
        </v-avatar>
      </v-col>
      <v-col cols="12" sm="" class="mb-4 mb-md-10 flex-grow-1">
        <h1 class="biennalePrimary--text">
          {{ pageData.fields.title }}
        </h1>
        <h4
          v-if="(pageData.fields.title_origin || '').length > 0"
          class="textSecondary--text mt-2 mt-md-4"
        >
          {{ pageData.fields.title_origin }}
        </h4>
        <div
          v-if="(pageData.fields.location || '').length > 0"
          class="caption-common textSecondary--text mt-2 mt-md-4"
        >
          <v-icon
            color="line"
            class="mr-1 mr-md-2"
            :size="$vuetify.breakpoint.mdAndUp ? 24 : 16"
            >$vuetify.icons.MapPin</v-icon
          >{{ pageData.fields.location }}
        </div>
      </v-col>
    </v-row>

    <kk-article
      ref="refarticle"
      :contents="pageData.fields.contents"
      dense
      class="subsection"
    >
      <kk-dynamic-html :html="pageData.fields.text" />
    </kk-article>

    <page-title
      v-if="pageData.linked.length > 0"
      :title="pageData.person_section_fields.title_linked"
    />

    <program-list-item
      v-for="linked in pageData.linked"
      :key="linked.id"
      :ident="linked.ident"
      :biennale="linked.biennale"
      :payload="linked.fields"
      show-biennale
    />

    <zuruck
      :with-aux-bar="Object.keys(pageData.fields.contents || {}).length > 0"
    />
  </div>
</template>

<script>
import ParticipantsRepository from "@/api/participants.js";
import ImageClipped from "@/components/ImageClipped.vue";
import KkArticle from "@/components/Article/KkArticle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import PageTitle from "@/components/PageTitle.vue";
import ProgramListItem from "@/components/Program/ProgramListItem.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  name: "ParticipantPage",
  components: {
    ImageClipped,
    KkArticle,
    KkDynamicHtml,
    PageTitle,
    ProgramListItem,
    Zuruck,
  },
  props: {
    ident: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        person_section_fields: {},
        linked: [],
      },
    };
  },
  computed: {},
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await ParticipantsRepository.getParticipant(this.ident);
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle?.initObserver();
      });
    },
  },
};
</script>
