<template>
  <div v-if="pageLoaded" class="section">
    <page-title :title="pageData.fields.archive_title" />

    <archive-list-item
      v-for="biennale in pageData.children"
      :key="biennale.id"
      :biennale="biennale"
      :browse-title="pageData.fields.archive_more_title"
    />

    <zuruck />
  </div>
</template>

<script>
import ArchiveRepository from "@/api/archive.js";
import PageTitle from "@/components/PageTitle.vue";
import ArchiveListItem from "@/components/Archive/ArchiveListItem.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    PageTitle,
    ArchiveListItem,
    Zuruck,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(
        this.pageData.fields.archive_title
      ),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        children: [],
      },
    };
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await ArchiveRepository.getList();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");
    },
  },
};
</script>
