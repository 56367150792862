<template>
  <div v-if="pageLoaded" class="section">
    <kk-breadcrumbs :items="breadcrumbsItems" class="mb-5 mb-sm-10" />

    <page-title :title="pageData.fields.title" class="mb-4 mb-md-8" />

    <kk-article ref="refarticle" :contents="contents" dense remove-last-gap>
      <kk-dynamic-html :html="pageData.fields.article" class="subsection" />

      <div v-if="(pageData.activities || []).length > 0" class="subsection">
        <h3 id="program" class="biennalePrimary--text mb-5 mb-md-10">
          {{ pageData.direction_section_fields.linked_title }}
        </h3>

        <program-list-item
          v-for="activity in pageData.activities"
          :key="activity.node_name"
          :payload="activity.fields"
          :biennale="biennale"
          :ident="activity.node_name"
          dense
        />
      </div>

      <h3
        v-if="(pageData.sites || []).length > 0"
        id="sites"
        class="biennalePrimary--text mb-5 mb-md-10"
      >
        {{ pageData.direction_section_fields.sites_title }}
      </h3>

      <div
        v-for="(item, index) in pageData.sites"
        :key="index"
        class="subsection"
      >
        <image-extended
          width="100%"
          :image="item.fields.image"
          class="mb-5 mb-md-10"
        />
        <h5 :id="item.node_name" class="mb-5 mb-md-10">
          {{ item.fields.title }}
        </h5>

        <tag-list
          v-if="(item.fields.tags || []).length > 0"
          :tags="item.fields.tags"
          :tag-route="tagRoute"
          class="mb-5 mb-md-10"
        />
        <tag-list
          v-else-if="(item.fields.directions || []).length > 0"
          :tags="item.fields.directions"
          :tag-route="directionRoute"
          class="mb-5 mb-md-10"
        />
        <kk-dynamic-html :html="item.fields.article" />
      </div>

      <participant-list
        v-for="participantGroup in pageData.participants"
        :key="participantGroup.node_name"
        :payload="participantGroup"
        :header-id="participantGroup.node_name"
        class="pt-0 subsection"
        dense
      />
    </kk-article>
  </div>
</template>

<script>
import ProgramRepository from "@/api/program.js";
import ImageExtended from "@/components/ImageExtended.vue";
import KkBreadcrumbs from "@/components/Article/KkBreadcrumbs.vue";
import KkArticle from "@/components/Article/KkArticle.vue";
import PageTitle from "@/components/PageTitle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import ParticipantList from "./components/ParticipantList.vue";
import ProgramListItem from "@/components/Program/ProgramListItem.vue";
import TagList from "@/components/Tags/TagList.vue";

export default {
  components: {
    ImageExtended,
    KkBreadcrumbs,
    KkArticle,
    PageTitle,
    KkDynamicHtml,
    ParticipantList,
    ProgramListItem,
    TagList,
  },
  props: {
    biennale: {
      type: String,
      required: true,
    },
    ident: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(
        this.pageData?.fields?.title || ""
      ),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        direction_section_fields: {},
        program_fields: {},
        main_fields: {},
        sites: [],
        activities: [],
        participants: [],
      },
    };
  },
  computed: {
    isActualBiennale() {
      return this.biennale === this.$store.getters.getActualBiennaleIdent;
    },
    contents() {
      const participantsGroupsContents = (
        this.pageData?.participants || []
      ).reduce((contents, group) => {
        contents[group.node_name] = group.fields.title;
        return contents;
      }, {});
      const contents = this.pageData?.fields?.contents || {};
      if ((this.pageData.activities || []).length > 0) {
        contents.program = this.pageData.direction_section_fields.linked_title;
      }
      if ((this.pageData.sites || []).length > 0) {
        contents.sites = this.pageData.direction_section_fields.sites_title;
      }
      return {
        ...contents,
        ...participantsGroupsContents,
      };
    },
    breadcrumbsItems() {
      return this.isActualBiennale
        ? this.breadcrumbsItemsActual
        : this.breadcrumbsItemsArchive;
    },
    breadcrumbsItemsActual() {
      const { biennale } = this;
      return [
        {
          text: this.pageData.main_fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.program_fields.title || "",
          to: { path: `/${biennale}/program` },
          exact: true,
          disabled: false,
        },
      ];
    },
    breadcrumbsItemsArchive() {
      const { biennale } = this;
      const biennaleName = this.biennaleInfo?.title || "";
      const biennaleNumber = this.biennaleInfo?.number || "";
      return [
        {
          text: this.pageData.main_fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.main_fields.archive_title || "",
          to: { name: "ArchiveList" },
          exact: true,
          disabled: false,
        },
        {
          text: `${biennaleNumber} «${biennaleName}»`,
          to: { name: "ArchiveItem", params: { biennale } },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.program_fields.title || "",
          to: { path: `/${biennale}/program` },
          exact: true,
          disabled: false,
        },
      ];
    },
    biennaleInfo() {
      return this.$store.getters.getBiennaleByIdent(this.biennale);
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
    biennale() {
      this.loadPage();
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await ProgramRepository.getDirectionPage(
        this.biennale,
        this.ident
      );
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
      });
    },
    tagRoute(tag) {
      const { biennale } = this;
      return { name: "ProgramListByTag", params: { biennale, tag: tag.id } };
    },
    directionRoute(direction) {
      const { biennale } = this;
      return {
        name: "ProgramDirection",
        params: { biennale, ident: direction.id },
      };
    },
  },
};
</script>
