<template>
  <div
    class="kk-arrow clickable biennaleTetriary"
    :style="btnStyle"
    v-on="$listeners"
  >
    <v-icon :size="$vuetify.breakpoint.smAndUp ? 32 : 22"
      >$vuetify.icons.Arrrow</v-icon
    >
  </div>
</template>
<script>
export default {
  name: "KkLink",
  props: {
    rotate: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    btnStyle() {
      return {
        transform: `rotateZ(${this.rotate}deg)`,
      };
    },
  },
};
</script>
<style scoped lang="scss">
.kk-arrow {
  width: 52px;
  height: 52px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 0.3s ease-in-out;
  .v-icon {
    color: white !important;
  }
  &:hover {
    border-color: white;
  }
}
</style>
