<template>
  <article ref="articleref" v-html="html" />
</template>
<script>
import ImageGallery from "@/components/ImageGallery.vue";
import LogotypeBand from "@/components/LogotypeBand.vue";
import FileAttach from "@/components/FileAttach.vue";
import KkLink from "@/components/KkLink.vue";
import Vue from "vue";

export default {
  name: "KkDynamicHtml",
  props: {
    html: {
      type: String,
      default: "",
    },
  },
  watch: {
    async html(newV, oldV) {
      if (oldV.length > 0) {
        await this.$nextTick();
        this.compileDynamicHTML();
      }
    },
  },
  mounted() {
    this.compileDynamicHTML();
  },
  methods: {
    compileDynamicHTML() {
      this.compileLinks();
      this.compileImageCaptions();
      this.compileFileAttaches();
      this.compileNotifications();
      this.compileImageGalleries();
      this.compileLogotypeBands();
    },

    compileLinks() {
      // выбираем все DOM-элементы в статье по имени тэга
      const elements = this.$refs.articleref.querySelectorAll("a");
      // получаем класс, который будет делать экземпляры компонента
      const componentClass = Vue.extend(KkLink);
      // по всем найденным в статье DOM-элементам
      elements.forEach((element) => {
        // получаем все свойства компонента из атрибутов DOM-элемента
        const link = element.getAttribute("href");
        // ссылки без ссылок — сжечь
        if (link === null) {
          element.remove();
          return true;
        }
        const propsData = {
          link,
          router: this.$router,
          store: this.$store,
        };
        // создаем компонент сразу со свойствами
        const componentInstance = new componentClass({ propsData });
        // прикручиваем компонент в элемент, замещая outerHTML элемента
        componentInstance.$vuetify = this.$vuetify;

        let e = document.createElement("textarea");
        e.innerHTML = element.innerHTML.replace(/(<([^>]+)>)/gi, "");
        componentInstance.$slots.default = [
          e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue,
        ];
        componentInstance.$mount(element);
      });
    },

    compileImageGalleries() {
      // выбираем все DOM-элементы в статье по имени тэга
      const elements = this.$refs.articleref.querySelectorAll("image-gallery");
      // получаем класс, который будет делать экземпляры компонента
      const componentClass = Vue.extend(ImageGallery);
      // по всем найденным в статье DOM-элементам
      elements.forEach((element) => {
        // получаем все свойства компонента из атрибутов DOM-элемента
        const propsData = element
          .getAttributeNames()
          .reduce((product, name) => {
            return { ...product, [name]: element.getAttribute(name) };
          }, {});
        // получаем дочерние элементы и заносим их свойства
        propsData.items = Array.from(
          element.querySelectorAll("image-gallery-item")
        ).map((item) => ({
          i_main: item.getAttribute("src"),
          i_aspect_ratio: item.getAttribute("aspect-ratio"),
          i_contain: item.getAttribute("contain"),
          position: item.getAttribute("position"),
          i_fill: item.getAttribute("fill"),
        }));

        // создаем компонент сразу со свойствами
        const componentInstance = new componentClass({ propsData });
        // прикручиваем компонент в элемент, замещая outerHTML элемента
        componentInstance.$vuetify = this.$vuetify;
        componentInstance.$store = this.$store;
        componentInstance.$mount(element);
      });
    },

    compileImageCaptions() {
      // выбираем все DOM-элементы в статье по имени тэга
      const elements = this.$refs.articleref.querySelectorAll("image-caption");
      // по всем найденным в статье DOM-элементам
      elements.forEach((element) => {
        // получаем все свойства компонента из атрибутов DOM-элемента
        const replaceWith = document.createElement("div");
        replaceWith.classList.add(`image-caption`);
        replaceWith.innerHTML = element.innerHTML;
        element.replaceWith(replaceWith);
      });
    },

    compileFileAttaches() {
      // выбираем все DOM-элементы в статье по имени тэга
      const elements = this.$refs.articleref.querySelectorAll("file-attach");
      // получаем класс, который будет делать экземпляры компонента
      const componentClass = Vue.extend(FileAttach);
      // по всем найденным в статье DOM-элементам
      elements.forEach((element) => {
        // получаем все свойства компонента из атрибутов DOM-элемента
        const propsData = {
          item: {
            f_path: element.getAttribute("src"),
            f_size: element.getAttribute("filesize"),
          },
        };
        propsData.item.f_title = element.innerHTML;
        // создаем компонент сразу со свойствами
        const componentInstance = new componentClass({ propsData });
        // прикручиваем компонент в элемент, замещая outerHTML элемента
        componentInstance.$vuetify = this.$vuetify;
        componentInstance.$mount(element);
      });
    },

    compileNotifications() {
      // выбираем все DOM-элементы в статье по имени тэга
      const elements = this.$refs.articleref.querySelectorAll("notification");
      // по всем найденным в статье DOM-элементам
      elements.forEach((element) => {
        // получаем все свойства компонента из атрибутов DOM-элемента
        const replaceWith = document.createElement("div");
        replaceWith.classList.add(`kk-notification`);
        replaceWith.innerHTML = element.innerHTML;
        element.replaceWith(replaceWith);
      });
    },

    compileLogotypeBands() {
      // выбираем все DOM-элементы в статье по имени тэга
      const elements = this.$refs.articleref.querySelectorAll("logotype-band");
      // получаем класс, который будет делать экземпляры компонента
      const componentClass = Vue.extend(LogotypeBand);
      // по всем найденным в статье DOM-элементам
      elements.forEach((element) => {
        // получаем все свойства компонента из атрибутов DOM-элемента
        const propsData = element
          .getAttributeNames()
          .reduce((product, name) => {
            return { ...product, [name]: element.getAttribute(name) };
          }, {});
        // получаем дочерние элементы и заносим их свойства
        propsData.items = Array.from(element.querySelectorAll("logotype")).map(
          (item) => ({
            src: item.getAttribute("src"),
          })
        );

        // создаем компонент сразу со свойствами
        const componentInstance = new componentClass({ propsData });
        // прикручиваем компонент в элемент, замещая outerHTML элемента
        componentInstance.$vuetify = this.$vuetify;
        componentInstance.$mount(element);
      });
    },
  },
};
</script>
