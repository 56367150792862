<template>
  <v-row
    class="program-list-item pt-6 pt-md-14 pb-0 kk-hoverable-image kk-hoverable-text"
    :class="dense ? 'dense-item' : 'wide-item'"
    no-gutters
  >
    <a
      v-if="
        payload.link &&
        !payload.link.startsWith('/program_biennale_direction_link')
      "
      :href="payload.link"
      class="program-list-item__link"
      >&nbsp;</a
    >
    <router-link
      v-else
      :to="{ name: 'ProgramItem', params: { biennale, ident } }"
      class="program-list-item__link"
      >&nbsp;</router-link
    >

    <v-col
      cols="12"
      class="flex-grow-0 program-list-item__schedule mb-3"
      :class="`col-${breakpoint} mb-${breakpoint}-0 pb-${breakpoint}-5`"
    >
      <div class="mb-1 mb-md-2">
        <schedule-dates-interval
          v-if="payload.schedule"
          :schedule="payload.schedule"
          rich-format
          class="h6-mini"
        />
        <h4 v-else>{{ timeFormat }}</h4>
      </div>
      <div
        v-if="showBiennale && isOldBiennale"
        class="caption-common kk-hoverable--item textSecondary--text d-flex align-start"
      >
        <v-icon
          class="d-block mr-2 kk-hoverable--item iconSocial--text program-list__icon"
          size="24"
          >$vuetify.icons.ClockPast</v-icon
        >
        <span
          >{{ $t("Common.archive") }}: {{ (biennaleInfo || {}).title }}</span
        >
      </div>
      <div
        v-else-if="payload.direction?.text"
        class="caption-common kk-hoverable--item textSecondary--text d-flex align-start"
      >
        <v-icon
          class="d-block mr-2 kk-hoverable--item iconSocial--text program-list__icon"
          size="24"
          >$vuetify.icons.StarOutline</v-icon
        >
        <span>{{ payload.direction.text.toLowerCase() }}</span>
      </div>
    </v-col>

    <v-col
      cols="12"
      class="flex-grow-0 program-list-item__image mb-3"
      :class="`col-${breakpoint} mb-${breakpoint}-0 pb-${breakpoint}-5`"
    >
      <v-avatar v-if="payload.image" :size="200" tile>
        <image-clipped :fit="200" :image="payload.image" />
      </v-avatar>
    </v-col>

    <v-col
      cols="12"
      class="flex-grow-1 program-list-item__description pb-5"
      :class="`col-${breakpoint}`"
    >
      <h4 class="mb-3 pr-12">{{ payload.title }}</h4>
      <div
        class="caption-common program-list-item__annotation"
        v-html="payload.annotation"
      />
      <div class="program-list-item__arrow">
        <v-icon class="d-block mr-2" color="biennalePrimary" size="32"
          >$vuetify.icons.Arrrow</v-icon
        >
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ImageClipped from "@/components/ImageClipped.vue";
import ScheduleDatesInterval from "@/components/ScheduleDatesInterval.vue";
export default {
  name: "ProgramListItem",
  components: {
    ImageClipped,
    ScheduleDatesInterval,
  },
  props: {
    payload: {
      type: Object,
      required: true,
    },
    biennale: {
      type: String,
      required: true,
    },
    ident: {
      type: String,
      required: true,
    },
    showBiennale: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    breakpoint() {
      return this.dense ? "xxl" : "md";
    },
    timeFormat() {
      if (this.payload.time_text) {
        return this.payload.time_text;
      } else if (this.payload.time_end) {
        return `${this.payload.time_begin} — ${this.payload.time_end}`;
      } else {
        return this.payload.time_begin;
      }
    },
    isOldBiennale() {
      return this.biennale != this.$store.getters.getActualBiennaleIdent;
    },
    biennaleInfo() {
      return this.$store.getters.getBiennaleByIdent(this.biennale);
    },
  },
};
</script>
<style scoped lang="scss">
.program-list-item {
  border-bottom: 1px solid var(--v-line-base);
  position: relative;

  .program-list-item__link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
  }

  &.wide-item {
    @media (min-width: 1024px) {
      .program-list-item__schedule {
        min-width: 270px;
        max-width: 270px;
        margin-right: 30px;
      }
      .program-list-item__image {
        min-width: 200px;
        max-width: 200px;
        margin-right: 40px;
      }
    }
  }

  &.dense-item {
    @media (min-width: 1920px) {
      .program-list-item__schedule {
        min-width: 270px;
        max-width: 270px;
        margin-right: 30px;
      }
      .program-list-item__image {
        min-width: 200px;
        max-width: 200px;
        margin-right: 40px;
      }
    }
  }

  .program-list-item__description {
    position: relative;
  }

  .program-list-item__annotation,
  .program-list-item__schedule {
    position: relative;
    z-index: 4;
  }

  .program-list-item__arrow {
    display: none;
    position: absolute;
    right: 0;
    top: -3px;
    width: auto;
    height: auto;
    @media (min-width: 1024px) {
      top: 4px;
    }
  }
  &:hover .program-list-item__arrow {
    display: block;
  }
}
</style>
