<template>
  <div>
    <page-title :title="pageData.fields.title" />

    <div
      v-for="(group, groupIndex) in pageData.children"
      :key="groupIndex"
      class="subsection"
    >
      <h3 class="biennalePrimary--text mb-8 mb-md-8">{{ group.title }}</h3>
      <v-row>
        <v-col
          v-for="(member, memberIndex) in group.list"
          :key="memberIndex"
          cols="12"
          sm="4"
          class="pb-4 pb-md-8"
        >
          <h4>{{ member.name }}</h4>
          <div v-if="member.role" class="base mt-1 mt-md-2">
            {{ member.role }}
          </div>
        </v-col>
      </v-row>
    </div>

    <zuruck />
  </div>
</template>

<script>
import SimplePagesRepository from "@/api/simple.js";
import PageTitle from "@/components/PageTitle.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: { PageTitle, Zuruck },
  props: {
    biennale: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        children: [],
      },
    };
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
    biennale() {
      this.loadPage();
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await SimplePagesRepository.getTeam(this.biennale);
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");
    },
  },
};
</script>
