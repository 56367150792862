<template>
  <div class="kk-top-breadcrumbs d-flex align-center stick-to-body">
    <v-breadcrumbs
      v-dragscroll.x
      class="pl-4 pl-md-10 py-0 my-0 section__title kk-breadcrumbs"
      :items="items"
      :divider="divider"
      :dark="dark"
      draggable="false"
    >
      <slot name="default"></slot>
      <template v-slot:item="{ item }">
        <router-link
          v-if="item.to"
          :to="item.to"
          class="v-breadcrumbs__item"
          draggable="false"
          :class="item.class || ''"
          >{{ item.text }}</router-link
        >
        <span v-else class="textSecondary--text">{{ item.text }}</span>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
export default {
  name: "KkBreadcrumbs",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    divider: {
      type: String,
      default: "/",
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
