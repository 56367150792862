<template>
  <router-link
    v-if="(nodeName || '').length > 0"
    :to="to"
    class="d-block kk-hoverable-image"
  >
    <slot></slot>
  </router-link>
  <div v-else>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "RouterLinkWrapper",
  props: {
    nodeName: {
      type: String,
      default: "",
    },
    to: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
