<template>
  <div class="sv-preloader">
    <div class="sv-preloader__text">{{ preloaderText }}</div>
    <div class="sv-preloader__line" :style="`width: ${pct}%`" />
  </div>
</template>

<script>
export default {
  name: "Preloader",
  data() {
    return {
      finalizing: false,
      timer: null,
      pct: 0,
    };
  },
  computed: {
    preloaderText() {
      const pctRnd = Math.round(this.pct);
      return `${pctRnd}`;
    },
  },
  mounted() {
    this.startTimer();
  },
  destroyed() {
    this.stopTimer();
  },
  methods: {
    finish() {
      this.finalizing = true;
      const local = this;
      setTimeout(() => {
        local.stopTimer();
        this.pct = 100;
        local.$emit("finalize");
      }, 500);
    },
    startTimer() {
      this.timer = setInterval(() => {
        if (this.finalizing) {
          this.pct = this.pct + (100 - this.pct) / 4;
        } else {
          this.pct = this.pct + ((100 - this.pct) * (100 - this.pct)) / 500;
        }
      }, 100);
    },
    stopTimer() {
      clearInterval(this.timer);
    },
  },
};
</script>
<style lang="scss" scoped>
.sv-preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  background-color: white;

  .sv-preloader__text {
    position: absolute;
    font-family: "A Chaika Futuris", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 150px;
    line-height: 91%;
    color: var(--v-mira-base);
    left: 20px;
    top: 60px;
    @media (min-width: 1024px) {
      left: 40px;
      top: 120px;
      font-size: 217px;
    }
  }

  .sv-preloader__line {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: var(--v-mira-base);
  }
}
</style>
