<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="currentColor"
      d="M30.803 0C27.4203 0.581382 23.9832 0.770967 20.5581 0.565086C14.2994 0.565086 8.89749 1.39388 2.63878 1.39388C-1.34742 1.39388 0.36627 4.18165 0.36627 7.00709C0.36627 12.0552 0.36627 17.367 0.36627 22.4151C0.662562 25.4089 0.662562 28.4251 0.36627 31.4189C0.105491 32.549 9.19552 31.6826 10.6112 31.6826C18.2856 31.6826 23.9855 31.9463 31.6598 31.9463C32.2186 31.9463 31.9206 28.5934 31.9206 27.7646C31.9206 26.9358 31.9206 24.9392 31.9206 22.9802C31.9206 21.0213 31.9206 18.4972 31.9206 17.367C31.9206 14.5793 31.9206 11.4901 31.6598 8.40097C31.399 5.31182 32.2186 2.26035 30.5422 0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="white"
      d="M20.0171 7.9668L27.4462 15.5882L20.0171 23.2095L18.8088 21.9702L24.1796 16.4634H4.90625V14.7129H24.1796L18.8088 9.2061L20.0171 7.9668V7.9668Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 32,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
  },
};
</script>
