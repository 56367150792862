<template>
  <div
    v-if="biennale"
    class="intro__logo d-flex justify-center justify-sm-start align-center ml-n3 mr-n3 mr-sm-10 flex-wrap flex-sm-nowrap"
  >
    <div class="flex-grow-0 pa-3">
      <div class="intro intro2" :style="maskStyle(biennale.intro2)">
        <img :src="biennale.intro2" class="intro intro2 intro-spectre" alt="" />
      </div>
    </div>
    <div class="flex-grow-0 pa-3">
      <div class="intro intro3" :style="maskStyle(biennale.intro3)">
        <img :src="biennale.intro3" class="intro intro3 intro-spectre" alt="" />
      </div>
    </div>
    <div
      class="flex-grow-1 flex-sm-grow-0 pa-3 order-sm-first intro1__container"
    >
      <div class="intro intro1 mx-auto" :style="maskStyle(biennale.intro1)">
        <img :src="biennale.intro1" class="intro intro1 intro-spectre" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MainHeaderIntroLogoMasked",
  props: {
    biennale: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      default: "#ffffff",
    },
  },
  computed: {
    maskStyle() {
      return (src) => ({
        mask: `url(${src}) no-repeat center / contain`,
        "-webkit-mask": `url(${src}) no-repeat center / contain`,
        "background-color": this.color,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.intro__logo {
  --base-intro-width-mult: 0.6;
  --sm-width-mult: 1;
  --sm-width-mult-intro1: 2;

  .intro {
    display: block;
    height: auto;
    &.intro-spectre {
      opacity: 0;
    }
  }
  .intro1 {
    width: calc(
      39vw * var(--sm-width-mult) * var(--sm-width-mult-intro1) *
        var(--base-intro-width-mult)
    );
  }
  .intro2 {
    width: calc(79vw * var(--sm-width-mult) * var(--base-intro-width-mult));
  }
  .intro3 {
    width: calc(36vw * var(--sm-width-mult) * var(--base-intro-width-mult));
  }
  @media (min-width: 640px) {
    --sm-width-mult: 0.7;
    --sm-width-mult-intro1: 1;
  }

  @media (min-width: 1024px) {
    .intro1 {
      width: calc(300px * var(--base-intro-width-mult));
    }
    .intro2 {
      width: calc(610px * var(--base-intro-width-mult));
    }
    .intro3 {
      width: calc(280px * var(--base-intro-width-mult));
    }
  }
}
</style>
