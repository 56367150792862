<template>
  <div class="concept-container pa-5 pa-md-10">
    <div class="concept-graph" :style="graphStyle" />
    <v-row class="concept-content">
      <v-col cols="12" md="10">
        <h5 class="biennalePrimary--text mb-4 mb-md-6">
          {{ payload.concept_header }}
        </h5>
      </v-col>
      <v-col cols="12" md="10">
        <article v-html="payload.concept_text" />
      </v-col>
    </v-row>
    <div
      v-if="(payload.concept_avatar_text || '').length > 0"
      class="d-flex align-center"
    >
      <v-avatar
        v-if="payload.concept_avatar.i_main"
        :size="$vuetify.breakpoint.smAndUp ? 100 : 50"
        class="mr-2 mr-sm-3 person-avatar flex-grow-0"
      >
        <image-clipped
          :image="payload.concept_avatar"
          :fit="$vuetify.breakpoint.smAndUp ? 100 : 50"
        />
      </v-avatar>
      <v-col cols="8" md="9" lg="7" xl="5" class="avatar-text flex-grow-0">
        <div class="longread" v-html="payload.concept_avatar_text" />
      </v-col>
    </div>
  </div>
</template>

<script>
import ImageClipped from "@/components/ImageClipped.vue";
export default {
  name: "ProgramConcept",
  components: {
    ImageClipped,
  },
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
  computed: {
    graphStyle() {
      return {
        "background-image": `url(${this.payload.concept_graphic})`,
      };
    },
  },
};
</script>
<style scoped lang="scss">
.concept-container {
  border: 1px solid var(--v-line-base);
  position: relative;
  .concept-content,
  .avatar-text {
    position: relative;
    z-index: 2;
  }
  .concept-graph {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-size: 90% auto;
    background-repeat: no-repeat;
    background-position: 250px center;
    @media (min-width: 1024px) {
      background-size: contain;
      background-position: 140% center;
    }
    @media (min-width: 1280px) {
      background-position: center right;
    }
    @media (min-width: 1440px) {
      background-size: 60%;
    }
  }
  .person-avatar {
    pointer-events: none;
  }
}
</style>
